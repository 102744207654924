<template>
  <div class="scrollable-container legal" style="line-height: 150% !important;">
    <v-container fluid v-if="$route.params.type === 'confidentialite'">
      <h1>
        Règlement de protection des données personnelles Fédération française de
        la montagne et de l’escalade
      </h1>

      <h2>Introduction</h2>

      <p>
        La Fédération française de montagne et d’escalade, ci-après dénommée
        FFME est une association régie par la loi du 1er juillet 1901, dont le
        siège est situé au 8/10 quai de la Marne, 75019 Paris, code NAF : 93122,
        n° de SIRET : 784 354 193 000.
      </p>
      <p>
        Par agrément en date du 1er janvier 1964 et décret de délégation en
        Conseil d’Etat, la FFME gère les activités suivantes : l'escalade, le
        montagnisme (alpinisme, expéditions, raquettes à neige, randonnée de
        montagne, ski-alpinisme) et le canyonisme.
      </p>
      <p>
        Conformément aux articles L131-8 et suivants du Code du Sport, la FFME
        participe à la mise en œuvre des missions de service public relatives au
        développement et à la démocratisation des activités physiques et
        sportives et dans ce cadre, elle délivre des licences.
      </p>
      <p>
        Conformément aux articles L 131-14 et suivant du Code du Sport, la FFME
        organise les compétitions sportives à l’issue desquelles sont délivrés
        les titres internationaux, nationaux, régionaux ou départementaux,
        procèdent aux sélections correspondantes, proposent l’inscription sur
        les listes de sportifs, d’entraîneurs, d’arbitres et juges de haut
        niveau sur les listes.
      </p>
      <p>
        La FFME, agissant en tant que fédération sportive agréée et délégataire
        et conformément aux dispositions de la loi, décrit dans le règlement de
        confidentialité (ci-après le « Règlement »), comment elle recueille,
        conserve, utilise et divulgue les informations personnelles de ses
        licenciés dans le cadre de sa procédure de délivrance de licences, dans
        le cadre de la prise de souscription d’assurances pour des licenciés qui
        le souhaitent ou dans le cadre d’organisation de compétitions, de
        manifestations ou de formations.
      </p>

      <p>
        Ce règlement s’applique aux licenciés possédant des licences en cours de
        validité, aux participants aux compétitions et aux formations organisées
        par la fédération.
      </p>
      <p>
        La FFME s’engage à protéger la confidentialité de toutes les données
        personnelles collectées qu’elle obtient lors du processus de prise de
        licence ou d’inscription à une compétition ou à une formation ainsi
        qu’aux résultats attachés à ces participations. La FFME assure les
        personnes concernées qu’elle a pris les mesures appropriées pour
        protéger la confidentialité, la sécurité et l’intégrité des informations
        en sa possession.
      </p>
      <p>
        La FFME contrôle la manière dont les données personnelles sont
        collectées par elle, ses ligues, comités territoriaux ou ses clubs ou
        établissements affiliés et les finalités pour lesquelles elle exerce ses
        missions propres agissant en tant que « responsable du traitement » aux
        fins de la législation européenne applicable en matière de protection
        des données.
      </p>

      <h2>Collecte de données personnelles</h2>

      <p>
        La FFME n’utilisera les Données Personnelles Collectées que conformément
        au Règlement (UE) 2016/607 du 27 avril 2016 relatif à la protection des
        personnes physiques à l’égard du traitement des données à caractère
        personnel et à la libre circulation de ces données (RGPD) ainsi que loi
        n°2018-493 du 20 juin 2018. La FFME peut traiter les Données
        Personnelles Collectées :
      </p>
      <ul>
        <li>
          Pour délivrer une licence dans le cadre de l’article L131-6 du code du
          sport et permettre au titulaire d’exercer les droits qui sont attachés
          à sa délivrance
        </li>
        <li>
          Pour délivrer des assurances aux licenciés sauf à ce qu’ils demandent
          expressément à ne pas en bénéficier
        </li>
        <li>
          Pour participer à une compétition, une manifestation ou à une
          formation
        </li>
        <li>
          Pour faire bénéficier les participants à une compétition, une
          manifestation ou une formation des résultats et droits éventuels
          attachés à cette participation
        </li>
        <li>
          Pour faire bénéficier ses athlètes inscrits sur les listes de Haut
          Niveau du Ministère des Sports des droits attachés à l’obtention de ce
          statut
        </li>
        <li>Pour toute obligation légale qui incomberait à la FFME</li>
        <li>
          Lorsque le licencié a donné son autorisation expresse à la FFME pour
          utiliser ses données ou n’a pas manifesté d’opposition expresse.
        </li>
      </ul>
      <p>
        En application de l’article 10 des statuts de la FFME, la prise de
        licence qu’elle ouvre ou non le droit à la pratique d’une discipline est
        obligatoire pour tout adhérent d’un club ou d’une section de club
        affilié à la FFME.
      </p>
      <p>
        Pour se licencier, une personne physique doit effectuer une demande
        d’inscription auprès d’un club ou d’un établissement affilié à la FFME
        ou auprès de la FFME, elle-même.
      </p>
      <p>
        Le club ou l’établissement au titre duquel une demande de licence est
        effectuée est responsable de la conservation de l’ensemble des pièces
        exigées pour la prise de licence et de la transmission au siège fédéral
        des informations nécessaires à la prise de licences, selon les formes
        prescrites par celui-ci.
      </p>
      <p>
        Le règlement médical fixe, au vu de la législation applicable ainsi que,
        le cas échéant, en considération de l’environnement dans lequel se
        déroule la pratique, les règles à respecter en matière de production de
        certificat médical nécessaire à la délivrance de la licence. Aucun
        certificat médical n’est toutefois exigé en vue de la délivrance d’une
        licence « non-pratiquant ».
      </p>
      <p>
        Le siège fédéral transmet à l’intéressé sa licence par voie
        électronique.
      </p>
      <p>
        Le titre de licencié à titre individuel peut être accordé aux personnes
        physiques qui ne relèvent d’aucun club ou établissement. Les demandes
        sont envoyées au siège fédéral qui instruit les dossiers. Dans ce cas,
        la personne physique qui demande sa licence remplit un formulaire en
        ligne et transmet les documents obligatoires avant de régler sa prise de
        licence.
      </p>
      <p>
        Les données personnelles collectées pour l’ensemble des finalités
        décrites ci-dessus incluent : nom, prénom, date de naissance,
        nationalité, sexe, adresse postale, adresse mail, numéro de téléphone,
        un certificat médical de non contre-indication ou une attestation de
        santé pour toute pratique de l’activité.
      </p>

      <h2>Utilisation des données personnelles</h2>

      <p>
        La FFME utilisera, conservera, contrôlera et gèrera les informations
        personnelles de la personne physique licenciée ou participante à une
        compétition, une manifestation ou une formation dans le cadre des
        missions qui lui sont déléguées par le Code du Sport conformément au
        Règlement (UE) 2016/607 du 27 avril 2016 relatif à la protection des
        personnes physiques à l’égard du traitement des données à caractère
        personnel et à la libre circulation de ces données (RGPD) ainsi que loi
        n°2018-493 du 20 juin 2018.
      </p>
      <p>
        La FFME collectera, utilisera et conservera les données personnelles
        pour lui permettre d’exécuter pleinement la mission de service public
        dont elle est investie et la réalisation de son objet statutaire à
        caractère sportif et associatif :
      </p>
      <ul>
        <li>
          Gestion des licences annuelles et des licences temporaires (ou
          licences découverte). A noter que dans ce cadre, ces données pourront
          être transmises à l’assureur de la FFME pour la mise en place des
          différentes garanties obligatoires ou des garanties particulières (sur
          demande spécifique du licencié) ou aux ligues, comités territoriaux,
          clubs ou comités d’organisation locaux affiliés.
        </li>
        <li>
          Gestion des compétitions et manifestations sportives. A noter que dans
          ce cadre, ces données pourront être transmises aux ligues, comités
          territoriaux, clubs ou comités d’organisation locaux affiliés.
        </li>
        <li>
          Gestion des athlètes inscrits sur les listes de haut niveau. A noter
          que dans ce cadre, ces données pourront être transmises au Ministère
          des Sports que ce soit pour les sportifs inscrits sur les listes ou
          ceux pour lesquels une inscription est demandée.
        </li>
        <li>
          Gestion des formations. A noter que dans ce cadre, ces données
          pourront être transmises à l’Etat, à la branche professionnelle «
          Sport » ou à tout organisme paritaire collecteur agréé selon la
          règlementation en vigueur.
        </li>
        <li>
          Information sur les activités de la fédération liées à l’exercice de
          ses missions de service public
        </li>
      </ul>
      <p>
        Par ailleurs, la FFME pourra avec l’accord de la personne physique
        concernée, utiliser ses données personnelles pour :
      </p>
      <ul>
        <li>
          Lui transmettre différents types de communication à caractère
          commercial : offres promotionnelles de sa boutique ou de ses
          partenaires, informations sur des partenaires de manifestations, ….
        </li>
      </ul>
      <p>
        La FFME recueille, traite et conserve également des données anonymisées
        et non personnelles à des fins statistiques et d’analyse.
      </p>
      <p>
        S’agissant des données collectées dans le cadre de l’attribution d’une
        licence, celles-ci seront conservées durant la validité de la licence
        puis postérieurement durant le délai de prescription des actions liées à
        l’obtention de cette licence évalué à 5 ans par la FFME.
      </p>
      <p>
        S’agissant des données relatives aux résultats des compétitions ainsi
        qu’aux formations et aux obtentions de diplômes associées, la FFME
        conservera les données jusqu’au décès des participants (sous réserve que
        celui-ci soit porté à la connaissance de la fédération) en vue de
        délivrer les attestations éventuelles y afférents.
      </p>
      <p>
        S’agissant des données relatives à la gestion des athlètes de haut
        niveau, celle-ci seront conservées durant leur inscription sur les
        listes puis durant le délai de prescription des actions liées à
        l’obtention de cette inscription évalué à 5 ans par la FFME.
      </p>

      <h2>Mesures de sécurité</h2>

      <p>
        Les informations personnelles fournies peuvent être répertoriées dans
        l’Espace Licencié, l’Intranet de la FFME, le site Internet de la FFME,
        les listes d’inscription aux compétitions ou manifestations, les listes
        d’inscription aux formations, les listes de délivrance de diplômes ou
        les listes d’athlètes de haut niveau ; La FFME offre une protection
        élevée des informations enregistrées fournies par le licencié, l’athlète
        ou le participant.
      </p>
      <p>
        La FFME fait ses meilleurs efforts pour protéger ces données contre les
        dommages, perte, détournement, intrusion, divulgation, allégation ou
        destruction. Toutefois, la FFME ne maîtrise pas les risques liés au
        fonctionnement d’Internet et signale l’existence de risques éventuels en
        termes de pertes ponctuelles de données ou d’atteinte à la
        confidentialité des données transmises.
      </p>
      <p>
        Toutes les données transmises sont considérées comme confidentielles et
        ne seront utilisées que dans le cadre des finalités décrites ci-dessus
        et dans les conditions décrites ci-dessus.
      </p>

      <h2>Droits sur les données collectées</h2>

      <p>
        Les licenciés, participants, athlètes de haut niveau disposent d’un
        droit général d’accès de modification, de rectification et de
        suppression des données transmises.
      </p>
      <p>Plus précisément, ils disposent :</p>
      <ul>
        <li>d’un droit d’accès et de rectification de manière permanente</li>
        d’un droit à l’effacement lorsque la durée de conservation des données
        est dépassée
        <li>
          d’un droit à l’utilisation restreint lorsque les données ne sont pas
          nécessaires
        </li>
        <li>
          d’un droit à la portabilité, c’est-à-dire la possibilité de
          communiquer les données à la personne choisie par le licencié, le
          participant ou l’athlète de haut niveau ayant transmis les
          informations, sur demande
        </li>
        <li>
          d’un droit de changer d’avis, notamment pour annuler l’accord donné
          pour l’utilisation commerciale ou en vue de la transmission de la
          Lettre d’information
        </li>
        <li>
          d’un droit de décider de l’utilisation des données personnelles après
          le décès du licencié, du participant ou de l’athlète de haut niveau
        </li>
      </ul>
      <p>
        Pour exercer son droit d’accès aux données collectées dans le cadre de
        la lutte anti-blanchiment et antiterroriste, il faut s’adresser
        directement à la CNIL. Le site de la CNIL précise en détail les
        différents droits et les aspects légaux liés au recueil des données
        personnelles :
        <a href="https://www.cnil.fr/" target="_blank">www.cnil.fr</a>
      </p>
      <p>
        Pour s’opposer à l’utilisation de ses données, demander leur effacement,
        poser une question sur l’ensemble des traitements ou une réclamation,
        les licenciés, participants ou athlètes de haut niveau peuvent écrire à
        FFME, Administration, 8-10 quai de la Marne, 75019 Paris ou
        <a href="mailto:administration@ffme.fr">administration@ffme.fr</a>.
      </p>
    </v-container>
    <v-container fluid v-if="$route.params.type === 'mentions-legales'">
      <h1>Mentions légales</h1>

      <h2>Dénomination et adresse du siège social</h2>
      <br />
      Fédération Française de la Montagne et de l’Escalade (FFME) <br />
      8/10 quai de la Marne <br />
      75019 Paris <br />
      <h2>Numéro de téléphone</h2>
      <br />
      Tel : 01-40-18-75-50 <br />
      Fax :01-40-18-75-59 <br />
      <h2>Numéro de Siret</h2>
      <br />
      784 354 193 00046-APE 926 C <br />
      <h2>Nom du directeur de publication</h2>
      <br />
      Pierre You
    </v-container>
    <v-container fluid v-if="$route.params.type === 'cgu-licences'">
      <p class="c28">
        <span class="c24"
          >Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation
          Utilisateur</span
        >
      </p>
      <p class="c6 c19"><span class="c1"></span></p>
      <p class="c6">
        <span class="c1"
          >FFME, 10 quai de la Marne, Paris 75019<br />784 354 193 00046-APE 926
          C</span
        >
      </p>
      <p class="c6 c19"><span class="c1"></span></p>
      <p class="c6">
        <span class="c1"
          >Les utilisateurs sont invit&eacute;s &agrave; lire attentivement et
          &agrave; comprendre les pr&eacute;sentes conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation. Ils s&rsquo;engagent
          &agrave; respecter la totalit&eacute; des dispositions qu&rsquo;elles
          contiennent. La navigation sur le site www.myFFME.fr,
          propri&eacute;t&eacute; de la FFME et exploit&eacute; par la FFME et
          l&rsquo;acc&egrave;s au service propos&eacute; par la FFME supposent
          l&rsquo;acceptation pleine et enti&egrave;re des pr&eacute;sentes
          conditions</span
        >
      </p>
      <p class="c6">
        <span class="c7">Derni&egrave;re mise &agrave; jour : 24 Mai 2018</span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.gjdgxs"
            >Pr&eacute;sentation du service</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.gjdgxs"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.30j0zll"
            >D&eacute;finitions</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.30j0zll"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1fob9te"
            >Acceptation</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1fob9te"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3znysh7"
            >Utilisation</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3znysh7"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2et92p0"
            >1 - Inscription</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2et92p0"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.tyjcwt"
            >2 - Compte utilisateur, mot de passe et s&eacute;curit&eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.tyjcwt"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3dy6vkm"
            >3 - Modalit&eacute;s de versement</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3dy6vkm"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1t3h5sf"
            >4 - R&eacute;siliation</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1t3h5sf"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4d34og8"
            >5 - Cr&eacute;ation d&rsquo;un compte de paiement en cas
            d&rsquo;utilisation du syst&egrave;me de paiement Lemonway</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4d34og8"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2s8eyo1"
            >6 - Indivisibilit&eacute; des pr&eacute;sentes conditions
            g&eacute;n&eacute;rales d&rsquo;utilisation et des conditions
            g&eacute;n&eacute;rales d&rsquo;utilisation propos&eacute;es par la
            soci&eacute;t&eacute; Lemonway</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2s8eyo1"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.17dp8vu"
            >7 - D&eacute;lai de r&eacute;tractation, annulation et
            remboursement</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.17dp8vu"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3rdcrjn"
            >Responsabilit&eacute; de La FFME</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3rdcrjn"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.26in1rg"
            >1 - Contenu</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.26in1rg"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.lnxbz9"
            >2 - Liens hypertextes</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.lnxbz9"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.35nkun2"
            >3 - Indisponibilit&eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.35nkun2"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1ksv4uv"
            >Donn&eacute;es &agrave; caract&egrave;re personnel</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1ksv4uv"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.44sinio"
            >Responsabilit&eacute; des utilisateurs</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.44sinio"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2jxsxqh"
            >1 - Les r&egrave;gles de conduite &agrave; respecter</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2jxsxqh"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.z337ya"
            >2 - Le signalement des abus</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.z337ya"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3j2qqm3"
            >3 - Inscription des Utilisateurs</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3j2qqm3"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1y810tw"
            >4 - Suspension du compte cr&eacute;&eacute; par
            l&rsquo;Utilisateur</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1y810tw"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4i7ojhp"
            >5 - Suppression du compte cr&eacute;&eacute; par
            l&rsquo;Utilisateur</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4i7ojhp"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2xcytpi"
            >6 - Comportement illicite</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2xcytpi"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1ci93xb"
            >7 - Indemnisation</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1ci93xb"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3whwml4"
            >Copyright et propri&eacute;t&eacute; intellectuelle</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3whwml4"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2bn6wsx"
            >1 - Droits de propri&eacute;t&eacute; intellectuelle de La FFME</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2bn6wsx"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.qsh70q"
            >2 - Interdiction d&#39;exploitation des services fournis</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.qsh70q"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3as4poj"
            >Autres dispositions</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3as4poj"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1pxezwc"
            >1 - Ill&eacute;galit&eacute; d&#39;une clause</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1pxezwc"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.49x2ik5"
            >2 - Cession des droits</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.49x2ik5"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2p2csry"
            >3 - Informations compl&eacute;mentaires</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2p2csry"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.147n2zr"
            >4 - Modification</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.147n2zr"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3o7alnk"
            >5 - Droit applicable et litiges</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3o7alnk"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.23ckvvd"
            >6 - Assistance</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.23ckvvd"></a
        ></span>
      </p>
      <p class="c6">
        <span class="c1"
          >ANNEXE 1 - Conditions g&eacute;n&eacute;rales d&rsquo;utilisation du
          service de paiement Lemonway</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0 start" start="1">
        <li class="c25" ref="h.gjdgxs">
          <span class="c12">Pr&eacute;sentation du service</span>
        </li>
      </ol>
      <p class="c6"><span class="c1">Bienvenue sur le site myFFME !</span></p>
      <p class="c6">
        <span class="c1"
          >La FFME a mis au point et d&eacute;veloppe un panel d&rsquo;outils
          simplifiant la vie des ligues, comit&eacute;s territoriaux, clubs,
          sections de club, &eacute;tablissements affili&eacute;s et membres
          associ&eacute;s et d&rsquo;une mani&egrave;re g&eacute;n&eacute;rale,
          au profit de tous ses licenci&eacute;s.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Afin d&#39;utiliser les Services, il vous faut un acc&egrave;s
          &agrave; l&#39;Internet soit directement sur votre ordinateur, soit
          par l&#39;interm&eacute;diaire d&#39;autres supports tels que le
          t&eacute;l&eacute;phone mobile. Les &eacute;ventuels frais
          correspondants &agrave; ces acc&egrave;s sont &agrave; votre seule
          charge. De plus, vous devez vous munir de tout mat&eacute;riel
          n&eacute;cessaire afin d&#39;assurer cette connexion au r&eacute;seau
          Internet.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Il vous appartient de vous assurer que votre mat&eacute;riel et vos
          logiciels ne perturberont pas ni n&#39;interf&eacute;reront avec les
          Services myFFME. Vous devez d&eacute;connecter imm&eacute;diatement
          tout appareil ou logiciel qui causerait des interf&eacute;rences avec
          les Services. Il est souhaitable que vous conserviez les Contenus
          &eacute;chang&eacute;s ou stock&eacute;s sur les Services sur une
          copie de sauvegarde afin de vous pr&eacute;munir de tous risques de
          perte.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="2">
        <li class="c25" ref="h.30j0zll">
          <span class="c12">D&eacute;finitions</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Les termes ci-apr&egrave;s d&eacute;finis et dont la premi&egrave;re
          lettre figure en lettres majuscules dans le pr&eacute;sent Accord ont
          la signification qui leur est attribu&eacute;e au pr&eacute;sent
          article.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Accord</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe le pr&eacute;sent document, ses Annexes ainsi
          que ses &eacute;ventuels avenants ult&eacute;rieurs.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Annexe</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe toute annexe au pr&eacute;sent Accord.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Compte de paiement</span
        ><span class="c1"
          >&nbsp;: compte ouvert dans les livres de Lemonway</span
        >
      </p>
      <p class="c6">
        <span class="c14">Contenus</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe les textes, photos, vid&eacute;os et toute
          autre forme de contenu mis en ligne par la FFME ou par
          l&rsquo;Organisme sur le Service.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Logo</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe le logo destin&eacute; &agrave;
          l&rsquo;identification de La FFME ou d&rsquo;un Organisme
          aupr&egrave;s du public.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Membres</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe les membres de l&rsquo;Organisme.</span
        >
      </p>
      <p class="c6" style="text-align:justify;">
        <span class="c14">Structure</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe les ligues, les comit&eacute;s territoriaux,
          les clubs, sections de club et &eacute;tablissements affili&eacute;s
          &agrave; la f&eacute;d&eacute;ration fran&ccedil;aise de la montagne
          et de l&rsquo;escalade ainsi que les membres associ&eacute;s &agrave;
          la f&eacute;d&eacute;ration fran&ccedil;aise de la montagne et de
          l&rsquo;escalade domicili&eacute;es en France m&eacute;tropolitaine ou
          DROM-COM utilisant les outils propos&eacute;s par la FFME.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Propri&eacute;t&eacute; intellectuelle myFFME</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe tout &eacute;l&eacute;ment susceptible de
          protection par le droit de la propri&eacute;t&eacute; intellectuelle
          ou industrielle, tel que notamment, le logo, la marque et les contenus
          originaux de myFFME.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Service</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe le service en ligne de La FFME disponible
          &agrave; l&rsquo;adresse http://www.myFFME.fr,
          c&rsquo;est-&agrave;-dire la possibilit&eacute; donn&eacute;e &agrave;
          un Utilisateur de soutenir un Organisme.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Utilisateur</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe un internaute navigant sur le site, en
          &eacute;tant inscrit sur le site et dont l&rsquo;identit&eacute; est
          connue.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="3">
        <li class="c25" ref="h.1fob9te">
          <span class="c12">Acceptation</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Toute utilisation ou visualisation des Services fournis par La FFME
          ou sur le site myFFME.fr, suppose que vous vous engagiez &agrave;
          respecter les pr&eacute;sentes conditions d&#39;utilisation
          (ci-apr&egrave;s &laquo; Conditions d&#39;Utilisation &raquo;).</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >En outre, les op&eacute;rations de paiement r&eacute;alis&eacute;es
          sur notre site, impliquent, conform&eacute;ment aux dispositions du
          Code mon&eacute;taire et financier, l&rsquo;utilisation d&rsquo;un
          syst&egrave;me de paiement g&eacute;r&eacute; par un
          &eacute;tablissement agr&eacute;&eacute;.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Par cons&eacute;quent, l&rsquo;utilisation d&rsquo;un moyen de
          paiement suppose l&rsquo;acceptation des conditions du syst&egrave;me
          de paiement propos&eacute; sur le site myFFME. A cette fin, La FFME a
          &eacute;tabli un partenariat avec un &eacute;tablissement
          agr&eacute;&eacute; qui permet aux Utilisateurs d&rsquo;effectuer
          leurs paiements en toute s&eacute;curit&eacute; et s&eacute;lectionne
          pour chaque projet le syst&egrave;me le plus adapt&eacute;.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Lorsque le syst&egrave;me de paiement propos&eacute; sur le site est
          celui g&eacute;r&eacute; par la soci&eacute;t&eacute; Lemonway, chaque
          Utilisateur accepte qu&rsquo;un compte de paiement soit ouvert
          &agrave; son nom et accepte les conditions g&eacute;n&eacute;rales
          d&rsquo;utilisation propos&eacute;es par cette
          soci&eacute;t&eacute;.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="4">
        <li class="c25"><span class="c12">Fonctionnement</span></li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME met &agrave; disposition de ses Utilisateurs un service en
          ligne leur permettant de r&eacute;aliser tout type de paiement au
          profit des Organismes r&eacute;f&eacute;renc&eacute;s sur le
          site.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="5">
        <li class="c25" ref="h.3znysh7">
          <span class="c12">Utilisation</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.2et92p0">
          <span class="c10">1 - Inscription</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La cr&eacute;ation d&#39;un compte La FFME implique la fourniture
          d&#39;informations permettant l&#39;identification de
          l&rsquo;Utilisateur, renseign&eacute;es dans un formulaire
          d&#39;inscription au Service. Ces informations sont ensuite
          stock&eacute;es et accessibles dans la rubrique &laquo; Espace
          personnel &raquo;, qui appara&icirc;t, d&egrave;s que vous avez ouvert
          une session avec votre identifiant.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Vous avez une obligation l&eacute;gale d&#39;identification
          d&egrave;s que vous utilisez des services vous permettant de mettre du
          contenu &agrave; disposition du public, par exemple de mettre en ligne
          votre page personnelle ou de poster un commentaire. Dans cette
          hypoth&egrave;se, en qualit&eacute; de personne physique agissant
          &agrave; titre non professionnel, vous vous engagez &agrave; :</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Lors de sa premi&egrave;re action sur myFFME, l&rsquo;Utilisateur
          doit renseigner les &eacute;l&eacute;ments suivants :</span
        >
      </p>
      <ul class="c2 lst-kix_list_2-0 start">
        <li class="c5">
          <span class="c1"
            >fournir des informations vraies, exactes, et compl&egrave;tes, et
            v&eacute;rifiables</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >les maintenir et les remettre &agrave; jour
            r&eacute;guli&egrave;rement.</span
          >
        </li>
      </ul>
      <p class="c6">
        <span class="c1"
          >La fourniture de ces informations et leur maintien &agrave; jour de
          fa&ccedil;on &agrave; permettre votre identification sont des
          conditions d&eacute;terminantes de votre droit d&#39;utiliser le
          Service.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c11" ref="h.tyjcwt">
          <span class="c10"
            >2 - Compte utilisateur, mot de passe et
            s&eacute;curit&eacute;</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Pour cr&eacute;er un Compte myFFME, vous entrez votre adresse mail et
          choisissez un mot de passe.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Ce Compte myFFME vous est strictement personnel et vous vous
          interdisez de le partager ou le c&eacute;der &agrave; qui que ce soit.
          Cela constitue une obligation essentielle de ce contrat.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Vous &ecirc;tes responsable (i) de la conservation du
          caract&egrave;re confidentiel de votre mot de passe et (ii) des
          actions qui sont effectu&eacute;es sous votre Nom de Compte et/ou avec
          votre mot de passe : si vous ouvrez une session sur un ordinateur
          public, veillez &agrave; fermer votre session lorsque vous quittez cet
          ordinateur. Ne communiquez votre mot de passe &agrave; personne.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Votre Nom et Pr&eacute;nom pourront &ecirc;tre reproduits sur les
          sites de la FFME au titre des Services disponibles que vous utilisez
          sur lesdits sites, ainsi que dans les cas de distribution des services
          sur des sites partenaires.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c11" ref="h.3dy6vkm">
          <span class="c10">3 - Modalit&eacute;s de versement</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME est charg&eacute; de collecter les paiements
          r&eacute;alis&eacute;s par les Utilisateurs au profit des Organismes
          pr&eacute;sents sur le site.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME ne peut payer aux Organismes que les sommes qui lui ont
          &eacute;t&eacute; effectivement r&eacute;gl&eacute;es.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c11" ref="h.1t3h5sf">
          <span class="c10">4 - R&eacute;siliation</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Tout utilisateur pourra r&eacute;silier son Compte myFFME &agrave;
          tout moment en ouvrant une session avec son courriel et mot de passe
          et en cliquant sur &laquo; Se d&eacute;sinscrire de La FFME
          &raquo;.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Cette r&eacute;siliation entra&icirc;nera automatiquement
          l&#39;inaccessibilit&eacute; de l&#39;ensemble des Contenus
          stock&eacute;s sur le Service et en particulier les justificatifs de
          paiement. Il est donc conseill&eacute; aux utilisateurs de veiller
          pr&eacute;alablement &agrave; la sauvegarde de leurs contenus sur un
          autre compte ou sur tout support de leur choix.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c11" ref="h.4d34og8">
          <span class="c10"
            >5 - Cr&eacute;ation d&rsquo;un compte de paiement en cas
            d&rsquo;utilisation du syst&egrave;me de paiement Lemonway</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >L&rsquo;inscription sur le site myFFME emporte &eacute;galement
          cr&eacute;ation d&rsquo;un compte de paiement g&eacute;r&eacute; par
          la soci&eacute;t&eacute; Lemonway, conform&eacute;ment aux conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation figurant en annexe. Ce
          compte de paiement r&eacute;capitule tous les paiements
          r&eacute;alis&eacute;s par les Utilisateurs.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Cette ouverture de compte est r&eacute;alis&eacute;e par
          l&#39;interm&eacute;diaire de La FFME agissant en tant qu&#39;agent
          prestataire de service de paiement pour le compte de la
          soci&eacute;t&eacute; Lemonway. L&rsquo;utilisateur mandate
          express&eacute;ment La FFME pour ouvrir un Compte de paiement et
          g&eacute;rer les flux li&eacute;s &agrave; son fonctionnement.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Lors de la cr&eacute;ation du compte de paiement, l&rsquo;utilisateur
          doit choisir un moyen de paiement dont les donn&eacute;es peuvent
          &ecirc;tre enregistr&eacute;es par la FFME.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Toutefois, les donn&eacute;es peuvent &ecirc;tre supprim&eacute;es
          &agrave; la demande de l&rsquo;Utilisateur en &eacute;crivant &agrave;
          l&rsquo;adresse mail si@ffme.fr ou par tout proc&eacute;d&eacute; qui
          serait mentionn&eacute; sur le site.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="6">
        <li class="c11" ref="h.2s8eyo1">
          <span class="c10"
            >6 - Indivisibilit&eacute; des pr&eacute;sentes conditions
            g&eacute;n&eacute;rales d&rsquo;utilisation et des conditions
            g&eacute;n&eacute;rales d&rsquo;utilisation propos&eacute;es par la
            soci&eacute;t&eacute; Lemonway</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >L&rsquo;utilisation du Service implique pour l&rsquo;Utilisateur
          d&rsquo;accepter les conditions g&eacute;n&eacute;rales
          d&rsquo;utilisation propos&eacute;es par la soci&eacute;t&eacute;
          Lemonway. A cette fin, nous vous invitons &agrave; prendre
          connaissance des conditions g&eacute;n&eacute;rales de notre
          prestataire de services de paiement figurant ci-apr&egrave;s, en
          annexe des pr&eacute;sentes. Ces conditions d&eacute;crivent le
          fonctionnement de ce compte et des op&eacute;rations de paiement qui
          pourraient &ecirc;tre r&eacute;alis&eacute;es sur notre site.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="7">
        <li class="c11" ref="h.17dp8vu">
          <span class="c10"
            >7 - D&eacute;lai de r&eacute;tractation, annulation et
            remboursement</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Les paiements en ligne ne font pas l&#39;objet d&#39;un droit de
          r&eacute;tractation. Tout paiement est ferme et
          d&eacute;finitif.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Les paiements r&eacute;alis&eacute;s par les Utilisateurs sur le site
          myFFME ne donnent lieu &agrave; aucun remboursement. En particulier,
          les billets propos&eacute;s sur le site dans le cadre des
          &eacute;v&egrave;nements g&eacute;r&eacute;s par l&rsquo;Organisme ne
          sont ni repris, ni &eacute;chang&eacute;s, ni rembours&eacute;s,
          m&ecirc;me en cas de perte ou de vol.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME agit en tant qu&rsquo;interm&eacute;diaire entre les
          Utilisateurs et l&rsquo;Organisme et ne prend pas charge le traitement
          des demandes de remboursements ou des r&eacute;tractations qui
          seraient formul&eacute;es par les Utilisateurs. La
          responsabilit&eacute; de La FFME ne serait donc &ecirc;tre
          engag&eacute;e &agrave; aucun titre sur ce point. Le remboursement du
          billet en cas d&rsquo;annulation de l&rsquo;&eacute;v&egrave;nement
          rel&egrave;ve uniquement d&rsquo;une d&eacute;cision de
          l&rsquo;Organisme.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="6">
        <li class="c25" ref="h.3rdcrjn">
          <span class="c12">Responsabilit&eacute; de La FFME</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME d&eacute;cline express&eacute;ment toute
          responsabilit&eacute; concernant le site myFFME, contenu et ses
          services. Le site La FFME son contenu et ses services sont fournis
          &laquo; en l&#39;&eacute;tat &raquo; et l&#39;utilisation que vous en
          faites est &agrave; vos propres risques. La FFME ne garantit pas que
          l&#39;acc&egrave;s au site myFFME.fr et &agrave; ses services se fera
          de mani&egrave;re continue et sans erreurs, que le site myFFME.fr sera
          sans virus, que les contenus des associations partenaires ne seront
          pas illicites, que l&#39;information pr&eacute;sente sur le site
          myFFME.fr sera pr&eacute;cise, compl&egrave;te et fiable.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >L&#39;utilisation des services du site se fait &agrave; vos propres
          risques. La FFME ne saurait &ecirc;tre tenu responsable des dommages
          &eacute;ventuels caus&eacute;s &agrave; votre syst&egrave;me
          informatique ou de la perte de donn&eacute;es r&eacute;sultant de
          l&#39;utilisation des services du site.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Aucune information, conseil ni aucun renseignement &eacute;crit ou
          oral fourni par La FFME ou un tiers ne saurait cr&eacute;er des
          garanties non &eacute;nonc&eacute;es express&eacute;ment dans le
          pr&eacute;sent contrat.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME ne saurait en aucun cas &ecirc;tre tenu pour responsable de
          tout probl&egrave;me rencontr&eacute; par suite de l&rsquo;utilisation
          du service et de toutes ses cons&eacute;quences
          &eacute;ventuelles.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >L&rsquo;utilisation des fonds par l&rsquo;Organisme ne peut en aucun
          cas engager la responsabilit&eacute; de La FFME vis &agrave; vis des
          Utilisateurs. Le choix d&#39;un Organisme se fait &agrave; la seule
          discr&eacute;tion de l&rsquo;Utilisateur et &agrave; ses propres
          risques.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME s&rsquo;attache &agrave; nouer des partenariats de
          qualit&eacute; mais ne saurait contr&ocirc;ler l&rsquo;utilisation
          faite de ces fonds. N&eacute;anmoins, tout Organisme ayant
          utilis&eacute; ses fonds de mani&egrave;re ill&eacute;gale
          av&eacute;r&eacute;e sera exclu des services fournis par La FFME. Les
          fonds collect&eacute;s au profit de cet Organisme ne lui ayant pas
          &eacute;t&eacute; encore vers&eacute;s seront alors rembours&eacute;s
          aux Utilisateurs.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="8">
        <li class="c11" ref="h.26in1rg">
          <span class="c10">1 - Contenu</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Les Utilisateurs et les Organismes sont seuls responsables de leurs
          d&eacute;clarations sur myFFME.fr.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Les descriptifs, avis, ainsi que tous les autres contenus disponibles
          sur les sites de La FFME ne garantissent pas la qualit&eacute;
          r&eacute;elle des services annonc&eacute;s et sont uniquement fournis
          &agrave; titre informatifs.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Les Visiteurs et Utilisateurs du site myFFME.fr ou de tout autre site
          exploit&eacute; par La FFME sont seuls responsables des
          d&eacute;cisions qu&rsquo;ils prennent par suite de la consultation
          des informations disponibles sur le site myFFME.fr. Ils sont
          invit&eacute;s &agrave; consulter &eacute;galement d&rsquo;autres
          sources d&rsquo;information et &agrave; effectuer leurs propres
          recherches.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME ne peut voir sa responsabilit&eacute; engag&eacute;e sur les
          contenus diffus&eacute;s et disponibles &agrave; partir des liens
          hypertextes vers d&#39;autres sites Internet pr&eacute;sents sur son
          site ou ailleurs.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Les informations pr&eacute;sent&eacute;es sur le site &eacute;tant
          soumises &agrave; de rapides changements, La FFME d&eacute;cline toute
          responsabilit&eacute; quant au contenu et ne garantit pas la
          v&eacute;racit&eacute; ni l&#39;exhaustivit&eacute; de ces
          informations.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="9">
        <li class="c11" ref="h.lnxbz9">
          <span class="c10">2 - Liens hypertextes</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Des liens sont disponibles sur les Services ou &agrave; partir des
          Services et qui les dirigent vers d&#39;autres sites Web ou
          d&#39;autres sources Internet.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME n&#39;a pas le contr&ocirc;le ni la responsabilit&eacute; des
          sites Web de tiers ou documents externes vers lesquels redirigent ces
          liens. Ces sites Web, documents et contenus de toute nature sont
          susceptibles d&#39;&ecirc;tre modifi&eacute;s, mis &agrave; jour ou
          supprim&eacute;s &agrave; tout moment par l&#39;&eacute;diteur qui
          prend la responsabilit&eacute; de leur accessibilit&eacute; au public.
          La FFME n&#39;exerce pas de contr&ocirc;le a priori sur la formulation
          de ces liens ni sur le contenu des sites vers lesquels ils renvoient
          et ne peut donc &ecirc;tre tenu responsable de ce fait.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="10">
        <li class="c11" ref="h.35nkun2">
          <span class="c10">3 - Indisponibilit&eacute;</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >myFFME.fr met tout en &oelig;uvre pour &ecirc;tre disponible &agrave;
          chaque instant et partout dans le monde. Toutefois compte tenu des
          besoins de maintenance ou pour d&rsquo;autres raisons, il peut arriver
          que les services de La FFME soient momentan&eacute;ment
          indisponibles.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME ne saurait en aucun cas &ecirc;tre tenu responsable des
          inconv&eacute;nients &eacute;ventuels r&eacute;sultant de
          l&rsquo;indisponibilit&eacute; du site myFFME.fr</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="7">
        <li class="c25" ref="h.1ksv4uv">
          <span class="c12"
            >Donn&eacute;es &agrave; caract&egrave;re personnel</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME pratique une politique de protection des donn&eacute;es
          personnelles dont les caract&eacute;ristiques sont explicit&eacute;es
          dans le document intitul&eacute; Charte relative &agrave; la
          protection des donn&eacute;es &agrave; caract&egrave;re personnel,
          accessible sur la page d&rsquo;accueil du Service et dont vous
          &ecirc;tes express&eacute;ment invit&eacute;s &agrave; prendre
          connaissance.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME d&eacute;clare notamment respecter toutes les obligations
          l&eacute;gales et r&eacute;glementaires qui lui incombent en
          mati&egrave;re de protection des donn&eacute;es &agrave;
          caract&egrave;re personnel visant &agrave; garantir notamment la
          s&eacute;curit&eacute; et la confidentialit&eacute; des donn&eacute;es
          collect&eacute;es et trait&eacute;es. Elle s&rsquo;engage &agrave; ce
          titre &agrave; respecter la r&egrave;glementation en vigueur
          applicable au traitement de donn&eacute;es &agrave; caract&egrave;re
          personnel et notamment au R&egrave;glement europ&eacute;en sur la
          protection des donn&eacute;es n&deg;2016/679 du 27 avril 2016.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME n&rsquo;est en aucun cas responsable de tout traitement de
          donn&eacute;es &agrave; caract&egrave;re personnel
          r&eacute;alis&eacute; par un Organisme au titre des activit&eacute;s
          et des op&eacute;rations r&eacute;alis&eacute;es par
          l&rsquo;interm&eacute;diation du Service, et auxquelles La FFME est
          strictement &eacute;trang&egrave;re. Il appartint &agrave; tout
          Organisme, le cas &eacute;ch&eacute;ant de respecter toutes les
          obligations r&egrave;glementaires qui lui incombent &agrave; ce
          titre.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Dans le cadre de leurs relations contractuelles, les Parties
          s&rsquo;engagent &agrave; respecter la r&egrave;glementation en
          vigueur applicable au traitement de donn&eacute;es &agrave;
          caract&egrave;re personnel et notamment au R&egrave;glement
          europ&eacute;en sur la protection des donn&eacute;es n&deg;2016/679 du
          27 avril 2016.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="8">
        <li class="c25" ref="h.44sinio">
          <span class="c12">Responsabilit&eacute; des utilisateurs</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.2jxsxqh">
          <span class="c10"
            >1 - Les r&egrave;gles de conduite &agrave; respecter</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Vous &ecirc;tes responsable de tout Contenu (i) que vous rendez
          accessible &agrave; des tiers, (ii) que vous envoyez par courrier
          &eacute;lectronique ou (iii) que vous stockez sur des espaces
          personnels du Service ou (iv) sur des espaces accessibles &agrave; des
          tiers, (v) que vous t&eacute;l&eacute;chargez, ou (vi) transmettez de
          toute autre mani&egrave;re par le Service.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Vous &ecirc;tes par ailleurs conscient que tout Contenu accessible au
          public mis en ligne par un des utilisateurs du Service rel&egrave;ve
          de la responsabilit&eacute; de celui-ci, La FFME n&#39;exer&ccedil;ant
          pas de contr&ocirc;le a priori sur lesdits Contenus. La FFME n&#39;a
          pas d&#39;obligation g&eacute;n&eacute;rale de surveillance du contenu
          stock&eacute; sur ses Services par les utilisateurs.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Vous vous interdisez dans le cadre de l&#39;utilisation des Services
          de vous livrer &agrave; des actes, de quelque nature que ce soit
          (notamment &agrave; des actes d&rsquo;envoi, diffusion,
          &eacute;dition, &eacute;mission, mise en ligne, publication ou de
          toute autre mani&egrave;re), qui seraient contraires &agrave; la loi
          fran&ccedil;aise, porteraient atteinte &agrave; l&#39;ordre public
          fran&ccedil;ais, ou aux droits d&#39;un tiers. En particulier, et sans
          que cette liste soit limitative, vous vous interdisez de :</span
        >
      </p>
      <ul class="c2 lst-kix_list_3-0 start">
        <li class="c5">
          <span class="c1"
            >Vous livrer &agrave; des actes constitutifs d&#39;apologie des
            crimes contre l&#39;humanit&eacute;, de n&eacute;gation de
            g&eacute;nocides, d&#39;incitation &agrave; la violence, &agrave; la
            haine raciale ou &agrave; la pornographie infantile ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Vous livrer &agrave; des actes de diffamation, d&#39;injure, de
            menaces, de chantage, de harc&egrave;lement ou &agrave; des actes
            attentatoires &agrave; la vie priv&eacute;e ou &agrave; la
            dignit&eacute; humaine ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Porter atteinte d&#39;une quelconque mani&egrave;re aux
            utilisateurs mineurs, de les inciter &agrave; se mettre en danger
            d&#39;une quelconque mani&egrave;re ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >En particulier, transmettre, diffuser, &eacute;diter, publier ou
            rendre accessible tout Contenu qui pourrait &ecirc;tre constitutif,
            sans que cette liste soit limitative, d&#39;incitation &agrave; la
            r&eacute;alisation de crimes et d&eacute;lits ; de propagation de
            fausses nouvelles ou d&#39;informations financi&egrave;res couvertes
            par le secret, de m&ecirc;me que tout Contenu destin&eacute;
            &agrave; repr&eacute;senter ou proposer &agrave; la vente des objets
            et/ou des ouvrages, des logiciels, des Contenus interdits par la loi
            ou portant atteinte aux droits de tiers ; d&#39;atteinte &agrave;
            l&#39;autorit&eacute; de la justice ; d&#39;atteinte &agrave; la vie
            priv&eacute;e, &agrave; la protection des donn&eacute;es
            personnelles ou au secret des correspondance ; de divulgation
            d&#39;informations couvertes par un secret relatives, notamment
            &agrave; l&#39;adoption pl&eacute;ni&egrave;re, &agrave; un
            proc&egrave;s en cours, au suicide, ou &agrave; la sant&eacute;
            d&#39;un tiers, ou &agrave; une situation patrimoniale ou
            financi&egrave;re individuelle couverte par le secret ou par le
            droit &agrave; l&#39;intimit&eacute; de la vie priv&eacute;e; ou
            encore d&#39;acte mettant en p&eacute;ril des mineurs notamment par
            la fabrication, la transmission, la diffusion ou
            l&#39;accessibilit&eacute; de messages &agrave; caract&egrave;re
            violent ou pornographique, de nature &agrave; porter atteinte
            &agrave; la dignit&eacute; humaine ou de nature &agrave; permettre
            la fabrication d&#39;explosifs ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Tenter d&#39;induire en erreur d&#39;autres utilisateurs en
            usurpant l&#39;identit&eacute; ou une d&eacute;nomination sociale ou
            en portant atteinte &agrave; l&#39;image ou &agrave; la
            r&eacute;putation d&#39;autres personnes et/ou en vous faisant
            passer pour un tiers ou pour un employ&eacute;, un service
            habilit&eacute; ou un affili&eacute; de La FFME ! ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Falsifier des donn&eacute;es, messages ou documents, des
            en-t&ecirc;tes de messages ou de donn&eacute;es d&#39;identification
            ou de connexion &agrave; un Service ou manipuler de toute autre
            mani&egrave;re un identifiant de mani&egrave;re &agrave; dissimuler
            l&#39;origine de la transmission d&#39;un Contenu via le Service
            ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Vous livrer &agrave; une violation des droits de
            propri&eacute;t&eacute; intellectuelle (notamment en mati&egrave;re
            de musique, vid&eacute;o, animations, jeux, logiciels, bases de
            donn&eacute;es, images, sons et textes) ou tout autre droit de
            propri&eacute;t&eacute; (ci-apr&egrave;s d&eacute;nomm&eacute;s
            collectivement les &laquo; Droits &raquo;) appartenant &agrave;
            autrui ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Exp&eacute;dier ou faire exp&eacute;dier des courriers
            &eacute;lectroniques ou des messages instantan&eacute;s &agrave; des
            personnes qui ne les ont pas sollicit&eacute;s ou sans avoir
            respect&eacute; leurs droits reconnus par la loi, tels que des
            publicit&eacute;s, du mat&eacute;riel promotionnel, des
            cha&icirc;nes de lettres ou toute autre forme de prospection directe
            non sollicit&eacute;e ; mettre en ligne des messages &agrave;
            caract&egrave;re promotionnel sur les Services La FFME ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >T&eacute;l&eacute;charger sciemment, afficher, &eacute;mettre,
            diffuser, transmettre ou rendre accessible de toute autre
            mani&egrave;re tout Contenu comprenant ou constituant des virus
            informatiques ou tout autre code ou programme informatique
            con&ccedil;us pour interrompre, d&eacute;truire, d&eacute;tourner ou
            limiter les fonctionnalit&eacute;s ou les performances de tout
            logiciel, ordinateur, service ou outil de communications
            &eacute;lectroniques sans que cette &eacute;num&eacute;ration ne
            soit limitative ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Perturber, ralentir, bloquer ou alt&eacute;rer le flux normal des
            donn&eacute;es &eacute;chang&eacute;es dans le cadre du Service,
            acc&eacute;l&eacute;rer le rythme de d&eacute;filement des Contenus
            du Service de telle mani&egrave;re que le fonctionnement du Service
            soit modifi&eacute; ou alt&eacute;r&eacute; ou commettre toute autre
            action ayant un effet perturbateur &eacute;quivalent sur les
            fonctionnalit&eacute;s du Service ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Acc&eacute;der frauduleusement, se maintenir, entraver ou perturber
            les syst&egrave;mes d&#39;information de La FFME et notamment des
            Services, les serveurs, les r&eacute;seaux connect&eacute;s au
            Service, ou refuser de se conformer aux conditions requises, aux
            proc&eacute;dures, aux r&egrave;gles g&eacute;n&eacute;rales ou aux
            dispositions r&eacute;glementaires applicables aux r&eacute;seaux
            connect&eacute;s au Service.</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.z337ya">
          <span class="c10">2 - Le signalement des abus</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Si vous constatez ou estimez que du Contenu stock&eacute; sur le
          Service pr&eacute;sente un caract&egrave;re manifestement illicite,
          vous pouvez le signaler &agrave; La FFME en envoyant un mail &agrave;
          si@ffme.fr</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Votre notification doit imp&eacute;rativement permettre &agrave; La
          FFME d&#39;identifier le Contenu illicite et le chemin
          d&#39;acc&egrave;s &agrave; ce contenu. Cette notification doit
          contenir les &eacute;l&eacute;ments suivants :</span
        >
      </p>
      <ul class="c2 lst-kix_list_4-0 start">
        <li class="c5">
          <span class="c1">Vos noms, pr&eacute;noms et domicile ;</span>
        </li>
        <li class="c5">
          <span class="c1"
            >Une copie du contenu qui vous semble litigieux ainsi que
            l&#39;adresse URL de la page concern&eacute;e ou les nom et
            pr&eacute;nom de l&rsquo;auteur du contenu litigieux ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Les motifs pour lesquels vous pensez que le contenu doit &ecirc;tre
            retir&eacute;.</span
          >
        </li>
      </ul>
      <p class="c6">
        <span class="c1"
          >Toute notification d&#39;un contenu abusivement
          pr&eacute;sent&eacute; comme illicite dans le but d&#39;en obtenir le
          retrait vous exposerait &agrave; des sanctions civiles et/ou
          p&eacute;nales.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c11" ref="h.3j2qqm3">
          <span class="c10">3 - Inscription des Utilisateurs</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Une seule inscription utilisateur par personne physique et morale est
          autoris&eacute;e. En cas de fraude, La FFME se r&eacute;serve le droit
          de supprimer tous les comptes de ces utilisateurs.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c11" ref="h.1y810tw">
          <span class="c10"
            >4 - Suspension du compte cr&eacute;&eacute; par
            l&rsquo;Utilisateur</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME pourra proc&eacute;der &agrave; la suspension
          imm&eacute;diate du compte cr&eacute;&eacute; sur le site my FFME, de
          plein droit et sans pr&eacute;avis :</span
        >
      </p>
      <ul class="c2 lst-kix_list_5-0 start">
        <li class="c5">
          <span class="c1"
            >En cas de non-respect des pr&eacute;sentes conditions
            g&eacute;n&eacute;rales ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >En cas de non-respect des conditions g&eacute;n&eacute;rales
            d&rsquo;utilisation propos&eacute;es par Lemonway ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >En cas de blocage du compte de paiement par Lemonway ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >En cas de donn&eacute;es d&#39;identification inexactes,
            p&eacute;rim&eacute;es ou incompl&egrave;tes ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >En cas de risque de fraude, de blanchiment de capitaux ou
            financement du terrorisme ou de risque pouvant affecter la
            s&eacute;curit&eacute; du Compte de paiement ou le fonctionnement du
            site La FFME ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >En cas de r&eacute;ception par Lemonway d&rsquo;un nombre important
            de demandes de remboursement, d&rsquo;annulation d&rsquo;ordres ou
            de contestation pour ordres non autoris&eacute;s ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Lorsque des Contenus ont fait l&rsquo;objet de signalisations par
            les Internautes ;
          </span>
        </li>
      </ul>
      <p class="c6">
        <span class="c1"
          >Le cas &eacute;ch&eacute;ant, La FFME vous contactera dans les
          meilleurs d&eacute;lais possibles pour tenter de rem&eacute;dier
          &agrave; la difficult&eacute; soulev&eacute;e.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c11" ref="h.4i7ojhp">
          <span class="c10"
            >5 - Suppression du compte cr&eacute;&eacute; par
            l&rsquo;Utilisateur</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME se r&eacute;serve le droit de supprimer le compte
          cr&eacute;&eacute; par l&rsquo;Utilisateur qui viendrait &agrave;
          :</span
        >
      </p>
      <ul class="c2 lst-kix_list_6-0 start">
        <li class="c5">
          <span class="c1"
            >Pirater le site de quelque mani&egrave;re que ce soit ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Contrevenir aux obligations des pr&eacute;sentes conditions
            g&eacute;n&eacute;rales ou aux obligations contenues dans les
            conditions g&eacute;n&eacute;rales d&rsquo;utilisation
            propos&eacute;es par Lemonway ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Utiliser des robots de fausses inscriptions, ou de faux envois de
            mail ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >S&rsquo;inscrire sous le nom d&rsquo;une autre personne ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >Fournir des informations personnelles fausses ou
            inv&eacute;rifiables ;</span
          >
        </li>
        <li class="c5">
          <span class="c1">S&rsquo;inscrire plusieurs fois. </span>
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.2xcytpi">
          <span class="c10">6 - Comportement illicite</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >L&rsquo;utilisateur s&#39;interdit d&#39;obtenir les adresses e-mail
          des autres utilisateurs afin de nuire au fonctionnement normal de leur
          courrier &eacute;lectronique. Tous les mails de type &quot; Mails
          Bombing &quot;, &quot; Flamming &quot;, &quot; Spamming &quot; sont
          interdits.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c11" ref="h.1ci93xb">
          <span class="c10">7 - Indemnisation</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Au titre de votre utilisation du site myFFME.fr, vous acceptez
          d&#39;indemniser La FFME, ses fournisseurs, ses employ&eacute;s, ses
          dirigeants et autres tierces partie de tous les dommages directs,
          indirects, cons&eacute;cutifs, sp&eacute;ciaux, fortuits, accessoires,
          exemplaires, de toute perte, toute demande de
          dommages-int&eacute;r&ecirc;ts, incluant les honoraires d&#39;avocat
          et autres frais judiciaires, occasionn&eacute;s par vous-m&ecirc;me ou
          tout tiers r&eacute;sultant de ou &eacute;tant associ&eacute; &agrave;
          votre utilisation du Logiciel et/ou r&eacute;sultant de toute
          violation des &laquo; Conditions G&eacute;n&eacute;rales
          d&rsquo;utilisation &raquo;</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="9">
        <li class="c25" ref="h.3whwml4">
          <span class="c12"
            >Copyright et propri&eacute;t&eacute; intellectuelle</span
          >
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.2bn6wsx">
          <span class="c10"
            >1 - Droits de propri&eacute;t&eacute; intellectuelle de La
            FFME</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Toutes les informations, &eacute;crans, graphismes, donn&eacute;es,
          textes, logiciels, musiques, sons, photographies, images,
          vid&eacute;os, messages, avis, opinions, ou commentaires de toute
          sorte ou tous autres mat&eacute;riels pr&eacute;sents sur myFFME.fr
          sont la propri&eacute;t&eacute; exclusive de La FFME. De ce fait toute
          reproduction, modification, distribution de ces &eacute;l&eacute;ments
          est interdite sans l&#39;accord pr&eacute;alable expr&egrave;s de son
          propri&eacute;taire.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Les Services ainsi que tout logiciel, contenu ou mat&eacute;riel
          utilis&eacute; par La FFME en relation avec les Services
          (ci-apr&egrave;s d&eacute;nomm&eacute; &laquo; Logiciels &raquo;)
          contiennent des informations confidentielles qui sont
          prot&eacute;g&eacute;es notamment par le code de la
          propri&eacute;t&eacute; intellectuelle. Sans pr&eacute;judice des
          conditions particuli&egrave;res ou licence d&#39;utilisation
          auxquelles vous souscrivez lors de l&#39;utilisation d&#39;un service
          sp&eacute;cifique, La FFME vous conc&egrave;de une licence
          personnelle, non cessible, et non exclusive d&#39;utiliser le Logiciel
          sous sa forme ex&eacute;cutable sur un seul ordinateur &agrave; la
          fois (ou t&eacute;l&eacute;phone portable ou autre appareil
          assimil&eacute; permettant l&#39;acc&egrave;s au Service),
          &eacute;tant entendu qu&#39;il vous est interdit de copier, modifier,
          int&eacute;grer les Logiciels dans une &oelig;uvre
          d&eacute;riv&eacute;e, ou d&#39;user de toute autre m&eacute;thode
          pour tenter d&#39;acc&eacute;der au code source ou aux protocoles des
          Logiciels. La FFME se r&eacute;serve la facult&eacute; de corriger
          tout dysfonctionnement du Logiciel. Vous n&#39;avez pas le droit de
          vendre, c&eacute;der, sous-licencier, donner en garantie ou
          transmettre de toute autre mani&egrave;re les Logiciels. Vous vous
          engagez en outre &agrave; ne pas acheter, exp&eacute;dier,
          transporter, exporter, ou r&eacute;exporter les Logiciels en dehors de
          l&#39;Union europ&eacute;enne, sauf dans les cas autoris&eacute;s par
          la loi.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Vous reconnaissez &eacute;galement que le Contenu compris dans des
          publicit&eacute;s, les informations qui vous sont
          pr&eacute;sent&eacute;es via le Service ou par des annonceurs tiers
          sont prot&eacute;g&eacute;s par le droit d&#39;auteur, le droit des
          marques, le droit des brevets, ou tout autre droit reconnu par la
          l&eacute;gislation en vigueur.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Pour ce qui concerne le Contenu que vous stockez ou mettez en ligne
          sur les Services en vue de le rendre accessible &agrave; des tiers,
          vous accordez &agrave; La FFME, un droit non-exclusif et gratuit
          d&#39;utilisation permettant &agrave; La FFME de reproduire, publier
          et diffuser ce Contenu aux fins de fourniture du Service, de sa
          promotion et de sa distribution, et ce, sur tout support
          &eacute;lectromagn&eacute;tique et par tout moyen de communication
          &eacute;lectronique, sur les sites de La FFME et sur les sites de
          partenaires ou de tiers. Ce droit est accord&eacute; pour la
          dur&eacute;e pendant laquelle vous d&eacute;ciderez d&#39;inclure le
          Contenu sur le Service. Vous garantissez &agrave; La FFME
          d&rsquo;avoir pr&eacute;alablement obtenu les droits
          n&eacute;cessaires &agrave; cette exploitation et que le contenu est
          conforme &agrave; la loi et ne porte pas atteinte aux droits de
          tiers.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c11" ref="h.qsh70q">
          <span class="c10"
            >2 - Interdiction d&#39;exploitation des services fournis</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Le Service est la propri&eacute;t&eacute; de La FFME et/ou de ses
          ayants droits. L&#39;utilisation personnelle des Services offerts sur
          le Site La FFME exclut l&#39;exploitation &agrave; toute autre fin.
          Vous vous interdisez notamment toute exploitation commerciale ou
          &agrave; but lucratif de tout ou partie des Services ou de
          l&#39;acc&egrave;s aux Services. En cons&eacute;quence, vous vous
          interdisez de reproduire, copier, vendre, revendre, &eacute;changer ou
          exploiter dans un but commercial ou moyennant une quelconque
          r&eacute;mun&eacute;ration, profit ou avantage, tout ou partie du
          Service, toute utilisation du Service, ou tout droit
          d&#39;acc&egrave;s au Service.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="10">
        <li class="c25" ref="h.3as4poj">
          <span class="c12">Autres dispositions</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.1pxezwc">
          <span class="c10">1 - Ill&eacute;galit&eacute; d&#39;une clause</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Le fait qu&rsquo;une disposition de ces &laquo; Conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation du Service La FFME &raquo;
          se r&eacute;v&egrave;le &ecirc;tre, en totalit&eacute; ou en partie,
          illicite ou inapplicable pour quelque raison que ce soit, sera sans
          incidence sur la validit&eacute; des autres clauses des
          pr&eacute;sentes conditions d&rsquo;utilisation.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c11" ref="h.49x2ik5">
          <span class="c10">2 - Cession des droits</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >En cas d&rsquo;acquisition de la soci&eacute;t&eacute; La FFME par
          une tierce partie ou de fusion entre La FFME et une tierce partie, La
          FFME se r&eacute;serve le droit de c&eacute;der tout ou partie de ses
          droits et obligations au titre des pr&eacute;sentes conditions
          d&rsquo;utilisation &agrave; la dite tierce partie ou &agrave; la
          nouvelle entit&eacute; r&eacute;sultant de la fusion.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c11" ref="h.2p2csry">
          <span class="c10">3 - Informations compl&eacute;mentaires</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Toutes les questions concernant les services propos&eacute;s par La
          FFME sont les bienvenues et peuvent &ecirc;tre pos&eacute;es par mail
          &agrave; si@ffme.fr</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c11" ref="h.147n2zr">
          <span class="c10">4 - Modification</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME se r&eacute;serve la possibilit&eacute; de modifier les
          pr&eacute;sentes dispositions &agrave; tout moment et sans
          pr&eacute;avis. Les nouvelles conditions d&rsquo;utilisation du site
          La FFME seront disponibles sur le site myFFME.fr et la continuation de
          l&rsquo;utilisation du service La FFME vaudra acceptation de ces
          conditions. Ainsi, nous vous conseillons de les consulter
          r&eacute;guli&egrave;rement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c11" ref="h.3o7alnk">
          <span class="c10">5 - Droit applicable et litiges</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Les pr&eacute;sentes CGU sont r&eacute;gies,
          interpr&eacute;t&eacute;es et appliqu&eacute;es conform&eacute;ment au
          droit fran&ccedil;ais.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Si l&rsquo;utilisation du site La FFME contrevient &agrave; la
          l&eacute;gislation du pays dans lequel r&eacute;side l&#39;utilisateur
          ou le Visiteur, il convient pour celui-ci de ne pas utiliser le site
          myFFME.fr. Il est le mieux plac&eacute; pour juger de
          l&rsquo;opportunit&eacute; et de la l&eacute;galit&eacute; de cette
          utilisation. L&#39;utilisation du site myFFME.fr se fait aux propres
          risques de chaque utilisateur.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME fera tout son possible pour trouver les moyens satisfaisants
          de r&eacute;soudre les probl&egrave;mes rencontr&eacute;s sur son
          site. Si cela ne s&rsquo;av&eacute;rait pas possible, La FFME pourrait
          solliciter une m&eacute;diation, un arbitrage, ou l&rsquo;introduction
          d&rsquo;une instance aupr&egrave;s d&rsquo;un tribunal.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="6">
        <li class="c11" ref="h.23ckvvd">
          <span class="c10">6 - Assistance</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >En cas de r&eacute;clamation, de plainte &eacute;manant de tiers ou
          d&#39;action judiciaire form&eacute;e &agrave; l&#39;encontre de La
          FFME, ses repr&eacute;sentants, ses salari&eacute;s, ou ses
          partenaires, cons&eacute;cutive &agrave; l&#39;envoi, l&#39;affichage,
          la diffusion, l&#39;&eacute;mission, l&#39;&eacute;dition, la mise en
          ligne ou la transmission de Contenu par vos soins sur les Services, ou
          du fait de votre utilisation des Services, vous vous engagez &agrave;
          assister La FFME, ses repr&eacute;sentants, salari&eacute;s, ou
          partenaires dans la pr&eacute;paration de leur r&eacute;ponse ou
          d&eacute;fense en leur communiquant tous documents, donn&eacute;es ou
          informations utiles &agrave; cette fin.</span
        >
      </p>
      <p class="c26">
        <span class="c24"
          >Annexe 1 - Conditions g&eacute;n&eacute;rales d&rsquo;utilisation du
          service de paiement Lemonway</span
        >
      </p>
      <p class="c26 c19"><span class="c24"></span></p>
      <p class="c0"><span class="c7">Version 2020</span></p>
      <p class="c0 c19"><span class="c7"></span></p>
      <p class="c0"><span class="c1">Le Titulaire d&rsquo;une part</span></p>
      <p class="c0"><span class="c1">et,</span></p>
      <p class="c0">
        <span class="c1"
          >Lemonway, soci&eacute;t&eacute; par actions simplifi&eacute;e dont le
          si&egrave;ge social est situ&eacute; au 8 rue du Sentier, 75002 Paris,
          France, immatricul&eacute;e au registre du commerce et des
          soci&eacute;t&eacute;s de Paris sous le num&eacute;ro 500 486 915
          (&laquo; Lemonway&laquo; ), agr&eacute;&eacute;e le 24 d&eacute;cembre
          2012 par l&rsquo;Autorit&eacute; de Contr&ocirc;le Prudentiel et de
          R&eacute;solution (&laquo; ACPR &raquo;, France, site internet
          http://acpr.banque-france.fr/), 4 place de Budapest CS 92459, 75436
          Paris, en qualit&eacute; d&rsquo;Etablissement de Paiement hybride,
          sous le num&eacute;ro 16 568 J, d&rsquo;autre part.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >D&eacute;sign&eacute;s individuellement une &laquo; Partie &raquo; et
          ensemble les &laquo; Parties &raquo;.</span
        >
      </p>
      <p class="c0 c19"><span class="c1"></span></p>
      <ol class="c2 lst-kix_list_1-0" start="11">
        <li class="c20" ref="h.ihv636">
          <span class="c12">AVERTISSEMENT</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut &agrave; tout moment consulter ce Contrat-cadre, le
          reproduire, le stocker sur son ordinateur ou sur un autre support, le
          transf&eacute;rer par courrier &eacute;lectronique ou l&rsquo;imprimer
          sur papier de mani&egrave;re &agrave; le conserver.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >A tout instant, conform&eacute;ment &agrave; la loi, il est possible
          de v&eacute;rifier l&rsquo;agr&eacute;ment de Lemonway sur le site
          regafi.fr, en qualit&eacute; d&rsquo;&eacute;tablissement de paiement.
          Le Site Internet de l&rsquo;&eacute;tablissement de paiement Lemonway
          est le suivant : www.lemonway.com</span
        >
      </p>
      <p class="c29"><span class="c12">Ta des mati&egrave;res</span></p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.ihv636"
            >AVERTISSEMENT</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.ihv636"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1</a
          ></span
        >
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.32hioqz"
            >1. OBJET</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.32hioqz"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1hmsyys"
            >2. D&Eacute;FINITIONS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1hmsyys"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.41mghml"
            >3. OUVERTURE DE COMPTE DE PAIEMENT</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.41mghml"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2grqrue"
            >4. CR&Eacute;DITER LE COMPTE DE PAIEMENT</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2grqrue"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.vx1227"
            >5. D&Eacute;BITER UN COMPTE DE PAIEMENT PAR VIREMENT</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.vx1227"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3fwokq0"
            >6. REPORTING</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3fwokq0"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1v1yuxt"
            >7. DUR&Eacute;E DU CONTRAT-CADRE ET DATE D&rsquo;EFFET</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1v1yuxt"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4f1mdlm"
            >8. R&Eacute;CLAMATIONS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4f1mdlm"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2u6wntf"
            >9. FRAIS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2u6wntf"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.19c6y18"
            >10. S&Eacute;CURIT&Eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.19c6y18"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3tbugp1"
            >11. RESPONSABILIT&Eacute;S</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3tbugp1"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.28h4qwu"
            >12. PROTECTION DES FONDS CLIENTS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.28h4qwu"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.nmf14n"
            >13. D&Eacute;C&Egrave;S &ndash; COMPTE DE PAIEMENT INACTIF &ndash;
            PROCURATION</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.nmf14n"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.37m2jsg"
            >14. PROPRI&Eacute;T&Eacute; INTELLECTUELLE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.37m2jsg"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1mrcu09"
            >15. CONFIDENTIALIT&Eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1mrcu09"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.46r0co2"
            >16. COLLECTE ET TRAITEMENT DES DONNEES A CARACTERE PERSONNEL</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.46r0co2"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2lwamvv"
            >17. CONVENTION DE PREUVE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2lwamvv"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.111kx3o"
            >18. BLOCAGE ET GEL DU COMPTE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.111kx3o"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3l18frh"
            >19. R&Eacute;SILIATION DU CONTRAT-CADRE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3l18frh"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.206ipza"
            >20. MODIFICATION DU CONTRAT-CADRE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.206ipza"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4k668n3"
            >21. GENERALITES</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4k668n3"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2zbgiuw"
            >22. DROIT APPLICABLE ET JURIDICTIONS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2zbgiuw"></a
        ></span>
      </p>
      <p class="c0 c19"><span class="c3"></span></p>
      <ol class="c2 lst-kix_list_1-0" start="12">
        <li class="c20" ref="h.32hioqz"><span class="c12">1. OBJET</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le pr&eacute;sent &laquo; Contrat-cadre de Services de paiement
          &raquo; est consultable &agrave; tout moment sur le Site Internet
          (https://www.lemonway.com). Il r&eacute;git les termes et conditions
          de l&rsquo;ouverture d&rsquo;un Compte de paiement par Lemonway au nom
          du Titulaire et la fourniture de Services de paiement. Le Titulaire
          est invit&eacute; &agrave; les lire avec attention avant de les
          accepter.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="13">
        <li class="c20" ref="h.1hmsyys">
          <span class="c12">2. D&Eacute;FINITIONS</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les termes utilis&eacute;s dans le pr&eacute;sent Contrat-cadre
          auront, lorsqu&rsquo;ils sont utilis&eacute;s avec la premi&egrave;re
          lettre en majuscule et ind&eacute;pendamment du fait qu&rsquo;ils
          soient utilis&eacute;s au singulier ou au pluriel, le sens
          d&eacute;fini ci-apr&egrave;s :</span
        >
      </p>
      <ul class="c2 lst-kix_list_7-0 start">
        <li class="c0 c18">
          <span class="c1"
            >B&eacute;n&eacute;ficiaire : personne physique ou morale
            d&eacute;sign&eacute;e par le Titulaire comme destinataire
            d&rsquo;un virement provenant de son Compte de paiement. Le
            B&eacute;n&eacute;ficiaire peut &ecirc;tre le Titulaire.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Contrat-cadre : Contrat-cadre de Services de paiement tel que
            d&eacute;fini &agrave; l&rsquo;article L. 314-12 du Code
            mon&eacute;taire et financier conclu entre Lemonway et le Titulaire
            compos&eacute; des pr&eacute;sentes CGU.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Compte de paiement : Compte de paiement au sens de l&rsquo;article
            L. 314-1 du Code mon&eacute;taire et financier ouvert dans les
            livres de Lemonway &agrave; l&rsquo;effet d&rsquo;inscrire au
            d&eacute;bit et au cr&eacute;dit les Op&eacute;rations de paiement,
            les frais dus par le Titulaire et toute contrepassation en lien avec
            ses Op&eacute;rations et de compenser ces montants &agrave; la date
            de leur inscription aux fins de faire appara&icirc;tre un solde
            net.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Jour Ouvr&eacute; : un jour autre qu&rsquo;un samedi, un dimanche
            ou un jour f&eacute;ri&eacute; en France.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Op&eacute;rations de paiement : action consistant &agrave; verser,
            transf&eacute;rer ou retirer des fonds &agrave; partir ou &agrave;
            destination du Compte de paiement, ind&eacute;pendamment de toute
            obligation sous-jacente entre le Payeur et le
            B&eacute;n&eacute;ficiaire.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Ordre de paiement : consentement du Titulaire donn&eacute; suivant
            le dispositif personnalis&eacute; et les proc&eacute;dures convenus
            entre le Titulaire et Lemonway, afin d&rsquo;autoriser une
            Op&eacute;ration de paiement.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Partenaire : soci&eacute;t&eacute; commerciale exploitant le Site
            Partenaire et utilisant les services de Lemonway.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Payeur : Personne physique ou morale pouvant &ecirc;tre le
            Titulaire en cas d&rsquo;alimentation de son propre Compte de
            paiement ou utilisatrice du Site Partenaire.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Provision : montant disponible inscrit au cr&eacute;dit du Compte
            de paiement pouvant &ecirc;tre affect&eacute; &agrave;
            l&rsquo;ex&eacute;cution d&rsquo;Op&eacute;rations de paiement
            futures, d&eacute;termin&eacute; par Lemonway apr&egrave;s prise en
            compte des Op&eacute;rations de paiement en cours et du montant de
            la Provision bloqu&eacute;e d&eacute;finie &agrave; l&rsquo;article
            5.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Prestataire de Services de paiement (PSP) tiers : prestataire de
            Services de paiement (&eacute;tablissement financier)
            agr&eacute;&eacute; par une autorit&eacute; d&rsquo;un Etat partie
            &agrave; l&rsquo;Espace &Eacute;conomique Europ&eacute;en ayant
            ouvert un compte bancaire ou de paiement au nom du Titulaire.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Services de paiement : services fournis par Lemonway en application
            du Contrat-cadre et comprenant l&rsquo;ex&eacute;cution de virements
            et l&rsquo;acquisition d&rsquo;ordres de paiement par cartes et par
            virements, ainsi que l&rsquo;encaissement de ch&egrave;ques.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Site Internet : D&eacute;signe le site internet
            http://www.lemonway.com &agrave; partir duquel Lemonway propose les
            Services de paiement.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Site Partenaire ou Site : D&eacute;signe le site et/ou
            l&rsquo;application exploit&eacute; par le Partenaire en vue de
            permettre &agrave; des personnes de r&eacute;aliser des
            Op&eacute;rations de paiement.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Titulaire : Personne physique ou morale disposant d&rsquo;un Compte
            de paiement permettant d&rsquo;effectuer et/ou recevoir une
            Op&eacute;ration de paiement.</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-0" start="14">
        <li class="c20" ref="h.41mghml">
          <span class="c12">3. OUVERTURE DE COMPTE DE PAIEMENT</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire doit satisfaire la proc&eacute;dure d&rsquo;ouverture de
          Compte de paiement ci-apr&egrave;s d&eacute;crite.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="7">
        <li class="c9">
          <span class="c10"
            >3.1 D&eacute;clarations pr&eacute;alables du Titulaire</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire, personne morale ou physique majeure capable,
          d&eacute;clare express&eacute;ment avoir la capacit&eacute; et/ou
          avoir re&ccedil;u les autorisations requises pour utiliser les
          Services de paiement fournis par Lemonway et garantit Lemonway contre
          toute responsabilit&eacute; pouvant r&eacute;sulter pour elle
          d&rsquo;une fausse d&eacute;claration.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire d&eacute;clare agir pour son compte. Le Titulaire a
          l&rsquo;obligation d&rsquo;utiliser les services fournis par Lemonway
          de bonne foi, &agrave; des fins licites et dans le respect des
          dispositions du Contrat-cadre.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire personne physique d&eacute;clare &ecirc;tre
          r&eacute;sident en France ou dans l&rsquo;Espace &Eacute;conomique
          Europ&eacute;en.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Pour tout autre pays de r&eacute;sidence ou d&rsquo;immatriculation,
          Lemonway se garde la possibilit&eacute; d&rsquo;&eacute;tudier la
          demande d&rsquo;ouverture d&rsquo;un Compte de paiement afin de se
          conformer au p&eacute;rim&egrave;tre g&eacute;ographique de son
          agr&eacute;ment.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="8">
        <li class="c9">
          <span class="c10"
            >3.2 Transmission des documents d&rsquo;identification</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway informe le Titulaire que conform&eacute;ment aux obligations
          d&rsquo;identification relatives &agrave; la lutte contre le
          blanchiment de capitaux et de financement du terrorisme,
          l&rsquo;ouverture du Compte de paiement est conditionn&eacute;e
          &agrave; la transmission et validation des documents
          d&rsquo;identification requis en fonction de la qualit&eacute; du
          Titulaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway se r&eacute;serve le droit de demander tout autre document
          ou information compl&eacute;mentaire, pour lui permettre
          d&rsquo;effectuer les v&eacute;rifications utiles au respect de ses
          obligations l&eacute;gales y compris en mati&egrave;re de lutte contre
          le blanchiment de capitaux.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire accepte que le Site Partenaire fasse parvenir ces
          documents &agrave; Lemonway par transmission informatique et
          t&eacute;l&eacute;versement sur les syst&egrave;mes informatiques de
          Lemonway.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="9">
        <li class="c9">
          <span class="c10"
            >3.3 Modalit&eacute;s d&rsquo;approbation du Contrat-cadre</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Contrat-cadre doit &ecirc;tre approuv&eacute; par le Titulaire par
          tout moyen. Ce dernier reconna&icirc;t avoir attentivement lu, compris
          et accept&eacute; le Contrat-cadre dans son
          int&eacute;gralit&eacute;.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="10">
        <li class="c9">
          <span class="c10"
            >3.4 Acceptation d&rsquo;ouverture de Compte de paiement</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway peut refuser d&rsquo;ouvrir un Compte de paiement pour tout
          motif sans avoir &agrave; justifier sa d&eacute;cision. Celle-ci ne
          donnera lieu &agrave; aucun dommage et int&eacute;r&ecirc;t.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Sous r&eacute;serve de l&rsquo;autorisation expresse et &eacute;crite
          de son repr&eacute;sentant l&eacute;gal, le mineur de moins de
          dix-huit (18) ans peut ouvrir un Compte de paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >A cet effet, les documents d&rsquo;identification requis seront ceux
          du repr&eacute;sentant l&eacute;gal ayant donn&eacute; son
          autorisation expresse et &eacute;crite, du mineur ainsi qu&rsquo;un
          document attestant du pouvoir (livret de famille, d&eacute;cision de
          justice conf&eacute;rant le pouvoir de repr&eacute;sentation).</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Site Partenaire pourra faire parvenir l&rsquo;acceptation ou le
          refus de l&rsquo;ouverture de son Compte de paiement au Titulaire par
          envoi d&rsquo;un Email. Le Titulaire peut &agrave; compter de
          l&rsquo;acceptation s&rsquo;identifier sur le Site Partenaire pour
          constater que son Compte de paiement est ouvert.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="11">
        <li class="c9">
          <span class="c10"
            >3.5 R&eacute;gime sp&eacute;cifique de la client&egrave;le
            occasionnelle</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le client occasionnel est le client &laquo; de passage &raquo; qui
          sollicite l&rsquo;intervention de Lemonway pour la r&eacute;alisation
          d&rsquo;une Op&eacute;ration de paiement isol&eacute;e ou de plusieurs
          Op&eacute;rations de paiement pr&eacute;sentant un lien entre elles
          dans la limite des plafonds applicables par Lemonway. Les
          Op&eacute;rations effectu&eacute;es par un client occasionnel ne
          feront pas l&rsquo;objet de l&rsquo;ouverture d&rsquo;un Compte de
          paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway rappelle que le r&eacute;gime sp&eacute;cifique de la
          client&egrave;le occasionnelle est uniquement applicable &agrave; une
          certaine typologie de client&egrave;le et
          d&rsquo;activit&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >L&rsquo;acceptation du pr&eacute;sent Contrat-cadre ne constitue pas
          une entr&eacute;e en relation d&rsquo;affaires et l&rsquo;ouverture
          d&rsquo;un Compte de paiement dans le cas o&ugrave; le r&eacute;gime
          sp&eacute;cifique de la client&egrave;le occasionnelle est
          applicable.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway rappelle qu&rsquo;en cas de d&eacute;passement des plafonds
          d&rsquo;Op&eacute;rations de paiement applicables, l&rsquo;ouverture
          d&rsquo;un Compte de paiement sera obligatoire pour effectuer de
          nouvelles Op&eacute;rations de paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="15">
        <li class="c20" ref="h.2grqrue">
          <span class="c12">4. CR&Eacute;DITER LE COMPTE DE PAIEMENT</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">4.1 Alimentation du Compte de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut proc&eacute;der &agrave; l&rsquo;alimentation de
          son Compte de paiement par les moyens de paiement mis &agrave;
          disposition par le Partenaire de Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas d&rsquo;alimentation du Compte de paiement par ch&egrave;que
          (fran&ccedil;ais uniquement), ces derniers devront &ecirc;tre
          endoss&eacute;s &agrave; l&rsquo;ordre de Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway peut refuser ou annuler l&rsquo;enregistrement du moyen de
          paiement utilis&eacute; par le Titulaire par mesure de
          s&eacute;curit&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway param&egrave;tre des plafonds de paiement dans
          l&rsquo;int&eacute;r&ecirc;t de la protection du Titulaire et le
          respect de la r&eacute;glementation en vigueur en mati&egrave;re de
          Services de paiement. Des plafonds uniques, par jour, par mois et par
          an, ainsi que toutes formes de restrictions (notamment portant sur
          l&rsquo;authentification forte du Titulaire) sont appliqu&eacute;es
          par Lemonway pour lutter contre la fraude.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire est inform&eacute; que toute Op&eacute;ration de
          paiement risquant d&rsquo;entra&icirc;ner un d&eacute;passement des
          plafonds applicables sera automatiquement rejet&eacute;e par
          Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Toute Op&eacute;ration par carte bancaire ou de paiement, qui ferait
          l&rsquo;objet d&rsquo;un impay&eacute;, d&rsquo;un rejet ou
          d&rsquo;une opposition verra son montant automatiquement d&eacute;duit
          par Lemonway du solde net du Compte de paiement. Si le solde net est
          insuffisant, Lemonway est autoris&eacute;e &agrave; utiliser toutes
          les voies de recours contre le Titulaire en vue de recouvrer le
          montant d&ucirc;. En outre, Lemonway sera en droit de refuser
          l&rsquo;ex&eacute;cution de toutes les futures remises de fonds
          effectu&eacute;es au moyen de la carte ayant donn&eacute; lieu
          &agrave; l&rsquo;incident.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway r&eacute;percute, en outre, au d&eacute;bit du Compte de
          paiement du Titulaire, les Op&eacute;rations de paiement
          rejet&eacute;es et autres amendes qui peuvent &ecirc;tre
          prononc&eacute;es par les r&eacute;seaux d&rsquo;acceptation.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9">
          <span class="c10"
            >4.2 D&eacute;lais d&rsquo;inscription des fonds sur le Compte</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway inscrira les fonds r&eacute;sultant de l&rsquo;acquisition
          d&rsquo;une Op&eacute;ration de paiement par carte ou par virement
          dans les plus brefs d&eacute;lais et au plus tard &agrave; la fin du
          Jour Ouvr&eacute; au cours duquel ils ont &eacute;t&eacute;
          re&ccedil;us par Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les d&eacute;lais d&rsquo;inscription des fonds sur le Compte peuvent
          &ecirc;tre plus longs en cas d&rsquo;utilisation d&rsquo;un autre
          moyen de paiement mis &agrave; disposition par Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Pour des raisons de s&eacute;curit&eacute;, les d&eacute;lais
          d&rsquo;inscription peuvent &ecirc;tre plus longs en cas de suspicion
          de fraude dans l&rsquo;attente de fourniture d&rsquo;informations
          compl&eacute;mentaires par le Titulaire ou de tout autre tiers
          concern&eacute;.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="16">
        <li class="c20" ref="h.vx1227">
          <span class="c12"
            >5. D&Eacute;BITER UN COMPTE DE PAIEMENT PAR VIREMENT</span
          >
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">5.1 Initier un Ordre de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway fournit un service de paiement permettant aux Titulaires
          disposant d&rsquo;un Compte de paiement de donner instruction &agrave;
          Lemonway, d&rsquo;ex&eacute;cuter un virement sous r&eacute;serve que
          la Provision du Compte soit sup&eacute;rieure au montant total du
          virement (frais inclus). En cas de Provision insuffisante,
          l&rsquo;Ordre de paiement sera automatiquement refus&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La Provision disponible correspond au solde net du Compte de paiement
          exclusion faite de la Provision bloqu&eacute;e et des
          Op&eacute;rations en cours. Le montant de la Provision bloqu&eacute;e
          est d&eacute;termin&eacute; par Lemonway en vue de couvrir les
          &eacute;ventuelles contrepassations provenant de la contestation
          d&rsquo;un Ordre de paiement. Une telle contestation peut intervenir
          dans un d&eacute;lai de treize (13) mois &agrave; compter du
          d&eacute;bit en Compte de l&rsquo;Op&eacute;ration.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >L&rsquo;Ordre de paiement pourra &ecirc;tre fait &agrave;
          l&rsquo;attention soit d&rsquo;un autre Compte de paiement ouvert dans
          les livres de Lemonway, soit d&rsquo;un compte bancaire au nom du
          Titulaire ouvert dans les livres d&rsquo;un PSP tiers
          autoris&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >L&rsquo;Ordre de paiement devra comporter les informations suivantes
          :</span
        >
      </p>
      <ul class="c2 lst-kix_list_8-0 start">
        <li class="c0 c18">
          <span class="c1">le montant en euros ou en devises ;</span>
        </li>
        <li class="c0 c18">
          <span class="c1"
            >le nom et pr&eacute;nom du B&eacute;n&eacute;ficiaire ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >le num&eacute;ro de son compte bancaire ouvert dans les livres du
            PSP tiers.</span
          >
        </li>
      </ul>
      <p class="c0">
        <span class="c1"
          >Le Titulaire reconna&icirc;t que si la devise du Compte de paiement
          diff&egrave;re de celle du compte B&eacute;n&eacute;ficiaire vers
          lequel va s&rsquo;effectuer le virement des fonds, des frais de change
          seront imput&eacute;s par le PSP du B&eacute;n&eacute;ficiaire. Il
          appartient au Partenaire de Lemonway et au PSP du
          B&eacute;n&eacute;ficiaire d&rsquo;informer le
          B&eacute;n&eacute;ficiaire avant toute acquisition d&rsquo;un ordre de
          virement, du taux de change pratiqu&eacute;, des frais et des
          d&eacute;lais d&rsquo;ex&eacute;cution. Cette information devra
          &ecirc;tre transmise au Titulaire Payeur par le Partenaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway ne peut &ecirc;tre tenu pour responsable si les
          coordonn&eacute;es bancaires transmises pour les demandes de virement
          sont erron&eacute;es ou non mises &agrave; jour.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9">
          <span class="c10"
            >5.2 Irr&eacute;vocabilit&eacute; d&rsquo;un Ordre de paiement</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >L&rsquo;Ordre de paiement valablement donn&eacute; par un Titulaire
          est irr&eacute;vocable pour le Titulaire &agrave; compter de la saisie
          d&rsquo;un code &agrave; usage unique conform&eacute;ment &agrave;
          l&rsquo;article 5.1. Le Titulaire ne pourra donc pas en demander
          l&rsquo;annulation.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway pourra cependant bloquer tout Ordre de paiement en cas de
          suspicion de fraude ou de violation des r&egrave;gles applicables en
          mati&egrave;re de lutte contre le blanchiment et le financement du
          terrorisme (LCB-FT).</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les Ordres de paiement devront pr&eacute;alablement recueillir le
          consentement du Payeur ou du Titulaire. En l&rsquo;absence d&rsquo;un
          tel consentement, l&rsquo;Op&eacute;ration ou la s&eacute;rie
          d&rsquo;Op&eacute;rations de paiement est r&eacute;put&eacute;e non
          autoris&eacute;e.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le consentement peut &ecirc;tre retir&eacute; par le Payeur tant que
          l&rsquo;Ordre de paiement n&rsquo;a pas acquis un caract&egrave;re
          d&rsquo;irr&eacute;vocabilit&eacute; conform&eacute;ment aux
          dispositions de l&rsquo;article L. 133-8 du Code mon&eacute;taire et
          financier.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lorsque l&rsquo;Op&eacute;ration de paiement est initi&eacute;e par
          le B&eacute;n&eacute;ficiaire ou par le Payeur qui donne un Ordre de
          paiement par l&rsquo;interm&eacute;diaire du
          B&eacute;n&eacute;ficiaire, le Payeur ne peut r&eacute;voquer
          l&rsquo;Ordre de paiement apr&egrave;s avoir transmis l&rsquo;Ordre de
          paiement au B&eacute;n&eacute;ficiaire ou donn&eacute; son
          consentement &agrave; l&rsquo;ex&eacute;cution de
          l&rsquo;Op&eacute;ration de paiement au
          B&eacute;n&eacute;ficiaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le consentement &agrave; l&rsquo;ex&eacute;cution d&rsquo;une
          s&eacute;rie d&rsquo;Op&eacute;rations de paiement peut aussi
          &ecirc;tre retir&eacute;, avec pour effet que toute Op&eacute;ration
          post&eacute;rieure est r&eacute;put&eacute;e non
          autoris&eacute;e.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c9">
          <span class="c10"
            >5.3 Montants des plafonds et limites applicables</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Toute Op&eacute;ration de paiement risquant d&rsquo;entra&icirc;ner
          un d&eacute;passement des plafonds applicables au montant des
          paiements mensuels cumul&eacute;s sera automatiquement rejet&eacute;e
          par Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >D&rsquo;autres plafonds ou blocages d&rsquo;Ordre pourront &ecirc;tre
          activ&eacute;s &agrave; tout moment par Lemonway en cas de risque de
          fraude.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway se r&eacute;serve le droit de contrepasser une
          Op&eacute;ration de paiement, si l&rsquo;op&eacute;ration de transfert
          de fonds par carte bancaire ou de paiement utilis&eacute;e pour
          cr&eacute;diter le Compte de paiement en vue de r&eacute;aliser cette
          op&eacute;ration est rejet&eacute;e ou annul&eacute;e par le PSP
          &eacute;metteur de la carte.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c9">
          <span class="c10">5.4 D&eacute;lais d&rsquo;ex&eacute;cution</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les d&eacute;lais maximaux d&rsquo;ex&eacute;cution des Services de
          paiement, conform&eacute;ment &agrave; l&rsquo;arr&ecirc;t&eacute; du
          29 juillet 2009, d&rsquo;application de l&rsquo;article L. 133-13 du
          Code mon&eacute;taire et financier, sont les suivants :</span
        >
      </p>
      <ul class="c2 lst-kix_list_9-0 start">
        <li class="c0 c18">
          <span class="c1"
            >une Op&eacute;ration de paiement initi&eacute;e un Jour
            Ouvr&eacute; sera ex&eacute;cut&eacute;e au plus tard par Lemonway
            le Jour Ouvr&eacute; suivant si elle est r&eacute;alis&eacute;e en
            euros au profit d&rsquo;un &eacute;tablissement de cr&eacute;dit
            localis&eacute; dans un Etat membre de l&rsquo;Union
            Europ&eacute;enne ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >une Op&eacute;ration de paiement initi&eacute;e un Jour
            Ouvr&eacute; sera ex&eacute;cut&eacute;e au plus tard par Lemonway
            &agrave; la fin dudit Jour Ouvr&eacute; si elle est
            r&eacute;alis&eacute;e en euros au profit d&rsquo;un autre Compte de
            paiement.</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-0" start="17">
        <li class="c20" ref="h.3fwokq0">
          <span class="c12">6. REPORTING</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9"><span class="c10">6.1 Par Op&eacute;ration</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lorsqu&rsquo;une Op&eacute;ration de paiement est effectu&eacute;e,
          Lemonway ou le Site Partenaire envoie automatiquement un email de
          confirmation de l&rsquo;Op&eacute;ration au Titulaire ayant
          initi&eacute; l&rsquo;Ordre de paiement. Cet email reprend
          l&rsquo;ensemble des informations relatives &agrave;
          l&rsquo;Op&eacute;ration de paiement qui ont &eacute;t&eacute;
          communiqu&eacute;es &agrave; Lemonway, telles que :
          l&rsquo;identit&eacute; du B&eacute;n&eacute;ficiaire ou du Payeur, le
          descriptif de l&rsquo;Op&eacute;ration de paiement, son montant, la
          date et l&rsquo;heure de l&rsquo;Op&eacute;ration de paiement ainsi
          que les &eacute;ventuelles conditions particuli&egrave;res de
          paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9"><span class="c10">6.2 Relev&eacute;s</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Toutes les Op&eacute;rations de paiement sont reprises dans un
          relev&eacute; &eacute;tabli en temps r&eacute;el pour chaque Compte de
          paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire aura acc&egrave;s aux relev&eacute;s de Comptes de
          paiement reprenant l&rsquo;ensemble des Op&eacute;rations de paiement
          inscrites au d&eacute;bit et au cr&eacute;dit de ce compte par
          l&rsquo;interm&eacute;diaire du Site Partenaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La p&eacute;riode de consultation est maintenue pendant deux (2) ans,
          plus l&rsquo;ann&eacute;e en cours. Lemonway conservera sur support
          &eacute;lectronique d&rsquo;archivage, pendant les d&eacute;lais
          r&eacute;glementaires applicables, les enregistrements et documents
          des Op&eacute;rations de paiement effectu&eacute;es.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="18">
        <li class="c20" ref="h.1v1yuxt">
          <span class="c12"
            >7. DUR&Eacute;E DU CONTRAT-CADRE ET DATE D&rsquo;EFFET</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Contrat-cadre entre en vigueur au moment de l&rsquo;acceptation
          des pr&eacute;sentes par le Titulaire et ce pour une dur&eacute;e
          ind&eacute;termin&eacute;e.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire dispose d&rsquo;un d&eacute;lai de quatorze (14) jours
          calendaires pour renoncer sans frais au Contrat-cadre, s&rsquo;il
          r&eacute;pond aux conditions de l&rsquo;article D. 341-1 du Code
          mon&eacute;taire et financier pour les personnes morales ou s&rsquo;il
          est une personne physique. Ce d&eacute;lai court &agrave; compter du
          jour o&ugrave; le Contrat-cadre est conclu c&rsquo;est-&agrave;-dire
          le jour o&ugrave; le Titulaire a accept&eacute; les pr&eacute;sentes
          conditions g&eacute;n&eacute;rales. Pendant ce d&eacute;lai de
          renonciation, l&rsquo;ex&eacute;cution du Contrat-cadre ne pourra
          commencer qu&rsquo;&agrave; la demande expresse du Titulaire. Le
          Titulaire reconna&icirc;t express&eacute;ment et accepte que toute
          instruction de paiement adress&eacute;e &agrave; Lemonway avant
          l&rsquo;&eacute;coulement du d&eacute;lai de renonciation, constitue
          une demande expresse du Titulaire d&rsquo;ex&eacute;cuter le
          Contrat-cadre. Le Titulaire ne sera donc pas en droit d&rsquo;annuler
          une instruction de paiement qu&rsquo;il aurait donn&eacute;e et
          confirm&eacute;e pendant le d&eacute;lai de renonciation.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Ce droit de renonciation peut &ecirc;tre exerc&eacute; par le
          Titulaire sans p&eacute;nalit&eacute;s et sans indication de
          motif.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire est tenu de notifier sa d&eacute;cision de renonciation
          &agrave; Lemonway par tout moyen. Si le Titulaire n&rsquo;exerce pas
          son droit de renonciation, le Contrat-cadre sera maintenu
          conform&eacute;ment aux dispositions des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales. Il devra pour r&eacute;silier le
          Contrat-cadre se conformer aux conditions de r&eacute;siliation de
          l&rsquo;article 19.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="19">
        <li class="c20" ref="h.4f1mdlm">
          <span class="c12">8. R&Eacute;CLAMATIONS</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les r&eacute;clamations qui portent sur les relations entre deux
          Titulaires ou entre un Titulaire et un tiers ne sont pas recevables
          aupr&egrave;s de Lemonway. Seules celles qui portent sur
          l&rsquo;absence ou la mauvaise ex&eacute;cution d&rsquo;une
          Op&eacute;ration de paiement ex&eacute;cut&eacute;e par Lemonway sont
          vis&eacute;es par le pr&eacute;sent article et par le
          Contrat-cadre.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les r&eacute;clamations (contestations, droits d&rsquo;opposition,
          d&rsquo;acc&egrave;s et de rectification) peuvent &ecirc;tre
          exerc&eacute;es gratuitement sur demande adress&eacute;e &agrave;
          Lemonway via la page de r&eacute;clamation disponible &agrave;
          l&rsquo;adresse suivante : www.lemonway.com/reclamation ou par
          courrier &agrave; l&rsquo;adresse suivante :</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Soci&eacute;t&eacute; LEMONWAY &ndash; Service R&eacute;clamation
          &ndash; 8 rue du Sentier, CS 60820, 75083 Paris CEDEX 2.</span
        >
      </p>
      <p class="c0">
        <span class="c1">Toute contestation ou requ&ecirc;te relative :</span>
      </p>
      <ul class="c2 lst-kix_list_10-0 start">
        <li class="c0 c21">
          <span class="c1"
            >&agrave; une information communiqu&eacute;e par Lemonway en
            application du Contrat-cadre ;</span
          >
        </li>
        <li class="c0 c21">
          <span class="c1"
            >&agrave; une erreur dans l&rsquo;ex&eacute;cution des Services de
            paiement ou dans son inex&eacute;cution ;</span
          >
        </li>
        <li class="c0 c21">
          <span class="c1"
            >&agrave; une erreur dans le pr&eacute;l&egrave;vement de
            commission, taxe ou frais par Lemonway ;</span
          >
        </li>
      </ul>
      <p class="c0">
        <span class="c1"
          >doit &ecirc;tre notifi&eacute;e &agrave; Lemonway par le Titulaire
          dans les plus brefs d&eacute;lais &agrave; compter du jour o&ugrave;
          le Titulaire en a eu connaissance ou est pr&eacute;sum&eacute; en
          avoir eu connaissance ou dans tout autre d&eacute;lai plus long
          pr&eacute;vu par des dispositions particuli&egrave;res ou par la
          loi.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Conform&eacute;ment &agrave; la recommandation de l&rsquo;ACPR
          2016-R-02 du 14 novembre 2016, un accus&eacute; de r&eacute;ception
          sera envoy&eacute; sous dix jours maximum. Les r&eacute;clamations
          seront trait&eacute;es sous deux mois maximum &agrave; compter de leur
          r&eacute;ception.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >A d&eacute;faut d&rsquo;accord amiable, le Titulaire agissant pour
          des besoins non professionnels peut s&rsquo;adresser, par lettre,
          &agrave; un m&eacute;diateur ind&eacute;pendant, pouvant &ecirc;tre
          saisi gratuitement en cas de litige n&eacute; de l&rsquo;application
          des pr&eacute;sentes, le M&eacute;diateur de l&rsquo;AFEPAME, 36 rue
          Taitbout 75009 Paris, et ceci sans pr&eacute;judice des autres voies
          d&rsquo;actions l&eacute;gales.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="20">
        <li class="c20" ref="h.2u6wntf"><span class="c12">9. FRAIS</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >En contrepartie de la fourniture des Services de paiement au
          Titulaire, Lemonway percevra une r&eacute;mun&eacute;ration dont le
          montant et les conditions sont fix&eacute;es entre le Partenaire et
          Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway informe le Titulaire que le Partenaire prend en charge
          l&rsquo;ensemble des frais relatifs &agrave; la fourniture des
          Services de paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="21">
        <li class="c20" ref="h.19c6y18">
          <span class="c12">10. S&Eacute;CURIT&Eacute;</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">10.1 Obligation de notification</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a l&rsquo;obligation d&rsquo;informer
          imm&eacute;diatement Lemonway en cas de soup&ccedil;on
          d&rsquo;acc&egrave;s ou d&rsquo;utilisation frauduleuse de son Compte
          de paiement ou de tout &eacute;v&eacute;nement susceptible de mener
          &agrave; une telle utilisation, tels que et de mani&egrave;re non
          limitative : la perte, la divulgation accidentelle ou le
          d&eacute;tournement de ses identifiants de Compte de paiement ou une
          Op&eacute;ration non autoris&eacute;e.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Cette notification doit s&rsquo;effectuer par l&rsquo;envoi
          d&rsquo;un courrier &eacute;lectronique &agrave; l&rsquo;adresse email
          suivante : alerte.lcbft@lemonway.com et &ecirc;tre confirm&eacute;e
          par courrier &agrave; l&rsquo;adresse suivante :</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Soci&eacute;t&eacute; LEMONWAY &ndash; Service S&eacute;curit&eacute;
          Financi&egrave;re &ndash; 8 rue du Sentier, CS 60820, 75083 Paris
          CEDEX 2.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9"><span class="c10">10.2 Pr&eacute;vention</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway fera ses meilleurs efforts pour emp&ecirc;cher toute autre
          utilisation du Compte de paiement. Le Partenaire dispose
          &eacute;galement de ses propres moyens de communication
          s&eacute;curis&eacute;e avec le Titulaire sous sa propre
          responsabilit&eacute;.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c9"><span class="c10">10.3 Utilisation de cookies</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway informe le Titulaire que dans le cadre des Services de
          paiement, des cookies pourront &ecirc;tre utilis&eacute;s. Ces cookies
          servent avant tout &agrave; am&eacute;liorer le fonctionnement des
          Services de paiement notamment en termes de rapidit&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire est inform&eacute; qu&rsquo;il peut refuser les cookies
          aupr&egrave;s de Lemonway dans les param&egrave;tres de son
          navigateur, mais que cela risque d&rsquo;alt&eacute;rer son
          utilisation des Services de paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c9">
          <span class="c10">10.4 Interruption des Services de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway s&rsquo;engage &agrave; mettre en &oelig;uvre tous les
          moyens raisonnables &agrave; sa disposition pour assurer un service
          permanent. Lemonway ne garantit toutefois pas l&rsquo;acc&egrave;s
          continu, ininterrompu aux Service de paiement. En cons&eacute;quence
          Lemonway ne pourra pas &ecirc;tre tenue pour responsable du retard
          et/ou de la non-accessibilit&eacute; totale ou partielle aux Services
          de paiement, d&egrave;s lors qu&rsquo;ils r&eacute;sultent de facteurs
          &eacute;chappant au contr&ocirc;le raisonnable de Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire est inform&eacute; que Lemonway peut interrompre,
          occasionnellement, l&rsquo;acc&egrave;s &agrave; tout ou partie des
          Services de paiement :</span
        >
      </p>
      <ul class="c2 lst-kix_list_11-0 start">
        <li class="c0 c23">
          <span class="c1"
            >pour permettre les r&eacute;parations, maintenances, ajouts de
            fonctionnalit&eacute; ;</span
          >
        </li>
        <li class="c0 c23">
          <span class="c1"
            >en cas de suspicion de tentative de piratage, de
            d&eacute;tournement de fonds ou de tout autre risque
            d&rsquo;atteinte ;</span
          >
        </li>
        <li class="c0 c23">
          <span class="c1"
            >sur demandes ou instructions &eacute;manant de personnes ou
            autorit&eacute;s comp&eacute;tentes habilit&eacute;es.</span
          >
        </li>
      </ul>
      <p class="c0">
        <span class="c1"
          >Lemonway ne peut en aucun cas &ecirc;tre tenue responsable des
          dommages r&eacute;sultants &eacute;ventuellement de ces
          suspensions.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >D&egrave;s la reprise normale du service, Lemonway mettra en
          &oelig;uvre les efforts raisonnables pour traiter les
          Op&eacute;rations de paiement en attente dans les meilleurs
          d&eacute;lais.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c9">
          <span class="c10"
            >10.5 Opposition au dispositif de s&eacute;curit&eacute;</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut faire opposition en contactant Lemonway via la page
          de r&eacute;clamation disponible &agrave; l&rsquo;adresse suivante :
          www.lemonway.com/reclamation ou par t&eacute;l&eacute;phone au
          num&eacute;ro : : +33 1 76 44 04 60.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le dispositif de s&eacute;curit&eacute; s&rsquo;entend comme toute
          mesure de s&eacute;curisation des Op&eacute;rations de paiement et/ou
          d&rsquo;acc&egrave;s au Titulaire &agrave; son Compte via son espace
          client du Site Partenaire, conform&eacute;ment &agrave; la
          r&eacute;glementation en vigueur.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Un num&eacute;ro d&rsquo;enregistrement de cette opposition est
          cr&eacute;&eacute; et est conserv&eacute; pendant 18 mois. Sur demande
          &eacute;crite du Titulaire et avant l&rsquo;expiration de ce
          d&eacute;lai, Lemonway lui communiquera une copie de cette
          opposition.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway ne saurait &ecirc;tre tenue pour responsable des
          cons&eacute;quences d&rsquo;une opposition qui
          n&rsquo;&eacute;manerait pas du Titulaire. La demande
          d&rsquo;opposition est r&eacute;put&eacute;e faite &agrave; la date de
          r&eacute;ception effective de la demande par Lemonway ou toute
          personne mandat&eacute;e par lui, &agrave; cet effet. En cas de vol ou
          d&rsquo;utilisation frauduleuse, Lemonway est habilit&eacute;e
          &agrave; demander un r&eacute;c&eacute;piss&eacute; ou une copie du
          d&eacute;p&ocirc;t de plainte au Titulaire qui s&rsquo;engage &agrave;
          y r&eacute;pondre dans les plus brefs d&eacute;lais.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway bloquera l&rsquo;acc&egrave;s au Compte de paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="22">
        <li class="c20" ref="h.3tbugp1">
          <span class="c12">11. RESPONSABILIT&Eacute;S</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Conform&eacute;ment &agrave; l&rsquo;article L. 133-22 du Code
          mon&eacute;taire et financier, Lemonway est responsable, sous
          r&eacute;serve des articles L. 133-5 et L. 133-21 du Code
          mon&eacute;taire et financier, de la bonne ex&eacute;cution de
          l&rsquo;Op&eacute;ration de paiement &agrave; l&rsquo;&eacute;gard du
          Titulaire Payeur jusqu&rsquo;&agrave; r&eacute;ception des fonds par
          le PSP tiers du B&eacute;n&eacute;ficiaire. Lorsque Lemonway est
          responsable d&rsquo;une Op&eacute;ration de paiement mal
          ex&eacute;cut&eacute;e par sa faute, Lemonway restitue sans tarder son
          montant au Payeur et r&eacute;tablit le compte d&eacute;bit&eacute;
          dans la situation qui aurait pr&eacute;valu si
          l&rsquo;Op&eacute;ration de paiement mal ex&eacute;cut&eacute;e
          n&rsquo;avait pas eu lieu.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire agissant pour des besoins non professionnels qui
          souhaite contester une Op&eacute;ration de paiement non
          autoris&eacute;e par lui doit contacter le service clients
          conform&eacute;ment &agrave; l&rsquo;article 8 dans les plus brefs
          d&eacute;lais suivant sa prise de connaissance de l&rsquo;anomalie et
          au plus tard 13 mois suivant l&rsquo;inscription de
          l&rsquo;Op&eacute;ration de paiement. En cas d&rsquo;utilisation du
          dispositif de s&eacute;curit&eacute;, les Op&eacute;rations de
          paiement non autoris&eacute;es effectu&eacute;es avant la notification
          de l&rsquo;opposition sont &agrave; la charge du Titulaire agissant
          pour des besoins non professionnels, dans la limite d&rsquo;un plafond
          de 50 euros conform&eacute;ment &agrave; l&rsquo;article L. 133-19 du
          Code mon&eacute;taire et financier. Toutefois, la
          responsabilit&eacute; de Lemonway n&rsquo;est pas engag&eacute;e en
          cas de faute du Titulaire tel qu&rsquo;un manquement volontaire ou
          constitutif d&rsquo;une n&eacute;gligence grave &agrave; ses
          obligations, une transmission tardive de l&rsquo;opposition ou de
          mauvaise foi. En cas de d&eacute;tournement de ses donn&eacute;es ou
          contrefa&ccedil;on, les pertes r&eacute;sultant des Op&eacute;rations
          de paiement pass&eacute;es avant l&rsquo;opposition par le Titulaire
          agissant pour des besoins non professionnels sont support&eacute;es
          par Lemonway, sauf en cas de faute telle que d&eacute;finie ci-dessus.
          Les Op&eacute;rations de paiement r&eacute;alis&eacute;es apr&egrave;s
          l&rsquo;opposition du Titulaire agissant pour des besoins non
          professionnels sont support&eacute;es par Lemonway sauf en cas de
          fraude.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway n&rsquo;est pas en droit d&rsquo;annuler un Ordre de
          paiement irr&eacute;vocable sur demande du Titulaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En aucun cas, Lemonway n&rsquo;est responsable des dommages
          indirects, tels que pr&eacute;judice commercial, perte de
          client&egrave;le, trouble commercial quelconque, perte de
          b&eacute;n&eacute;fice, perte d&rsquo;image de marque subis par un
          Titulaire, ou par un tiers, et qui pourraient r&eacute;sulter des
          Services de paiement fournis par Lemonway. Toute action dirig&eacute;e
          contre un Titulaire par un tiers est assimil&eacute;e &agrave; un
          pr&eacute;judice indirect, et en cons&eacute;quence n&rsquo;ouvre pas
          droit &agrave; r&eacute;paration.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Sauf stipulation contraire des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales ou des lois imp&eacute;ratives et sans
          pr&eacute;judice des autres causes d&rsquo;exclusion ou de limitation
          de responsabilit&eacute; pr&eacute;vues par les pr&eacute;sentes,
          Lemonway ne pourra en aucun cas &ecirc;tre tenue pour responsable de
          tout dommage caus&eacute; par un cas de force majeure ou un
          &eacute;v&eacute;nement hors de son contr&ocirc;le ou de toute mesure
          ou dispositions l&eacute;gislatives prises par les autorit&eacute;s
          fran&ccedil;aises ou &eacute;trang&egrave;res. Sont
          r&eacute;put&eacute;s constituer un cas de force majeure ou un
          &eacute;v&eacute;nement hors de son contr&ocirc;le, notamment, mais
          sans que cela soit limitatif : une panne
          d&rsquo;&eacute;lectricit&eacute;, un incendie ou une inondation, la
          gr&egrave;ve de son personnel ou d&rsquo;un de ses sous-traitants ou
          fournisseurs, un dysfonctionnement des syst&egrave;mes interbancaires
          ou de paiement par carte bancaire, troubles &agrave; l&rsquo;ordre
          public, n&eacute;gligence d&rsquo;un tiers au sens retenu par la
          jurisprudence et de la doctrine telles que les personnes responsables
          de la livraison d&rsquo;&eacute;lectricit&eacute;, des services de
          t&eacute;l&eacute;communication ou d&rsquo;h&eacute;bergement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="23">
        <li class="c20" ref="h.28h4qwu">
          <span class="c12">12. PROTECTION DES FONDS CLIENTS</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway conservera les fonds disponibles inscrits au cr&eacute;dit
          du Compte de paiement du Titulaire &agrave; la fin du Jour
          Ouvr&eacute; suivant le jour o&ugrave; ils ont &eacute;t&eacute;
          re&ccedil;us sur un compte de cantonnement ouvert aupr&egrave;s des
          banques partenaires de Lemonway conform&eacute;ment l&rsquo;article L.
          522-17 du Code mon&eacute;taire et financier.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="24">
        <li class="c20" ref="h.nmf14n">
          <span class="c12"
            >13. D&Eacute;C&Egrave;S &ndash; COMPTE DE PAIEMENT INACTIF &ndash;
            PROCURATION</span
          >
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9"><span class="c10">13.1 D&eacute;c&egrave;s</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >En cas de d&eacute;c&egrave;s du Titulaire du Compte de paiement,
          Lemonway doit en &ecirc;tre avis&eacute;e le plus rapidement possible
          par les ayant droits ou leur mandataire. Si cet avis est donn&eacute;
          verbalement, il doit &ecirc;tre confirm&eacute; par &eacute;crit.
          D&egrave;s r&eacute;ception de cet &eacute;crit, Lemonway veillera
          &agrave; ce qu&rsquo;aucune nouvelle Op&eacute;ration de paiement ne
          soit ex&eacute;cut&eacute;e et proc&egrave;dera &agrave; la
          cl&ocirc;ture du Compte.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Si la Provision que Lemonway d&eacute;tient au nom du d&eacute;funt
          est sup&eacute;rieure aux frais permettant de supporter les
          co&ucirc;ts de retrait, elle pourra faire l&rsquo;objet d&rsquo;un
          remboursement en faveur des ayants-droits uniquement en cas de
          production par ces ayants-droits ou leur mandataire des pi&egrave;ces
          probantes, selon la l&eacute;gislation applicable, &eacute;tablissant
          la d&eacute;volution de la succession ainsi que de toute autre
          pi&egrave;ce que Lemonway jugerait n&eacute;cessaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >A d&eacute;faut de virement pour quelque raison que ce soit, y
          compris l&rsquo;absence de production de document probant &agrave;
          Lemonway, les dispositions de l&rsquo;article 13.2 des
          pr&eacute;sentes conditions g&eacute;n&eacute;rales
          s&rsquo;appliqueront &agrave; la Provision.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9"><span class="c10">13.2 Compte inactif</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Un Compte de paiement est r&eacute;put&eacute; inactif si :</span
        >
      </p>
      <ol class="c2 lst-kix_list_12-0 start" start="1">
        <li class="c0 c16">
          <span class="c1"
            >a) le Compte de paiement n&rsquo;a fait l&rsquo;objet
            d&rsquo;aucune Op&eacute;ration de paiement pendant une
            p&eacute;riode de douze mois au cours de laquelle, hors inscription
            de d&eacute;bit par Lemonway tenant le compte de frais et
            commissions de toutes natures, et</span
          >
        </li>
        <li class="c0 c16">
          <span class="c1"
            >b) le Titulaire du Compte, son repr&eacute;sentant l&eacute;gal ou
            la personne habilit&eacute;e par lui ne s&rsquo;est pas
            manifest&eacute;, sous quelque forme que ce soit, aupr&egrave;s de
            Lemonway ; ou</span
          >
        </li>
        <li class="c0 c16">
          <span class="c1"
            >&agrave; l&rsquo;issue d&rsquo;une p&eacute;riode de douze (12)
            mois suivant le d&eacute;c&egrave;s du Titulaire. Le Titulaire et
            ses ayants droit sont inform&eacute;s par les pr&eacute;sentes des
            cons&eacute;quences qui y sont attach&eacute;es.</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les avoirs inscrits sur le Compte de paiement inactif sont
          d&eacute;pos&eacute;s &agrave; la Caisse des d&eacute;p&ocirc;ts et
          consignations &agrave; l&rsquo;issue d&rsquo;un d&eacute;lai de dix
          (10) ans &agrave; compter de la date de la derni&egrave;re
          Op&eacute;ration de paiement hors inscription des d&eacute;bits par
          Lemonway tenant le compte de frais et commissions de toutes natures ;
          sauf en cas de d&eacute;c&egrave;s du Titulaire du compte o&ugrave;
          les avoirs inscrits sur le compte de paiement inactif sont
          d&eacute;pos&eacute;s &agrave; la Caisse des d&eacute;p&ocirc;ts et
          consignations &agrave; l&rsquo;issue d&rsquo;un d&eacute;lai de trois
          (3) ans apr&egrave;s la date du d&eacute;c&egrave;s du
          Titulaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Pour toute relance et notification de Lemonway au Titulaire ou au
          Partenaire, des frais seront applicables. En cas de compte inactifs et
          apr&egrave;s relance et notification de Lemonway, des frais de gestion
          seront applicables.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c9"><span class="c10">13.3 Procuration</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut donner &agrave; une personne pouvoir
          d&rsquo;effectuer sur son Compte de paiement et sous son
          enti&egrave;re responsabilit&eacute; les Op&eacute;rations de paiement
          telles que d&eacute;finies dans la procuration. La procuration ne
          prendra effet qu&rsquo;&agrave; compter de la r&eacute;ception et de
          l&rsquo;acceptation du formulaire d&ucirc;ment compl&eacute;t&eacute;
          par Lemonway. La procuration sera notifi&eacute;e par tout moyen. Elle
          cesse automatiquement au d&eacute;c&egrave;s du Titulaire. Elle peut
          &ecirc;tre r&eacute;voqu&eacute;e &agrave; l&rsquo;initiative du
          Titulaire qui en informe le mandataire et Lemonway par lettre
          recommand&eacute;e avec avis de r&eacute;ception. La r&eacute;vocation
          prend effet &agrave; la date de r&eacute;ception de la notification
          par Lemonway. Le Titulaire reste tenu des Op&eacute;rations de
          paiement initi&eacute;es pour son compte jusqu&rsquo;&agrave; cette
          date par le mandataire d&eacute;sign&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire l&egrave;ve express&eacute;ment Lemonway du secret
          professionnel relatif aux donn&eacute;es du Compte de paiement
          &agrave; l&rsquo;&eacute;gard du mandataire d&eacute;sign&eacute; par
          la procuration.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="25">
        <li class="c20" ref="h.37m2jsg">
          <span class="c12">14. PROPRI&Eacute;T&Eacute; INTELLECTUELLE</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Aucun droit de propri&eacute;t&eacute; intellectuelle relatif
          &agrave; l&rsquo;utilisation du Service de paiement ou aux prestations
          rendues par Lemonway n&rsquo;est transf&eacute;r&eacute; au Titulaire
          au titre des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire s&rsquo;engage &agrave; ne pas porter atteinte aux
          droits d&eacute;tenus par Lemonway, en s&rsquo;interdisant notamment,
          toute reproduction, ou adaptation de tout ou partie des
          &eacute;l&eacute;ments intellectuels et mat&eacute;riels de Lemonway
          et ses accessoires, et ce quel que soit le support, actuel et
          futur.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >L&rsquo;ensemble des droits relatifs aux logiciels servant &agrave;
          la r&eacute;alisation des Services de paiement sont la
          propri&eacute;t&eacute; pleine et enti&egrave;re de la
          soci&eacute;t&eacute; Lemonway. Ils font partie de ses informations
          confidentielles sans &eacute;gard au fait que certaines composantes
          puissent ou non &ecirc;tre prot&eacute;g&eacute;es en
          l&rsquo;&eacute;tat actuel de la l&eacute;gislation par un droit de
          propri&eacute;t&eacute; intellectuelle.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les logiciels de Lemonway et, le cas &eacute;ch&eacute;ant, leur
          documentation, sont reconnus par le Titulaire comme &oelig;uvre de
          l&rsquo;esprit que lui-m&ecirc;me et les membres de son personnel
          s&rsquo;obligent &agrave; consid&eacute;rer comme telle en
          s&rsquo;interdisant de les copier, de les reproduire, de les traduire
          en toute autre langue ou langage, de les adapter, de les distribuer
          &agrave; titre gratuit ou on&eacute;reux, ou de leur adjoindre tout
          objet non conforme &agrave; leurs sp&eacute;cifications.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La marque &laquo; Lemonway &raquo; est la propri&eacute;t&eacute; de
          la soci&eacute;t&eacute; Lemonway. Le Titulaire s&rsquo;engage
          &agrave; ne pas supprimer la mention de la marque &laquo; Lemonway
          &raquo; sur tout &eacute;l&eacute;ment fourni ou mis &agrave; sa
          disposition par Lemonway, tels que logiciel, document ou
          banni&egrave;re publicitaire.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="26">
        <li class="c20" ref="h.1mrcu09">
          <span class="c12">15. CONFIDENTIALIT&Eacute;</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire s&rsquo;engage &agrave; respecter la plus stricte
          confidentialit&eacute; concernant l&rsquo;ensemble des techniques,
          commerciales ou de toute autre nature dont il viendrait &agrave; avoir
          connaissance dans le cadre de l&rsquo;ex&eacute;cution du Service de
          paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Cette obligation de confidentialit&eacute; demeurera en vigueur
          pendant la dur&eacute;e de la souscription au Service de paiement et
          pendant trois (3) ans suivant la date de r&eacute;siliation du
          Contrat-cadre. Cette obligation de confidentialit&eacute; ne
          s&rsquo;applique pas aux informations qui sont ou deviennent
          publiquement disponibles sans faute du Titulaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les Parties reconnaissent que les Op&eacute;rations de paiement sont
          couvertes par le secret professionnel en application de
          l&rsquo;article L. 522-19 du Code mon&eacute;taire et financier.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="27">
        <li class="c20" ref="h.46r0co2">
          <span class="c12"
            >16. COLLECTE ET TRAITEMENT DES DONNEES A CARACTERE PERSONNEL</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Conform&eacute;ment au R&egrave;glement G&eacute;n&eacute;ral sur la
          Protection des Donn&eacute;es (General Data Protection
          R&eacute;gulation) adopt&eacute; par le Parlement europ&eacute;en le
          14 avril 2016, et &agrave; la Loi Informatique et Libert&eacute;s du 6
          janvier 1978 modifi&eacute;e, Lemonway informe le Titulaire :</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c9">
          <span class="c10"
            >16.1 Identification du responsable de traitement</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Soci&eacute;t&eacute; Lemonway SAS, si&egrave;ge social sis au 8 rue
          du Sentier, 75002 Paris, France, T&eacute;l. : +33 1 76 44 04
          60.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c9">
          <span class="c10"
            >16.2 D&eacute;l&eacute;gu&eacute; &agrave; la protection des
            donn&eacute;es</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire pourra joindre le d&eacute;l&eacute;gu&eacute; &agrave;
          la protection des donn&eacute;es &agrave; l&rsquo;adresse courriel
          suivante : dpo@lemonway.com</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="6">
        <li class="c9">
          <span class="c10">16.3 Finalit&eacute;s du traitement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Dans le cadre de l&rsquo;exploitation du Site et des services fournis
          par Lemonway, le traitement de donn&eacute;es personnelles a pour
          finalit&eacute; la gestion des clients, la cr&eacute;ation et la
          gestion des comptes, la gestion des contrats, la gestion des
          r&eacute;siliations, la gestion des litiges, la gestion du Site
          Internet, le mailing, les communications, les v&eacute;rifications en
          mati&egrave;re de lutte contre le blanchiment de capitaux et de lutte
          contre le financement du terrorisme, la gestion de la connaissance
          client, l&rsquo;&eacute;laboration de statistiques dans le but
          d&rsquo;am&eacute;liorer les outils de Lemonway, la gestion des
          demandes concernant les droits des personnes,
          l&rsquo;impl&eacute;mentation des Partenaires, la gestion du
          support.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="7">
        <li class="c9">
          <span class="c10">16.4 Nature des donn&eacute;es</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway collecte de mani&egrave;re directe et indirecte les
          cat&eacute;gories de donn&eacute;es suivantes concernant ses
          utilisateurs :</span
        >
      </p>
      <ul class="c2 lst-kix_list_13-0 start">
        <li class="c0 c18">
          <span class="c1"
            >Donn&eacute;es d&rsquo;&eacute;tat-civil, d&rsquo;identit&eacute;,
            d&rsquo;identification&hellip; ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Donn&eacute;es relatives &agrave; la vie professionnelle (CV,
            scolarit&eacute;, formation professionnelle, &hellip;) ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Informations d&rsquo;ordre &eacute;conomique et financier (revenus,
            situation financi&egrave;re, situation fiscale&hellip;) ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Donn&eacute;es de connexion (adresses IP, journaux
            d&rsquo;&eacute;v&eacute;nements&hellip;).</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">16.5 Source des donn&eacute;es</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway collecte les donn&eacute;es &agrave; caract&egrave;re
          personnel de mani&egrave;re directe par le biais d&rsquo;un contrat,
          d&rsquo;une obligation l&eacute;gale, du consentement de la personne
          ou de l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime de la
          soci&eacute;t&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway collecte &eacute;galement les donn&eacute;es &agrave;
          caract&egrave;re personnel de mani&egrave;re indirecte afin de
          respecter la r&egrave;glementation en mati&egrave;re de lutte contre
          le blanchiment de capitaux et de lutte contre le financement du
          terrorisme.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9">
          <span class="c10">16.6 Consentement de la personne</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lorsque la personne a donn&eacute; son consentement pour la collecte
          de ses donn&eacute;es personnelles, celle-ci peut retirer son
          consentement &agrave; tout moment. Le Titulaire peut retirer son
          consentement via l&rsquo;adresse dpo@lemonway.com.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway informe qu&rsquo;un tel retrait entra&icirc;nera la
          cl&ocirc;ture du Compte.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c9">
          <span class="c10"
            >16.7 Int&eacute;r&ecirc;t l&eacute;gitime du traitement</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lorsque Lemonway collecte et utilise des donn&eacute;es personnelles
          de repr&eacute;sentants de ses Partenaires en se fondant sur
          l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime, celui-ci a pour
          finalit&eacute; la prospection de nouveaux Partenaires.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c9"><span class="c10">16.8 Scoring</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Un scoring est mis en place uniquement en mati&egrave;re de lutte
          contre le blanchiment de capitaux, de lutte contre le financement du
          terrorisme et de lutte contre la fraude.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c9">
          <span class="c10">16.9 Destinataires des donn&eacute;es</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les destinataires des donn&eacute;es &agrave; caract&egrave;re
          personnel sont les collaborateurs habilit&eacute;s au sein de
          Lemonway, les autorit&eacute;s de contr&ocirc;le, les partenaires de
          Lemonway et ses sous-traitants. Les donn&eacute;es personnelles
          peuvent &eacute;galement &ecirc;tre divulgu&eacute;es en application
          d&rsquo;une loi, d&rsquo;un r&egrave;glement ou en vertu d&rsquo;une
          d&eacute;cision d&rsquo;une autorit&eacute; r&eacute;glementaire ou
          judiciaire comp&eacute;tente.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="6">
        <li class="c9">
          <span class="c10">16.10 Dur&eacute;e de conservation</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les donn&eacute;es &agrave; caract&egrave;re personnel
          collect&eacute;es par Lemonway sont conserv&eacute;es le temps
          n&eacute;cessaire &agrave; la finalit&eacute; du traitement.
          Au-del&agrave; de cette dur&eacute;e de conservation, elles deviennent
          des archives interm&eacute;diaires ou sont anonymis&eacute;es et
          conserv&eacute;es dans un but statistique et historique.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Des purges concernant les donn&eacute;es &agrave; caract&egrave;re
          personnel sont mises en place afin de v&eacute;rifier la suppression
          effective d&egrave;s lors que la dur&eacute;e de conservation ou
          d&rsquo;archivage n&eacute;cessaire &agrave; l&rsquo;accomplissement
          des finalit&eacute;s d&eacute;termin&eacute;es ou impos&eacute;es est
          atteinte.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="7">
        <li class="c9"><span class="c10">16.11 Droits des personnes</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Conform&eacute;ment aux dispositions en vigueur, le Titulaire dispose
          de droits concernant ses donn&eacute;es personnelles qu&rsquo;il
          pourra exercer en &eacute;crivant &agrave; l&rsquo;adresse postale
          mentionn&eacute;e au point 16.1 en l&rsquo;adressant au DPO ou en
          &eacute;crivant &agrave; dpo@lemonway.com.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2 start" start="1">
        <li class="c9">
          <span class="c22">&Oslash; Droit d&rsquo;acc&egrave;s</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire dispose de la facult&eacute; d&rsquo;acc&eacute;der aux
          donn&eacute;es personnelles le concernant. Cependant, pour des motifs
          de s&eacute;curit&eacute; et de confidentialit&eacute;, la demande ne
          pourra &ecirc;tre trait&eacute;e que si le Titulaire apporte la preuve
          de son identit&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway peut s&rsquo;opposer ou mettre en place une facturation pour
          les demandes manifestement abusives (nombre important de demandes,
          caract&egrave;re r&eacute;p&eacute;titif ou
          syst&eacute;matique).</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="2">
        <li class="c9">
          <span class="c22">&Oslash; Droit de rectification</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de demander la rectification de ses
          donn&eacute;es personnelles lorsque celles-ci sont inexactes,
          erron&eacute;es, incompl&egrave;tes ou obsol&egrave;tes.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="3">
        <li class="c9">
          <span class="c22">&Oslash; Droit &agrave; limitation</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de demander la limitation de ses
          donn&eacute;es personnelles. Lorsque le droit &agrave; limitation est
          demand&eacute;, Lemonway pourra seulement stocker les donn&eacute;es.
          Aucune autre op&eacute;ration ne pourra avoir lieu.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="4">
        <li class="c9">
          <span class="c22">&Oslash; Droit &agrave; portabilit&eacute;</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire conserve la facult&eacute; de demander de
          r&eacute;cup&eacute;rer les donn&eacute;es personnelles qu&rsquo;il a
          fourni &agrave; Lemonway, dans un format structur&eacute;, couramment
          utilis&eacute; et lisible par machine afin de les transmettre &agrave;
          un autre responsable de traitement. Ce droit ne peut &ecirc;tre
          utilis&eacute; que si le traitement des donn&eacute;es est bas&eacute;
          sur le consentement de la personne concern&eacute;e ou sur un
          contrat.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="5">
        <li class="c9">
          <span class="c22">&Oslash; Droit d&rsquo;opposition</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de s&rsquo;opposer &agrave;
          l&rsquo;utilisation de ses donn&eacute;es dans deux situations :</span
        >
      </p>
      <ul class="c2 lst-kix_list_14-0 start">
        <li class="c0 c18">
          <span class="c1">Motifs l&eacute;gitimes ;</span>
        </li>
        <li class="c0 c18">
          <span class="c1"
            >En cas d&rsquo;utilisation des donn&eacute;es recueillies &agrave;
            des fins commerciales.</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-2 start" start="1">
        <li class="c9">
          <span class="c22">&Oslash; Droit &agrave; l&rsquo;effacement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de demander l&rsquo;effacement de
          ses donn&eacute;es dans les meilleurs d&eacute;lais si l&rsquo;un des
          motifs du paragraphe 1 de l&rsquo;article 17 du R&egrave;glement
          G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es
          s&rsquo;applique.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Si les donn&eacute;es du Titulaire ont &eacute;t&eacute; transmises
          &agrave; d&rsquo;autres entit&eacute;s, le m&eacute;canisme du &laquo;
          droit &agrave; l&rsquo;oubli &raquo; s&rsquo;enclenche : Lemonway
          devra prendre toutes les mesures raisonnables pour informer les autres
          entit&eacute;s que la personne concern&eacute;e a demand&eacute;
          l&rsquo;effacement de tout lien vers ses donn&eacute;es personnelles,
          ou de toute copie ou reproduction de celles-ci.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="2">
        <li class="c9"><span class="c22">&Oslash; Droit post mortem</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de d&eacute;finir des directives
          concernant les donn&eacute;es personnelles du Titulaire apr&egrave;s
          son d&eacute;c&egrave;s. Le cas &eacute;ch&eacute;ant, les
          h&eacute;ritiers du Titulaire peuvent exiger de prendre en
          consid&eacute;ration le d&eacute;c&egrave;s ou proc&eacute;der aux
          mises &agrave; jour.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="8">
        <li class="c9">
          <span class="c10">16.12 D&eacute;lai de r&eacute;ponse</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway s&rsquo;engage &agrave; r&eacute;pondre aux demandes
          relatives aux donn&eacute;es &agrave; caract&egrave;re personnel
          d&rsquo;acc&egrave;s ou &agrave; l&rsquo;exercice d&rsquo;un droit
          dans un d&eacute;lai d&rsquo;un (1) mois &agrave; compter de la
          r&eacute;ception de la demande.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="9">
        <li class="c9">
          <span class="c10">16.13 Transfert de donn&eacute;es</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway a recours &agrave; des prestataires habilit&eacute;s se
          situant &agrave; l&rsquo;int&eacute;rieur et &agrave;
          l&rsquo;ext&eacute;rieur de l&rsquo;Union Europ&eacute;enne.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas de transfert vers un pays tiers, Lemonway respecte le
          R&egrave;glement G&eacute;n&eacute;ral sur la Protection des
          Donn&eacute;es en ayant recours &agrave; des partenaires ou
          sous-traitant pr&eacute;sentant des garanties ad&eacute;quates par le
          biais d&rsquo;une proc&eacute;dure d&rsquo;ad&eacute;quation, de
          clauses contractuelles types ou de r&egrave;gles internes
          d&rsquo;entreprise.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="10">
        <li class="c9">
          <span class="c10"
            >16.14 Commission Nationale Informatique et Libert&eacute;s
            (CNIL)</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Si le Titulaire consid&egrave;re que Lemonway ne respecte pas ses
          obligations au regard de Loi Informatique et Libert&eacute;s ou du
          R&egrave;glement G&eacute;n&eacute;ral sur la Protection des
          Donn&eacute;es, il pourra adresser une plainte ou une demande
          aupr&egrave;s de l&rsquo;autorit&eacute; comp&eacute;tente. Le
          si&egrave;ge social de Lemonway se situant en France,
          l&rsquo;autorit&eacute; comp&eacute;tente est la Commission Nationale
          Informatique et Libert&eacute;s. Le Titulaire a la possibilit&eacute;
          de s&rsquo;adresser &agrave; la Commission Nationale Informatique et
          Libert&eacute;s par voie &eacute;lectronique via le lien suivant :
          https://www.cnil.fr/fr/plaintes/internet.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="28">
        <li class="c20" ref="h.2lwamvv">
          <span class="c12">17. CONVENTION DE PREUVE</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les communications effectu&eacute;es par le biais de courrier
          &eacute;lectronique sont des modes de communication valablement admis
          &agrave; titre de preuve par le Titulaire et Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Toutes les informations enregistr&eacute;es dans les bases de
          donn&eacute;es informatiques de Lemonway relatives notamment aux
          Ordres et Op&eacute;rations de paiement, ont, jusqu&rsquo;&agrave;
          preuve du contraire, la m&ecirc;me force probante qu&rsquo;un
          &eacute;crit sign&eacute; sur un support papier, tant en ce qui
          concerne leur contenu qu&rsquo;en ce qui concerne la date et
          l&rsquo;heure &agrave; laquelle ils ont &eacute;t&eacute;
          effectu&eacute;s et/ou re&ccedil;us. Ces traces inalt&eacute;rables,
          s&ucirc;res et fiables sont grav&eacute;es et conserv&eacute;es dans
          les syst&egrave;mes informatiques de Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les documents de Lemonway reproduisant ces informations, ainsi que
          les copies ou reproductions de documents produits par Lemonway ont la
          m&ecirc;me force probante que l&rsquo;original, jusqu&rsquo;&agrave;
          preuve du contraire.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="29">
        <li class="c20" ref="h.111kx3o">
          <span class="c12">18. BLOCAGE ET GEL DU COMPTE</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">18.1 Blocage du Compte de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >La suspension temporaire et imm&eacute;diate d&rsquo;un Compte de
          paiement peut &ecirc;tre prononc&eacute;e par Lemonway pour toute
          raison &agrave; l&rsquo;appr&eacute;ciation de Lemonway et notamment
          :</span
        >
      </p>
      <ul class="c2 lst-kix_list_15-0 start">
        <li class="c0 c18">
          <span class="c1"
            >Si le Titulaire n&rsquo;a pas respect&eacute; les dispositions du
            Contrat-cadre ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Si le Titulaire a fourni &agrave; Lemonway des donn&eacute;es
            d&rsquo;identification inexactes, p&eacute;rim&eacute;es ou
            incompl&egrave;tes ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >En cas de risque sensiblement accru d&rsquo;incapacit&eacute; par
            le Titulaire de s&rsquo;acquitter de son obligation de paiement
            ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >En cas de r&eacute;ception par Lemonway d&rsquo;un nombre important
            de remboursements, d&rsquo;impay&eacute;s, d&rsquo;annulation
            d&rsquo;Ordres ou de contestation pour Op&eacute;rations de paiement
            non autoris&eacute;es.</span
          >
        </li>
      </ul>
      <p class="c0">
        <span class="c1"
          >Cette d&eacute;cision est notifi&eacute;e au Titulaire par tout
          moyen. La suspension du Compte de paiement ayant pour objet de
          prot&eacute;ger le Titulaire, elle ne pourra en aucun cas donner lieu
          au versement de dommages int&eacute;r&ecirc;ts au profit de ce
          dernier.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La r&eacute;activation du Compte de paiement se fera &agrave; la
          discr&eacute;tion de Lemonway, sur la base d&rsquo;informations ou de
          documents additionnels qui pourront &ecirc;tre
          r&eacute;clam&eacute;s.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En fonction de la gravit&eacute; du manquement au Contrat-cadre et
          notamment en cas d&rsquo;utilisation du Compte de paiement &agrave;
          des fins illicites ou contraire aux bonnes m&oelig;urs, Lemonway se
          r&eacute;serve le droit de r&eacute;silier le Contrat-cadre
          conform&eacute;ment aux dispositions de l&rsquo;article 19.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway se r&eacute;serve le droit d&rsquo;appliquer des
          p&eacute;nalit&eacute;s, des frais de gestion et de r&eacute;clamer
          des dommages et int&eacute;r&ecirc;ts au Titulaire.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9">
          <span class="c10">18.2 Gel du Compte de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Dans le cadre du dispositif de filtrage mis en place par Lemonway
          (notamment bas&eacute; sur les listes de sanctions internationales et
          de personnes politiquement expos&eacute;es), le gel imm&eacute;diat
          d&rsquo;un Compte de paiement pourra &ecirc;tre prononc&eacute; en cas
          de risque de fraude, de blanchiment de capitaux ou financement du
          terrorisme ou de risque pouvant affecter la s&eacute;curit&eacute; du
          Compte de paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le gel du Compte de paiement sera lev&eacute; ou non apr&egrave;s
          analyse approfondie du risque par les &eacute;quipes LCB-FT de
          Lemonway, et les fonds gel&eacute;s seront trait&eacute;s
          conform&eacute;ment aux mesures d&eacute;finies par la Direction
          g&eacute;n&eacute;rale du Tr&eacute;sor.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="30">
        <li class="c20" ref="h.3l18frh">
          <span class="c12">19. R&Eacute;SILIATION DU CONTRAT-CADRE</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut r&eacute;silier de plein droit le Contrat-cadre qui
          emportera cl&ocirc;ture de son Compte de paiement par tout moyen
          suivant le respect d&rsquo;un pr&eacute;avis d&rsquo;un (1) mois. Il
          doit maintenir une Provision suffisante pour assurer la bonne fin des
          Op&eacute;rations de paiement en cours pendant le d&eacute;lai
          n&eacute;cessaire &agrave; leur d&eacute;nouement et le paiement des
          frais dus par lui.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway peut r&eacute;silier de plein droit le Contrat-cadre qui
          emportera cl&ocirc;ture du Compte de paiement par lettre
          recommand&eacute;e avec avis de r&eacute;ception suivant un
          pr&eacute;avis de deux (2) mois.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas de manquement grave d&rsquo;une Partie, le Contrat-cadre peut
          &ecirc;tre r&eacute;sili&eacute; avec effet imm&eacute;diat. Il est
          entendu par manquements graves r&eacute;alis&eacute;s par le Titulaire
          : communication de fausses informations ; exercice
          d&rsquo;activit&eacute; ill&eacute;gale, contraire aux bonnes
          m&oelig;urs, de blanchiment de capitaux ou de financement du
          terrorisme ; menaces &agrave; l&rsquo;encontre de
          pr&eacute;pos&eacute;s de Lemonway ou du Partenaire ; d&eacute;faut de
          paiement ; non-respect d&rsquo;une obligation du Titulaire au titre
          des pr&eacute;sentes ; r&eacute;siliation des relations entre le
          Titulaire et le Partenaire ; r&eacute;siliation des relations entre le
          Partenaire et Lemonway ; surendettement ou pour les personnes morales
          nomination d&rsquo;un mandataire ad hoc, d&rsquo;un administrateur
          judiciaire, ouverture d&rsquo;une proc&eacute;dure de redressement ou
          de liquidation. Il est entendu par manquements graves
          r&eacute;alis&eacute;s par Lemonway : communication de fausses
          informations ; irrespect d&rsquo;une obligation au titre des
          pr&eacute;sentes ; nomination d&rsquo;un mandataire ad hoc, d&rsquo;un
          administrateur judiciaire, ouverture d&rsquo;une proc&eacute;dure de
          redressement ou de liquidation.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas de modification de la r&eacute;glementation applicable et de
          l&rsquo;interpr&eacute;tation qui en est faite par
          l&rsquo;autorit&eacute; de r&eacute;gulation concern&eacute;e
          affectant la capacit&eacute; de Lemonway ou de ses mandataires
          &agrave; ex&eacute;cuter les Op&eacute;rations de paiement, le
          Contrat-cadre sera automatiquement r&eacute;sili&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire ne pourra plus transmettre d&rsquo;Ordre de paiement
          &agrave; compter de la date d&rsquo;effet de la r&eacute;siliation. Le
          Compte pourra &ecirc;tre maintenu pendant une dur&eacute;e de 13 mois
          &agrave; l&rsquo;effet de couvrir les &eacute;ventuelles contestations
          et r&eacute;clamations ult&eacute;rieures. Les Op&eacute;rations de
          paiement initi&eacute;es avant la date d&rsquo;effet de la
          r&eacute;siliation ne seront pas remises en cause par la demande de
          r&eacute;siliation et devront &ecirc;tre ex&eacute;cut&eacute;es dans
          les termes du Contrat-cadre.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La r&eacute;siliation du Contrat-cadre entra&icirc;ne la
          cl&ocirc;ture d&eacute;finitive du Compte de paiement. La
          cl&ocirc;ture d&rsquo;un Compte de paiement ne pourra donner lieu
          &agrave; aucune indemnit&eacute; quels que soient les &eacute;ventuels
          dommages occasionn&eacute;s par la fermeture de ce Compte de paiement.
          Le Titulaire dont le Compte a &eacute;t&eacute; cl&ocirc;tur&eacute;
          par Lemonway n&rsquo;est pas autoris&eacute;, sauf accord
          expr&egrave;s de Lemonway, &agrave; ouvrir un autre Compte de
          paiement. Tout Compte de paiement ouvert en violation de cette
          disposition pourra &ecirc;tre imm&eacute;diatement
          cl&ocirc;tur&eacute; par Lemonway, sans pr&eacute;avis.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La Provision sur le Compte de paiement objet de la cl&ocirc;ture
          donnera droit &agrave; un virement au profit du Titulaire de ce compte
          suivant ses instructions sous r&eacute;serve (i) des Op&eacute;rations
          de paiement en cours et des &eacute;ventuels impay&eacute;s, rejets
          bancaires ou oppositions &agrave; venir et (ii) des mesures
          applicables au gel des avoirs telles que d&eacute;finies par la
          Direction G&eacute;n&eacute;rale du Tr&eacute;sor. Si un successeur
          est d&eacute;sign&eacute; par Lemonway, il pourra &ecirc;tre
          propos&eacute; au Titulaire de cl&ocirc;turer son Compte de paiement
          et de transf&eacute;rer la Provision sur un nouveau compte de paiement
          ouvert dans les livres de l&rsquo;&eacute;tablissement
          d&eacute;sign&eacute; comme successeur.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway se r&eacute;serve le droit de demander en justice
          r&eacute;paration du pr&eacute;judice qu&rsquo;elle aurait subi du
          fait de la violation du Contrat-cadre. La cl&ocirc;ture du Compte de
          paiement pourra donner lieu &agrave; des frais dans la limite de
          l&rsquo;article L. 314-13 du Code mon&eacute;taire et financier.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="31">
        <li class="c20" ref="h.206ipza">
          <span class="c12">20. MODIFICATION DU CONTRAT-CADRE</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les dispositions du Contrat-cadre sont susceptibles
          d&rsquo;&ecirc;tre modifi&eacute;es ou compl&eacute;t&eacute;es
          &agrave; tout moment, notamment en vue de se conformer &agrave; toute
          &eacute;volution l&eacute;gislative, r&egrave;glementaire,
          jurisprudentielle ou technologique.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Tout projet de modification du Contrat-cadre est communiqu&eacute;
          sur support papier ou sur un autre support durable au Titulaire au
          plus tard deux (2) mois avant la date d&rsquo;application
          propos&eacute;e pour son entr&eacute;e en vigueur.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En l&rsquo;absence de contestation &eacute;crite par lettre
          recommand&eacute;e avec avis de r&eacute;ception adress&eacute;e
          &agrave; Lemonway par le Titulaire avant l&rsquo;expiration de ce
          d&eacute;lai de deux mois, ce dernier est r&eacute;put&eacute; avoir
          accept&eacute; ces modifications. En cas de refus de la modification
          propos&eacute;e, le Titulaire peut r&eacute;silier sur demande
          &eacute;crite le Contrat-cadre sans frais, avant la date
          d&rsquo;entr&eacute;e en vigueur propos&eacute;e de celle-ci. Cette
          demande n&rsquo;affecte pas l&rsquo;ensemble des d&eacute;bits (frais,
          cotisations, paiement) dont le Titulaire reste redevable.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="32">
        <li class="c20" ref="h.4k668n3">
          <span class="c12">21. GENERALITES</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Au cas o&ugrave; des formalit&eacute;s administratives seraient
          n&eacute;cessaires au titre de l&rsquo;ex&eacute;cution du
          pr&eacute;sent Contrat-cadre, Lemonway et le Titulaire se
          pr&ecirc;teront mutuelle assistance pour la r&eacute;gularisation de
          ces formalit&eacute;s.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Si l&rsquo;une quelconque des stipulations non substantielles du
          Contrat-cadre est nulle au regard d&rsquo;une r&egrave;gle de droit en
          vigueur, elle sera r&eacute;put&eacute;e non &eacute;crite, mais
          n&rsquo;entra&icirc;nera pas la nullit&eacute; du pr&eacute;sent
          Contrat-cadre.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le fait pour l&rsquo;une des Parties de ne pas se pr&eacute;valoir
          d&rsquo;un manquement par l&rsquo;autre Partie &agrave; l&rsquo;une
          quelconque des obligations vis&eacute;es dans les pr&eacute;sentes ne
          saurait &ecirc;tre interpr&eacute;t&eacute; pour l&rsquo;avenir comme
          une renonciation &agrave; l&rsquo;obligation en cause.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas de difficult&eacute; d&rsquo;interpr&eacute;tation entre
          l&rsquo;un quelconque des titres et l&rsquo;une quelconque des clauses
          des conditions g&eacute;n&eacute;rales il ne sera pas tenu compte des
          titres.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le pr&eacute;sent Contrat-cadre a &eacute;t&eacute;
          r&eacute;dig&eacute; en fran&ccedil;ais et en anglais puis traduit
          dans d&rsquo;autres langues &agrave; titre d&rsquo;information
          uniquement. En cas de difficult&eacute; d&rsquo;interpr&eacute;tation,
          les versions fran&ccedil;aises et anglaises du Contrat-cadre
          pr&eacute;vaudront sur toute autre traduction existante.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="33">
        <li class="c20" ref="h.2zbgiuw">
          <span class="c12">22. DROIT APPLICABLE ET JURIDICTIONS</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le pr&eacute;sent Contrat-cadre est r&eacute;gi par le droit
          fran&ccedil;ais.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Sauf disposition imp&eacute;rative contraire, tout litige relatif
          &agrave; son ex&eacute;cution, son interpr&eacute;tation ou sa
          validit&eacute;, sera port&eacute; devant les cours et tribunaux
          comp&eacute;tents et &agrave; d&eacute;faut de Paris.</span
        >
      </p>
    </v-container>
    <v-container fluid v-if="$route.params.type === 'cgu-structures'">
      <p class="c26">
        <span class="c24"
          >Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation
          Structure</span
        >
      </p>
      <p class="c6 c19"><span class="c1"></span></p>
      <p class="c26">
        <span class="c24" style="text-align: justify;"
          >Partenariat avec les ligues, les comit&eacute;s territoriaux, les
          clubs, sections de club et &eacute;tablissements affili&eacute;s
          &agrave; la f&eacute;d&eacute;ration fran&ccedil;aise de la montagne
          et de l&rsquo;escalade ainsi que les membres associ&eacute;s &agrave;
          la f&eacute;d&eacute;ration fran&ccedil;aise de la montagne et de
          l&rsquo;escalade.</span
        >
      </p>
      <p class="c6 c19"><span class="c1"></span></p>
      <p class="c6">
        <span class="c1"
          >FFME, 10 quai de la Marne, Paris 75019<br />784 354 193 00046-APE 926
          C</span
        >
      </p>
      <p class="c6 c19"><span class="c1"></span></p>
      <p class="c6">
        <span class="c1"
          >Les ligues, les comit&eacute;s territoriaux, les clubs, sections de
          club et &eacute;tablissements affili&eacute;s &agrave; la
          f&eacute;d&eacute;ration fran&ccedil;aise de la montagne et de
          l&rsquo;escalade ainsi que les membres associ&eacute;s &agrave; la
          f&eacute;d&eacute;ration fran&ccedil;aise de la montagne et de
          l&rsquo;escalade sont invit&eacute;s &agrave; lire attentivement et
          &agrave; comprendre les pr&eacute;sentes conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation. Ils s&rsquo;engagent
          &agrave; respecter la totalit&eacute; des dispositions qu&rsquo;elles
          contiennent. La navigation sur le site myFFME et l&rsquo;acc&egrave;s
          au service propos&eacute; par la FFME supposent l&rsquo;acceptation
          pleine et enti&egrave;re des pr&eacute;sentes conditions</span
        >
      </p>
      <p class="c6">
        <span class="c7"
          >Derni&egrave;re mise &agrave; jour : 21 octobre 2020</span
        >
      </p>
      <p class="c32"><span class="c12">Table des mati&egrave;res</span></p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1egqt2p"
            >Pr&eacute;ambule</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1egqt2p"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3ygebqi"
            >D&eacute;finitions</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3ygebqi"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.sqyw64"
            >Objet</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.sqyw64"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3cqmetx"
            >Description du Service fourni par la FFME</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3cqmetx"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1rvwp1q"
            >1 - Principes de fonctionnement du Service</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1rvwp1q"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4bvk7pj"
            >2 - Modalit&eacute;s de mise en &oelig;uvre du Service</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4bvk7pj"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2r0uhxc"
            >3 - Disponibilit&eacute; du Service</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2r0uhxc"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1664s55"
            >4 - Evolutions du Service</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1664s55"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3q5sasy"
            >Vos engagements</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3q5sasy"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.25b2l0r"
            >1 - Accorder une licence non exclusive &agrave; la FFME</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.25b2l0r"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.kgcv8k"
            >2 - Placer des Contenus en ligne</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.kgcv8k"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.34g0dwd"
            >3 - Accepter le syst&egrave;me de paiement propos&eacute; par la
            FFME et g&eacute;r&eacute; par un &eacute;tablissement
            agr&eacute;&eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.34g0dwd"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1jlao46"
            >4 - Fournir des &eacute;l&eacute;ments d&rsquo;identification</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1jlao46"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.43ky6rz"
            >5 - Utiliser les fonds de mani&egrave;re conforme &agrave; la
            description mise en ligne</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.43ky6rz"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2iq8gzs"
            >Conditions financi&egrave;res</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2iq8gzs"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.xvir7l"
            >1 - D&eacute;termination des revenus</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.xvir7l"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3hv69ve"
            >2 - D&eacute;termination des sommes dues</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3hv69ve"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1x0gk37"
            >3 - Cr&eacute;ation d&rsquo;un compte de paiement en cas
            d&rsquo;utilisation du syst&egrave;me de paiement Lemonway</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1x0gk37"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4h042r0"
            >4 - Conditions pr&eacute;alables au versement des sommes
            collect&eacute;es par votre Structure</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4h042r0"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2w5ecyt"
            >5 - Documents juridiques</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2w5ecyt"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1baon6m"
            >6 &ndash; Remboursement</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1baon6m"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3vac5uf"
            >Propri&eacute;t&eacute; intellectuelle FFME</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3vac5uf"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2afmg28"
            >G&eacute;n&eacute;ralit&eacute;s</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2afmg28"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.pkwqa1"
            >1 - Responsabilit&eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.pkwqa1"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.39kk8xu"
            >2 - Donn&eacute;es personnelles</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.39kk8xu"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1opuj5n"
            >3 - Confidentialit&eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1opuj5n"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.48pi1tg"
            >4 - Dur&eacute;e</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.48pi1tg"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2nusc19"
            >5 - Suspension</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2nusc19"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1302m92"
            >6 - R&eacute;siliation</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1302m92"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3mzq4wv"
            >7 - Droit applicable</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3mzq4wv"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2250f4o"
            >8 - M&eacute;diation - Juridiction</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2250f4o"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.319y80a"
            >9 - Domiciliation</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.319y80a"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1gf8i83"
            >10 - Nullit&eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1gf8i83"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.40ew0vw"
            >11 - Tol&eacute;rance</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.40ew0vw"></a
        ></span>
      </p>
      <p class="c8">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2fk6b3p"
            >12 - Notifications et Communications</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2fk6b3p"></a
        ></span>
      </p>
      <p class="c13">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.upglbi"
            >Modification des Conditions G&eacute;n&eacute;rales
            d&#39;Utilisation</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.upglbi"></a
        ></span>
      </p>
      <p class="c6">
        <span class="c1"
          >ANNEXE 1 - Conditions g&eacute;n&eacute;rales d&rsquo;utilisation du
          service de paiement Lemon Way</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="34">
        <li class="c25" ref="h.1egqt2p">
          <span class="c12">Pr&eacute;ambule</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME a mis au point et d&eacute;veloppe un panel d&rsquo;outils
          simplifiant la gestion quotidienne des ligues, des comit&eacute;s
          territoriaux, des clubs, sections de club et &eacute;tablissements
          affili&eacute;s &agrave; la FFME ainsi qu&rsquo;aux membres
          associ&eacute;s &agrave; la FFME.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Ces outils appr&eacute;hendent toutes les op&eacute;rations
          susceptibles d&rsquo;engendrer un paiement, comme par exemple la
          billetterie li&eacute;e aux &eacute;v&egrave;nements organis&eacute;s
          par une Structure ou encore les adh&eacute;sions &agrave; celui-ci,
          sans que cette liste ne soit exhaustive.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Gr&acirc;ce &agrave; l&rsquo;utilisation de l&rsquo;un des
          syst&egrave;mes de paiement associ&eacute;, les outils de collecte
          propos&eacute;s par la FFME prennent en charge les paiements par carte
          bancaire.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Si votre Structure souhaite b&eacute;n&eacute;ficier de ce service
          pour p&eacute;renniser ses actions, l&rsquo;acceptation des
          pr&eacute;sentes conditions &eacute;tablies pour fixer les
          modalit&eacute;s de notre partenariat est obligatoire.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Celui-ci implique &eacute;galement d&rsquo;accepter les conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation propos&eacute;es par nos
          prestataires de services de paiement, figurant en annexe et cela
          conform&eacute;ment &agrave; la description du Service des
          pr&eacute;sentes.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Le service fourni par La FFME s&rsquo;appuie n&eacute;cessairement
          sur un compte de paiement dont les modalit&eacute;s d&rsquo;ouverture
          et de gestion sont fix&eacute;es par les pr&eacute;sentes et par les
          conditions g&eacute;n&eacute;rales d&rsquo;utilisation
          propos&eacute;es par la soci&eacute;t&eacute; Lemonway, figurant en
          annexe.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="35">
        <li class="c25" ref="h.3ygebqi">
          <span class="c12">D&eacute;finitions</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Les termes ci-apr&egrave;s d&eacute;finis et dont la premi&egrave;re
          lettre figure en lettres majuscules dans le pr&eacute;sent Accord ont
          la signification qui leur est attribu&eacute;e au pr&eacute;sent
          article.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Accord</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe le pr&eacute;sent document, ses Annexes (en
          particulier les conditions g&eacute;n&eacute;rales d&rsquo;utilisation
          propos&eacute;es par le prestataire de services de paiement Lemonway)
          ainsi que ses &eacute;ventuels avenants ult&eacute;rieurs.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Annexe</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe toute annexe au pr&eacute;sent Accord (y
          compris le contrat conclu avec le prestataire de services de paiement
          Lemonway).</span
        >
      </p>
      <p class="c6">
        <span class="c14">Compte de paiement</span
        ><span class="c1"
          >&nbsp;: compte ouvert dans les livres de Lemonway.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Contenus</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe les textes, photos, vid&eacute;os et toute
          autre forme de contenu mis en ligne par votre Structure sur le
          Service. Les Contenus sont utilis&eacute;s sur l&rsquo;ensemble des
          sites de la FFME.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Contributeur</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe la personne qui s&rsquo;est inscrite sur le
          site myFFME et qui r&eacute;alise un paiement par carte bancaire au
          profit de votre Structure.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Donn&eacute;es de trafic</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe les donn&eacute;es communiqu&eacute;es
          &agrave; la FFME par ses Partenaires relativement au trafic et sur la
          foi desquelles les paiements sont effectu&eacute;s.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Internaute</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe une personne navigant sur les sites FFME, sans
          &ecirc;tre inscrit sur myFFME.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Logo</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe le logo destin&eacute; &agrave;
          l&rsquo;identification de votre Structure aupr&egrave;s du
          public.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Membres</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe les membres de votre Structure.</span
        >
      </p>
      <p class="c6" ref="h.2dlolyb">
        <span class="c14">Structure</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe les ligues, les comit&eacute;s territoriaux,
          les clubs, sections de club et &eacute;tablissements affili&eacute;s
          &agrave; la f&eacute;d&eacute;ration fran&ccedil;aise de la montagne
          et de l&rsquo;escalade ainsi que les membres associ&eacute;s &agrave;
          la f&eacute;d&eacute;ration fran&ccedil;aise de la montagne et de
          l&rsquo;escalade domicili&eacute;es en France m&eacute;tropolitaine ou
          DROM-COM utilisant les outils propos&eacute;s par la FFME.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Propri&eacute;t&eacute; intellectuelle FFME</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe tout &eacute;l&eacute;ment susceptible de
          protection par le droit de la propri&eacute;t&eacute; intellectuelle
          ou industrielle, tel que notamment, le logo, la marque et les contenus
          originaux de myFFME.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Service</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe le service en ligne de la FFME disponible
          &agrave; l&rsquo;adresse https://myffme.fr, c&rsquo;est-&agrave;-dire
          l&rsquo;ensemble des outils mis &agrave; la disposition des
          Structures.</span
        >
      </p>
      <p class="c6">
        <span class="c14">Utilisateur</span
        ><span class="c1"
          >&nbsp;: d&eacute;signe la personne qui s&rsquo;est inscrite sur le
          site myFFME et dont l&rsquo;identit&eacute; est connue, qu&rsquo;il
          s&rsquo;agisse d&rsquo;un Internaute, un licenci&eacute; ou une
          Structure.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="36">
        <li class="c25" ref="h.sqyw64"><span class="c12">Objet</span></li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Le pr&eacute;sent Accord a pour objet de pr&eacute;ciser les
          obligations respectives de votre Structure et de la FFME en vue
          :</span
        >
      </p>
      <ul class="c2 lst-kix_list_16-0 start">
        <li class="c6 c23">
          <span class="c1"
            >de l&rsquo;utilisation des diff&eacute;rents outils propos&eacute;s
            par la FFME</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >de l&rsquo;utilisation du compte de paiement associ&eacute;
            &agrave; ces outils
          </span>
        </li>
      </ul>
      <p class="c6">
        <span class="c1"
          >Le champ d&rsquo;application du pr&eacute;sent Accord concerne les
          Structures domicili&eacute;s en France m&eacute;tropolitaine, dans les
          DROM-COM et &agrave; l&rsquo;&eacute;tranger, conform&eacute;ment
          &agrave; la r&eacute;glementation en vigueur.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="37">
        <li class="c25" ref="h.3cqmetx">
          <span class="c12">Description du Service fourni par la FFME</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.1rvwp1q">
          <span class="c10">1 - Principes de fonctionnement du Service</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-2 start" start="1">
        <li class="c11">
          <span class="c22">1.1 - Description du Service</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME met &agrave; disposition de ses Utilisateurs un service en
          ligne innovant, leur permettant de g&eacute;rer leur Structure.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >L&rsquo;utilisation du Service implique d&rsquo;accepter les
          conditions d&rsquo;utilisation du syst&egrave;me de paiement
          propos&eacute; par la FFME (Lemonway ou tout autre syst&egrave;me de
          paiement propos&eacute; par la FFME &agrave; son libre choix, &agrave;
          tout moment).</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Cette inscription ouvre un compte de paiement g&eacute;r&eacute; par
          la soci&eacute;t&eacute; Lemonway conform&eacute;ment aux dispositions
          des pr&eacute;sentes et des conditions g&eacute;n&eacute;rales
          d&rsquo;utilisation figurant en annexe.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="2">
        <li class="c11"><span class="c22">1.2 - Mandat</span></li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La Structure mandate express&eacute;ment la FFME pour ouvrir un
          Compte de paiement et g&eacute;rer les flux li&eacute;s &agrave; son
          fonctionnement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c11" ref="h.4bvk7pj">
          <span class="c10"
            >2 - Modalit&eacute;s de mise en &oelig;uvre du Service</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La Structure disposera sur le site myffme.fr d&rsquo;une page
          d&eacute;di&eacute;e. La Structure disposera d&rsquo;un acc&egrave;s
          &agrave; cette page, et pourra modifier librement les informations qui
          y sont pr&eacute;sentes et dont il a seul la responsabilit&eacute;.
        </span>
      </p>
      <p class="c6">
        <span class="c1"
          >La Structure est seul responsable de la bonne utilisation de son
          compte par les membres de son personnel dans le respect des
          pr&eacute;sentes.
        </span>
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c11" ref="h.2r0uhxc">
          <span class="c10">3 - Disponibilit&eacute; du Service</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >D&rsquo;importants moyens techniques sont mis en &oelig;uvre pour
          assurer une disponibilit&eacute; du Service 24h/24, 7J/7 et 365J/an
          &agrave; l&rsquo;adresse www.myffme.fr, sous r&eacute;serve de cas de
          force majeure, de perturbations inh&eacute;rentes au r&eacute;seau
          Internet et des interruptions de maintenance.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME veille &agrave; pr&eacute;server la plus grande
          disponibilit&eacute; possible du Service pendant les op&eacute;rations
          de maintenance. En cons&eacute;quence, les op&eacute;rations de
          maintenance programm&eacute;es seront effectu&eacute;es selon les
          plages horaires qui g&ecirc;neront le moins le Service. Les
          op&eacute;rations de maintenance corrective interviendront, quant
          &agrave; elles, dans les conditions dict&eacute;es par les exigences
          techniques. L&rsquo;indisponibilit&eacute; temporaire, m&ecirc;me
          prolong&eacute;e, du Service ne saurait donner lieu &agrave; une
          quelconque compensation, quelle que soit son origine ou sa
          dur&eacute;e et ne peuvent engager la responsabilit&eacute; de la
          FFME.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c11" ref="h.1664s55">
          <span class="c10">4 - Evolutions du Service</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME accorde une grande importance &agrave; la qualit&eacute; du
          Service. C&rsquo;est pourquoi, la FFME peut &ecirc;tre amen&eacute;,
          sans toutefois y &ecirc;tre oblig&eacute;e de quelque mani&egrave;re
          que ce soit, &agrave; faire &eacute;voluer la pr&eacute;sentation, le
          contenu ou les fonctionnalit&eacute;s du Service. Votre Structure en
          sera inform&eacute;e dans son espace f&eacute;d&eacute;ral et prendra
          soin de modifier le cas &eacute;ch&eacute;ant ses Contenus pour les
          adapter au Service ainsi modifi&eacute;.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="38">
        <li class="c25" ref="h.3q5sasy">
          <span class="c12">Vos engagements</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Votre Structure s&rsquo;engage &agrave; ex&eacute;cuter loyalement
          chacune des obligations d&eacute;taill&eacute;es au pr&eacute;sent
          article. Cet engagement est d&eacute;terminant et essentiel &agrave;
          l&rsquo;op&eacute;ration de partenariat convenue au titre des
          pr&eacute;sentes conditions g&eacute;n&eacute;rales.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c11" ref="h.25b2l0r">
          <span class="c10"
            >1 - Accorder une licence non exclusive &agrave; la FFME</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Votre Structure conc&egrave;de &agrave; la FFME sur son Logo, sur sa
          Marque et, sous r&eacute;serve de protection par le droit
          d&rsquo;auteur, sur ses Contenus, une licence non-exclusive, pour les
          seuls besoins du partenariat tels que d&eacute;finis aux
          pr&eacute;sentes conditions g&eacute;n&eacute;rales, de reproduction,
          de diffusion et de repr&eacute;sentation sur tout support papier,
          analogique, &eacute;lectronique ou opto-num&eacute;rique, et sur tout
          r&eacute;seau connu ou inconnu &agrave; ce jour, y compris
          l&rsquo;Internet, la t&eacute;l&eacute;vision et les r&eacute;seaux de
          t&eacute;l&eacute;communications mobiles.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >En contrepartie des engagements financiers pris par la FFME au titre
          du partenariat, la pr&eacute;sente licence est conc&eacute;d&eacute;e
          gratuitement pour le monde entier et pour toute la dur&eacute;e des
          pr&eacute;sentes conditions g&eacute;n&eacute;rales de partenariat. Il
          vous appartiendra ainsi, en cas d&rsquo;action sur le fondement du
          Logo, de la Marque ou des Contenus mis en ligne, de d&eacute;fendre et
          d&rsquo;indemniser la FFME contre toute cons&eacute;quence
          p&eacute;cuniaire et tous frais expos&eacute;s &agrave;
          l&rsquo;occasion d&rsquo;une telle action. En ex&eacute;cution de la
          pr&eacute;sente licence, la FFME s&rsquo;engage &agrave; respecter
          l&rsquo;image de marque de votre Structure. A ce titre la FFME
          soumettra &agrave; votre Structure les projets de communication
          utilisant votre Marque, Logo ou Contenus avant leur publication.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME pourra engager des actions de communication et de promotion
          et mettre en avant tout ou partie des Contenus, Marque ou Logo de
          votre Structure ; ce sur tout support &eacute;lectronique ou papier,
          tout r&eacute;seau y compris sur des r&eacute;seaux sociaux de type
          Facebook.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Des actions de communication communes pourront &eacute;galement
          &ecirc;tre organis&eacute;es par la FFME auxquelles votre Structure
          pourra &ecirc;tre convi&eacute;.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="6">
        <li class="c11" ref="h.kgcv8k">
          <span class="c10">2 - Placer des Contenus en ligne</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La pr&eacute;sence sur le Service de contenus de qualit&eacute;
          repr&eacute;sentatifs de la diversit&eacute; des actions de la
          Structure est un &eacute;l&eacute;ment cl&eacute; de la
          r&eacute;ussite du partenariat avec les Structures et de la
          p&eacute;rennit&eacute; du Service. Ainsi votre Structure devra mettre
          en ligne sur sa page d&eacute;di&eacute;e des Contenus
          personnalis&eacute;s afin de pr&eacute;senter ses activit&eacute;s.
          Ces Contenus devront &ecirc;tre renouvel&eacute;s et mis-&agrave;-jour
          autant que possible par votre Structure de mani&egrave;re &agrave;
          rendre votre Structure attractif aupr&egrave;s des Internautes. Dans
          le respect des valeurs de la communaut&eacute; FFME et des lois et
          r&egrave;glements applicables aux activit&eacute;s en ligne, votre
          Structure doit veiller de mani&egrave;re permanente &agrave; ne pas
          faire figurer de Contenus illicites ou ill&eacute;gaux, et notamment
          tous contenus violents, pornographiques, obsc&egrave;nes,
          ind&eacute;cents ou inadapt&eacute;s &agrave; un public familial,
          diffamatoires, injurieux, racistes, x&eacute;nophobes ou
          r&eacute;visionnistes ou qu&rsquo;ils portent atteinte &agrave; la
          dignit&eacute; humaine. De m&ecirc;me, aucun contenu mentionn&eacute;
          sur le site ne doit inciter &agrave; la haine raciale, la violence, la
          diffamation ou l&rsquo;injure, ou porter atteinte au droit &agrave;
          l&rsquo;image de quiconque ou aux droits de propri&eacute;t&eacute;
          intellectuelle ou industrielle d&rsquo;un tiers. De plus, aucun
          contenu mentionn&eacute; sur le site ne doit &ecirc;tre mensonger,
          trompeur ou proposant ou promouvant des activit&eacute;s illicites,
          frauduleuses ou trompeuses, ou contenu des &eacute;l&eacute;ments
          nuisibles aux syst&egrave;mes d&rsquo;information de tiers -tels que
          virus, vers, chevaux de Troie, etc.). Plus g&eacute;n&eacute;ralement,
          la Structure s&rsquo;interdit de diffuser tout contenu susceptible de
          porter atteinte, directement ou par incitation, aux droits de tiers ou
          d&rsquo;&ecirc;tre pr&eacute;judiciable &agrave; des tiers, de quelque
          mani&egrave;re et sous quelque forme que ce soit. Pour toutes les
          images, ainsi que les fichiers vid&eacute;o et musicaux
          utilis&eacute;s, la Structure doit disposer des droits d&rsquo;auteur
          suffisants. Si votre Structure met en ligne des photographies ou de la
          vid&eacute;o faisant appara&icirc;tre des personnes clairement
          identifiables, le consentement &eacute;crit des personnes
          concern&eacute;es par ces photos ou vid&eacute;os doit &ecirc;tre
          accord&eacute;.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Responsable de l&rsquo;ensemble des Contenus mis en ligne dans votre
          espace, votre Structure garantit le respect le plus absolu de ses
          engagements au titre des Contenus. La Structure s&rsquo;engage, en cas
          d&rsquo;action sur le fondement des Contenus mis en ligne, &agrave;
          d&eacute;fendre et &agrave; indemniser la FFME contre toute
          cons&eacute;quence p&eacute;cuniaire et &agrave; prendre en charge
          tous frais expos&eacute;s &agrave; l&rsquo;occasion d&rsquo;une telle
          action. La Structure est responsable de la v&eacute;racit&eacute; des
          informations communiqu&eacute;es en ligne et en aucun cas la
          responsabilit&eacute; de la FFME pourrait &ecirc;tre engag&eacute;e en
          raison des informations contenus sur le site.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="7">
        <li class="c11" ref="h.34g0dwd">
          <span class="c10"
            >3 - Accepter le syst&egrave;me de paiement propos&eacute; par la
            FFME et g&eacute;r&eacute; par un &eacute;tablissement
            agr&eacute;&eacute;</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >L&rsquo;acceptation des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation emporte acceptation du
          syst&egrave;me de paiement propos&eacute; par la FFME et
          g&eacute;r&eacute; par l&rsquo;un de nos partenaires. Ces derniers
          disposent d&rsquo;un agr&eacute;ment, conform&eacute;ment &agrave; la
          r&eacute;glementation bancaire et ont &eacute;t&eacute; choisis par la
          FFME pour assurer le traitement des op&eacute;rations de paiement
          li&eacute;es &agrave; l&rsquo;utilisation du Site.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >A cette fin, nous vous invitons &agrave; prendre connaissance des
          &eacute;l&eacute;ments d&rsquo;information sur les diff&eacute;rents
          syst&egrave;mes de paiement propos&eacute;s par la FFME, figurant
          ci-apr&egrave;s, en annexe des pr&eacute;sentes.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Le cas &eacute;ch&eacute;ant, l&rsquo;acceptation des
          pr&eacute;sentes conditions emporte acceptation des conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation propos&eacute;es par
          l&rsquo;&eacute;tablissement partenaire.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="8">
        <li class="c11" ref="h.1jlao46">
          <span class="c10"
            >4 - Fournir des &eacute;l&eacute;ments d&rsquo;identification</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Afin de pouvoir b&eacute;n&eacute;ficier de tous les Services de la
          FFME, votre Structure s&rsquo;engage &agrave; transmettre des
          informations exactes et tous les documents justificatifs
          n&eacute;cessaires &agrave; votre identification et garantit la FFME
          que les informations renseign&eacute;es &agrave; cette occasion sont
          exactes et qu&rsquo;elles ne sont entach&eacute;es d&rsquo;aucun
          caract&egrave;re trompeur.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Aussi en acceptant les pr&eacute;sentes CGU, votre Structure
          s&rsquo;engage &agrave; notifier spontan&eacute;ment et sans
          d&eacute;lai la FFME de toute mise &agrave; jour n&eacute;cessaire sur
          les &eacute;l&eacute;ments d&rsquo;identification de votre compte. La
          Structure reconna&icirc;t et accepte que les informations saisies aux
          fins de cr&eacute;ation ou de mise &agrave; jour de son compte valent
          preuve de son identit&eacute; ou de celle de ses repr&eacute;sentants.
          Il est seul responsable de la v&eacute;racit&eacute; desdites
          informations et de leur mise &agrave; jour, ce qu&rsquo;il
          reconna&icirc;t et accepte.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="9">
        <li class="c11" ref="h.43ky6rz">
          <span class="c10"
            >5 - Utiliser les fonds de mani&egrave;re conforme &agrave; la
            description mise en ligne</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Toute Structure reconnait et accepte que l&rsquo;intervention de la
          FFME se limite &agrave; la fourniture des Services tels que
          pr&eacute;vus aux pr&eacute;sentes et que la responsabilit&eacute; de
          la FFME est exclusivement limit&eacute;e &agrave; la fourniture des
          moyens techniques permettant aux Utilisateurs de
          b&eacute;n&eacute;ficier des Services.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME ne saurait en aucun cas &ecirc;tre responsable de tout litige
          intervenant entre une Structure et un Utilisateur et/ou tout dommage
          d&eacute;coulant des relations entre une Structure et un Utilisateur,
          notamment de la disponibilit&eacute;, la nature, la provenance des
          fonds, ainsi que relatif &agrave; l&rsquo;usage des fonds par la
          Structure. En cons&eacute;quence, toute r&eacute;clamation
          &eacute;ventuelle par un Utilisateur devra &ecirc;tre dirig&eacute;e
          en premier lieu vers l&rsquo;auteur du manquement objet de la
          r&eacute;clamation.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >&Agrave; cet &eacute;gard, la Structure s&rsquo;engage
          express&eacute;ment et garantit la FFME, ainsi que les Contributeurs,
          &agrave; utiliser les fonds vers&eacute;s conform&eacute;ment &agrave;
          la finalit&eacute; mentionn&eacute;e sur la fiche descriptive de la
          campagne de collecte &agrave; la date du paiement vers&eacute; par le
          Contributeur. La responsabilit&eacute; de la FFME ne pourra pas non
          plus &ecirc;tre engag&eacute;e en cas d&rsquo;utilisation des Services
          et/ou des fonds faite par un Utilisateur non conforme aux
          pr&eacute;sentes.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >En tout &eacute;tat de cause, la Structure garantit
          express&eacute;ment la FFME contre toute plainte, revendication et/ou
          action d&rsquo;un ou plusieurs Utilisateurs et/ou de tout tiers
          soutenant que l&rsquo;utilisation des fonds par la Structure
          constituerait une violation de ses droits et/ou lui causerait un
          pr&eacute;judice quelconque. En cons&eacute;quence, la Structure
          s&rsquo;engage &agrave; indemniser la FFME de tout pr&eacute;judice
          qu&rsquo;elle subirait et &agrave; prendre &agrave; sa charge tous les
          dommages-int&eacute;r&ecirc;ts, ainsi que les frais, charges et
          d&eacute;pens auxquels la FFME pourrait &ecirc;tre condamn&eacute;e,
          ou qui seraient pr&eacute;vus par un accord transactionnel
          sign&eacute; par la FFME apr&egrave;s avoir obtenu l&rsquo;accord
          pr&eacute;alable de la Structure.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="39">
        <li class="c25" ref="h.2iq8gzs">
          <span class="c12">Conditions financi&egrave;res</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.xvir7l">
          <span class="c10">1 - D&eacute;termination des revenus</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME propose aux internautes de r&eacute;aliser un paiement en
          ligne par carte bancaire au nom et pour le compte de votre
          Structure.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c11" ref="h.3hv69ve">
          <span class="c10">2 - D&eacute;termination des sommes dues</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME s&rsquo;engage &agrave; verser &agrave; votre Structure une
          somme &eacute;gale &agrave; 100% des paiements en ligne
          effectu&eacute;s &agrave; destination de votre Structure.
        </span>
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c11" ref="h.1x0gk37">
          <span class="c10"
            >3 - Cr&eacute;ation d&rsquo;un compte de paiement en cas
            d&rsquo;utilisation du syst&egrave;me de paiement Lemonway</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >L&rsquo;inscription sur le site myFFME emporte cr&eacute;ation
          d&rsquo;un compte de paiement g&eacute;r&eacute; par la
          soci&eacute;t&eacute; Lemonway, conform&eacute;ment aux conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation figurant en annexe. Ce
          compte de paiement centralise tous les paiements
          r&eacute;alis&eacute;s par les internautes au profit de votre
          Structure.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Cette ouverture de compte doit &ecirc;tre r&eacute;alis&eacute;e par
          l&#39;interm&eacute;diaire de la FFME agissant en tant qu&#39;agent
          prestataire de service de paiement pour le compte de la
          soci&eacute;t&eacute; Lemonway.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Le Compte de paiement est un compte permettant d&#39;enregistrer les
          op&eacute;rations de paiement et d&#39;encaissement
          r&eacute;alis&eacute;es par votre Structure. Il ne peut en aucun cas
          &ecirc;tre assimilable &agrave; un compte bancaire de
          d&eacute;p&ocirc;t.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >L&rsquo;ouverture de ce compte de paiement implique de communiquer
          &agrave; la FFME les pi&egrave;ces suivantes :</span
        >
      </p>
      <ul class="c2 lst-kix_list_17-0 start">
        <li class="c6 c23">
          <span class="c1"
            >Copie de l&#39;autorisation de l&#39;association (extrait du
            Journal Officiel) ;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1">Statuts de l&#39;association ;</span>
        </li>
        <li class="c6 c23">
          <span class="c1"
            >Proc&egrave;s-verbal de la derni&egrave;re Assembl&eacute;e
            G&eacute;n&eacute;rale, ou liste &agrave; jour des membres du bureau
            sign&eacute;e et dat&eacute;e de moins de deux ans ;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1">Photocopie du RIB au nom de la Structure ;</span>
        </li>
        <li class="c6 c23">
          <span class="c1"
            >Copie de la pi&egrave;ce d&#39;identit&eacute; du mandataire
            ;</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.4h042r0">
          <span class="c10"
            >4 - Conditions pr&eacute;alables au versement des sommes
            collect&eacute;es par votre Structure</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME fera parvenir, par virement, les sommes dues &agrave; la
          Structure sous r&eacute;serve que les pi&egrave;ces n&eacute;cessaires
          &agrave; l&rsquo;ouverture du compte aient &eacute;t&eacute; fournies
          et v&eacute;rifi&eacute;es par l&rsquo;&eacute;tablissement
          g&eacute;rant le service de paiement.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Les sommes dues seront vers&eacute;es selon les modalit&eacute;s
          choisies et param&eacute;tr&eacute;es par les Structures. Si la
          Structure s&eacute;lectionne un paiement mensuel, les sommes dues
          seront vers&eacute;es aux alentours du 10 du mois suivant celui au
          cours duquel le paiement a &eacute;t&eacute; effectu&eacute; par le
          Contributeur Si la Structure fait le choix de percevoir ses versements
          &ldquo;&agrave; la demande&rdquo; en activant la fonctionnalit&eacute;
          correspondante dans son Espace, les sommes dues seront disponibles au
          versement 7 jours ouvr&eacute;s apr&egrave;s la date du paiement du
          Contributeur. Toute demande de modification des informations de
          paiements entra&icirc;nera la suspension des versements de la
          Structure le temps de la v&eacute;rification de ces
          derni&egrave;res.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME se r&eacute;serve le droit de restreindre l&#39;acc&egrave;s
          des Structures &agrave; la fonctionnalit&eacute; de versement &agrave;
          la demande. Dans ce cas, la Structure restera, sous r&eacute;serve du
          respect des pr&eacute;sentes CGU, &eacute;ligible a minima au paiement
          mensuel.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La Structure reconna&icirc;t et accepte que le calendrier de paiement
          est donn&eacute; &agrave; titre d&rsquo;information et n&rsquo;engage
          en aucun cas la FFME.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >A compter de l&rsquo;activation de la demande de paiement, des
          d&eacute;lais bancaires, ind&eacute;pendants de la FFME, peuvent
          s&rsquo;appliquer entre le transfert et l&rsquo;arriv&eacute;e des
          fonds sur le compte bancaire de la Structure.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME se r&eacute;serve le droit de suspendre temporairement la
          fonctionnalit&eacute; de paiement &agrave; la demande afin de
          proc&eacute;der &agrave; des contr&ocirc;les ou &agrave; toute
          investigation sur le Compte de paiement que la FFME jugerait
          n&eacute;cessaire. La FFME se r&eacute;serve le droit de suspendre
          toute demande de virement initi&eacute;e par une Structure qui
          n&rsquo;aurait pas satisfait &agrave; la proc&eacute;dure
          d&rsquo;identification, dans le cadre de la proc&eacute;dure telle que
          d&eacute;finie par les r&egrave;gles de lutte contre le blanchiment de
          capitaux et contre le financement du terrorisme, comme dans le cadre
          d&rsquo;une proc&eacute;dure ad hoc exig&eacute;e par la FFME
          notamment en cas de soup&ccedil;on de fraude ou de doute sur la
          conformit&eacute; de l&rsquo;utilisation de son Service avec les
          pr&eacute;sentes CGU.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c11" ref="h.2w5ecyt">
          <span class="c10">5 - Documents juridiques</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Dans le cadre d&rsquo;un paiement en ligne, les Contributeurs doivent
          indiquer leur Nom, Pr&eacute;nom, Adresse Postale, Adresse Mail ainsi
          que d&eacute;finir le montant du versement fait &agrave; la Structure.
          Une fois que le Contributeur a finalis&eacute; son paiement sur le
          Service, la FFME lui envoie automatiquement par courrier
          &eacute;lectronique une confirmation de paiement.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Par ailleurs, la FFME peut mettre &agrave; disposition des
          Structures, &agrave; tout moment &agrave; son libre choix, &agrave;
          travers son site, de t&eacute;l&eacute;charger des mod&egrave;les
          types de documents juridiques pr&eacute;remplis, incluant des
          mod&egrave;les de statuts juridiques pour les associations. La
          Structure reconna&icirc;t et accepte que les documents
          pr&eacute;remplis sont des documents standards, nullement
          propos&eacute;s &agrave; la seule intention de la Structure en
          fonction de ses propres contraintes personnelles et qu&rsquo;ils ne
          r&eacute;pondront pas sp&eacute;cifiquement aux besoins et attentes de
          la Structure. En outre, la Structure reconna&icirc;t &ecirc;tre
          express&eacute;ment inform&eacute; et accepte que la FFME ne
          v&eacute;rifie pas l&rsquo;exactitude des informations contenues dans
          lesdites documents et ne garantit par la Structure que leur contenu
          est conforme aux dispositions l&eacute;gales en vigueur.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La Structure reconna&icirc;t et accepte que la responsabilit&eacute;
          de la FFME ne saurait &ecirc;tre engag&eacute;e du fait des contenus
          des documents, la Structure restant seul responsable de
          v&eacute;rifier la l&eacute;galit&eacute; et l&rsquo;ad&eacute;quation
          du contenu &agrave; ses besoins. &Agrave; ce titre,
          l&rsquo;utilisation par la Structure des mod&egrave;les de documents
          vaut validation du contenu dudit document.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME ne garantit en outre pas la Structure de
          l&rsquo;exhaustivit&eacute; de la base de mod&egrave;les de documents
          et actes types. Elle ne garantit &agrave; ce titre pas la Structure
          que l&rsquo;int&eacute;gralit&eacute; des courriers, documents et
          actes types dont il peut avoir besoin pour r&eacute;pondre &agrave;
          ses obligations figurent dans la base de documents qu&rsquo;elle met
          &agrave; sa disposition.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c11" ref="h.1baon6m">
          <span class="c10">6 &ndash; Remboursement</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1">// </span
        ><span class="c31">condition remboursement</span>
      </p>
      <p class="c28">
        <span class="c1">/*** </span
        ><span class="c30"
          >Les paiements r&eacute;alis&eacute;s par les Contributeurs sur le
          site myFFME ne donnent lieu &agrave; aucun remboursement. En
          particulier, les inscriptions propos&eacute;es sur le site dans le
          cadre des &eacute;v&egrave;nements g&eacute;r&eacute;s par votre
          Structure ne sont ni repris, ni &eacute;chang&eacute;s, ni
          rembours&eacute;s, m&ecirc;me en cas de perte ou de vol.</span
        ><span class="c1">&nbsp;***/</span>
      </p>
      <p class="c28">
        <span class="c1">/*** </span
        ><span class="c30"
          >La FFME agit en tant qu&rsquo;interm&eacute;diaire entre les
          Utilisateurs et votre Structure et ne prend pas en charge le
          traitement des demandes de remboursements ou des r&eacute;tractations
          qui seraient formul&eacute;es par les Utilisateurs. La
          responsabilit&eacute; de La FFME ne saurait donc &ecirc;tre
          engag&eacute;e &agrave; aucun titre sur ce point. Le remboursement du
          billet en cas d&rsquo;annulation de l&rsquo;&eacute;v&egrave;nement
          rel&egrave;ve uniquement d&rsquo;une d&eacute;cision de votre
          Structure</span
        ><span class="c1">. ***/</span>
      </p>
      <ol class="c2 lst-kix_list_1-0" start="40">
        <li class="c25" ref="h.3vac5uf">
          <span class="c12">Propri&eacute;t&eacute; intellectuelle FFME</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME et votre Structure restent respectivement
          propri&eacute;taires ou titulaires exclusifs de l&rsquo;ensemble des
          droits relatifs &agrave; leurs images, vid&eacute;os, textes, logos,
          logiciels, marques et tous &eacute;l&eacute;ments
          prot&eacute;g&eacute;s ou prot&eacute;geables respectifs. Aucune
          cession de droit ne r&eacute;sulte du pr&eacute;sent
          partenariat.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Votre Structure d&eacute;tient les droits n&eacute;cessaires &agrave;
          l&rsquo;ex&eacute;cution de l&rsquo;int&eacute;gralit&eacute; du
          pr&eacute;sent partenariat, ce qu&rsquo;elle garantit &agrave; la
          FFME. En particulier, votre Structure conc&egrave;de autant que de
          besoin, &agrave; titre gratuit, non exclusif, pour le monde entier et
          sur tous supports et r&eacute;seaux, l&rsquo;ensemble des droits
          n&eacute;cessaires pour les actions organis&eacute;es en vertu du
          pr&eacute;sent partenariat.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="41">
        <li class="c25" ref="h.2afmg28">
          <span class="c12">G&eacute;n&eacute;ralit&eacute;s</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c11" ref="h.pkwqa1">
          <span class="c10">1 - Responsabilit&eacute;</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME fournit seulement des outils de gestion des paiements de la
          Structure, c&#39;est donc un tiers ext&eacute;rieur &agrave; toute
          autre relation existant entre les Contributeurs et la Structure. La
          FFME n&#39;intervient en aucune mani&egrave;re dans les relations
          entre les Contributeurs et la Structure et en particulier dans les
          relations financi&egrave;res.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >En cons&eacute;quence, la FFME ne peut en aucun cas &ecirc;tre tenue
          responsable du comportement des Utilisateurs ou des Contributeurs
          ainsi que des diff&eacute;rends qui pourraient en r&eacute;sulter. La
          Structure a seul la responsabilit&eacute; de la bonne tenue des
          comptes, des d&eacute;cisions d&#39;utilisation et des utilisations
          des fonds collect&eacute;s, des compensations et des remboursements,
          ainsi que des relations avec les Utilisateurs. L&rsquo;utilisation des
          fonds par votre Structure ne peut en aucun cas engager la
          responsabilit&eacute; de la FFME vis &agrave; vis des utilisateurs ou
          des tiers. Votre Structure s&rsquo;engage &agrave; utiliser les fonds
          r&eacute;colt&eacute;s sur le site conform&eacute;ment aux
          informations pr&eacute;sent&eacute;es aux Utilisateurs et aux
          internautes.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME ne garantit pas l&#39;identit&eacute; des Utilisateurs, pas
          plus que la bonne ex&eacute;cution des utilisations de l&#39;argent
          collect&eacute; par la Structure. En cas de litige concernant
          l&#39;utilisation des fonds par la Structure, les Utilisateurs
          s&rsquo;adresseront directement &agrave; la Structure afin
          d&#39;essayer de r&eacute;soudre leur litige avec lui, la FFME ne
          pouvant intervenir &agrave; aucun titre dans cette relation.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME proc&egrave;de en mati&egrave;re de choix techniques selon
          une d&eacute;marche qualit&eacute; visant &agrave; la
          disponibilit&eacute; la plus grande et au meilleur fonctionnement
          possible de l&rsquo;espace en ligne personnalisable mis &agrave;
          disposition de votre Structure. Compte tenu de la nature m&ecirc;me
          des multiples technologies d&rsquo;Internet, l&rsquo;absence
          d&rsquo;erreur et la mise en ligne ininterrompue ne peuvent &ecirc;tre
          garantis.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME ne saurait en cons&eacute;quence &ecirc;tre tenu responsable
          pour tout dommage indirect qui serait caus&eacute; &agrave; votre
          Structure et notamment en cas :</span
        >
      </p>
      <ul class="c2 lst-kix_list_18-0 start">
        <li class="c5">
          <span class="c1"
            >de perte, d&rsquo;alt&eacute;ration ou de non-transmission de toute
            donn&eacute;e, du fait d&rsquo;une erreur mat&eacute;rielle,
            logicielle ou de t&eacute;l&eacute;communication. La Structure
            prendre soin, &agrave; cet effet, de sauvegarder l&rsquo;ensemble de
            ses donn&eacute;es et contenus afin de pouvoir les restaurer en cas
            de besoin ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >de d&eacute;faillance des moyens techniques, informatiques ou de
            t&eacute;l&eacute;communications mis &agrave; sa disposition par des
            tiers ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >d&rsquo;indisponibilit&eacute; des r&eacute;seaux
            &eacute;lectriques ou de t&eacute;l&eacute;communications affectant
            directement ou indirectement le Service ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >d&rsquo;actions de tiers impliquant votre Structure et sa
            communication dans le cadre du Service ;</span
          >
        </li>
        <li class="c5">
          <span class="c1"
            >de perte de revenus, de chance, de dons, de financements ou en cas
            d&rsquo;atteinte &agrave; l&rsquo;image de votre Structure sans
            faute directe de la FFME.
          </span>
        </li>
      </ul>
      <p class="c6">
        <span class="c1"
          >La FFME s&rsquo;efforcera de trouver avec ses partenaires et
          prestataires des solutions pour rem&eacute;dier aux &eacute;ventuelles
          d&eacute;faillances techniques ou indisponibilit&eacute;s du Service.
          Ces &eacute;v&egrave;nements ne pourront donner lieu &agrave;
          r&eacute;clamation &agrave; l&rsquo;encontre de cette
          derni&egrave;re.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c11" ref="h.39kk8xu">
          <span class="c10">2 - Donn&eacute;es personnelles</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La Structure d&eacute;clare avoir rempli l&rsquo;ensemble de ses
          obligations aupr&egrave;s de la CNIL au titre de la protection des
          donn&eacute;es &agrave; caract&egrave;re personnel. Il d&eacute;clare
          notamment respecter toutes les obligations l&eacute;gales et
          r&egrave;glementaires qui lui incombent, visant &agrave; garantir
          notamment la transparence, la s&eacute;curit&eacute; et la
          confidentialit&eacute; des donn&eacute;es qu&rsquo;il collecte et
          qu&rsquo;il traite dans le cadre des pr&eacute;sentes.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME vous informe qu&rsquo;elle respecte toutes les obligations
          l&eacute;gales et r&eacute;glementaires qui lui incombent, visant
          &agrave; garantir notamment la s&eacute;curit&eacute; et la
          confidentialit&eacute; des donn&eacute;es qu&rsquo;il collecte et
          qu&rsquo;il traite dans le cadre des pr&eacute;sentes, en application
          de la loi informatique et libert&eacute;s du 6 janvier 1978
          modifi&eacute;e, relativement aux traitements automatis&eacute;s de
          donn&eacute;es &agrave; caract&egrave;re nominatif et du
          R&egrave;glement europ&eacute;en sur la protection des donn&eacute;es
          n&deg;2016/679. La FFME n&rsquo;est en aucun cas responsable de tout
          traitement de donn&eacute;es &agrave; caract&egrave;re personnel
          r&eacute;alis&eacute; par un Utilisateur au titre des activit&eacute;s
          dudit Utilisateur r&eacute;alis&eacute;es par
          l&rsquo;interm&eacute;diation de la Plateforme, et auxquelles La FFME
          est strictement &eacute;trang&egrave;re. Il appartient &agrave; tout
          Utilisateur, le cas &eacute;ch&eacute;ant de respecter toutes les
          obligations r&eacute;glementaires qui lui incombent &agrave; ce
          titre.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME s&rsquo;engage &agrave; ne pas utiliser les donn&eacute;es
          personnelles des Contributeurs &agrave; des fins autres que la
          fourniture et la promotion des Services de la plateforme. En
          particulier, La FFME s&rsquo;engage &agrave; ne pas revendre la base
          de donn&eacute;es personnelles des Contributeurs &agrave;
          d&rsquo;autres Structures ou &agrave; des tiers. Les donn&eacute;es
          personnelles des Contributeurs, en particulier leurs adresses
          &eacute;lectroniques, sont seulement utilisables par votre
          Structure.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME pratique une politique de protection des donn&eacute;es
          personnelles dont les caract&eacute;ristiques sont explicit&eacute;es
          dans le document intitul&eacute; &quot;</span
        ><span class="c15"
          ><a
            class="c4"
            @click="scrollMeTo"
            data-href="https://www.google.com/url?q=https://www.montagne-escalade.com/site/BO/documents/rgpd.pdf&amp;sa=D&amp;ust=1604318818066000&amp;usg=AOvVaw1dFM1400wbtXbeInVM8cKv"
            >R&egrave;glement de protection des donn&eacute;es personnelles</a
          ></span
        ><span class="c1"
          >&quot;, accessible sur la page d&rsquo;accueil du Service et dont
          l&rsquo;Utilisateur est express&eacute;ment invit&eacute; &agrave;
          prendre connaissance.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME garantit la Structure que les Partenaires ont pris
          l&rsquo;engagement dans les m&ecirc;mes termes d&rsquo;utiliser leurs
          donn&eacute;es personnelles dans le respect strict des
          pr&eacute;sentes CGU et notamment des clauses de sous-traitance
          ci-apr&egrave;s. Dans ce cadre, la Structure autorise
          express&eacute;ment La FFME &agrave; communiquer les donn&eacute;es
          personnelles contenues dans les coordonn&eacute;es publiques de la
          Structure aux Partenaires, aux seules fins de la mise &agrave;
          disposition de ce dernier de la base de donn&eacute;es des Structures,
          &agrave; l&rsquo;exclusion de toute autre donn&eacute;e personnelle et
          de toute autre finalit&eacute;.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >La FFME n&rsquo;est en aucun cas responsable de tout traitement de
          donn&eacute;es &agrave; caract&egrave;re personnel
          r&eacute;alis&eacute; par une Structure et/ou tout Utilisateur au
          titre des activit&eacute;s et des op&eacute;rations
          r&eacute;alis&eacute;es par l&rsquo;interm&eacute;diation de La FFME,
          et auxquelles cette derni&egrave;re est strictement
          &eacute;trang&egrave;re. Il appartient &agrave; toute Structure et/ou
          Utilisateur, le cas &eacute;ch&eacute;ant de respecter toutes les
          obligations r&eacute;glementaires qui lui incombent &agrave; ce
          titre.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >En outre, la Structure a la possibilit&eacute;, dans le cadre de son
          acc&egrave;s aux Services, de demander et collecter aupr&egrave;s des
          Contributeurs, en son nom et pour son compte, de sa seule initiative,
          toutes donn&eacute;es personnelles compl&eacute;mentaires qu&rsquo;il
          estime n&eacute;cessaires (ci-apr&egrave;s les &laquo; Donn&eacute;es
          de la Structure &raquo;). A ce titre, il reconna&icirc;t et accepte
          qu&rsquo;il confie &agrave; La FFME, en qualit&eacute; de
          sous-traitant, les op&eacute;rations de conservation des
          Donn&eacute;es de la Structure collect&eacute;es par
          l&rsquo;interm&eacute;diaire du Service. La FFME s&rsquo;engage quant
          &agrave; elle express&eacute;ment &agrave; effectuer lesdites
          op&eacute;rations pour le compte de la Structure.</span
        >
      </p>
      <p class="c6">
        <span class="c1"
          >Dans le cadre de leurs relations, les Parties s&rsquo;engagent
          &agrave; respecter la r&eacute;glementation en vigueur applicable au
          traitement de donn&eacute;es &agrave; caract&egrave;re personnel et
          notamment au R&egrave;glement europ&eacute;en sur la protection des
          donn&eacute;es n&deg;2016/679 du 27 avril 2016.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c11" ref="h.1opuj5n">
          <span class="c10">3 - Confidentialit&eacute;</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Pour autant que votre Structure ait acc&egrave;s &agrave; des
          informations qui n&rsquo;auraient pas &eacute;t&eacute; rendues
          publiques ou dont la nature m&ecirc;me indique le caract&egrave;re
          confidentiel de la divulgation effectu&eacute;e &agrave; votre
          b&eacute;n&eacute;fice, votre Structure et ses Membres
          s&rsquo;engagent &agrave; observer la plus stricte
          confidentialit&eacute; et &agrave; ne pas divulguer &agrave; des tiers
          de telles informations.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c11" ref="h.48pi1tg">
          <span class="c10">4 - Dur&eacute;e</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Le partenariat entre votre Structure et la FFME prend effet
          imm&eacute;diatement &agrave; compter de la premi&egrave;re
          utilisation du service. Il est conclu pour la dur&eacute;e
          n&eacute;cessaire &agrave; la r&eacute;alisation du partenariat. Votre
          Structure pourra demander &agrave; tout moment la r&eacute;siliation
          du partenariat par l&rsquo;envoi d&rsquo;un simple courriel &agrave;
          l&rsquo;adresse info@ffme.fr. La r&eacute;siliation sera effective
          sous 15 jours environ.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c11" ref="h.2nusc19">
          <span class="c10">5 - Suspension</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME pourra proc&eacute;der &agrave; la suspension
          imm&eacute;diate du pr&eacute;sent partenariat et du compte
          cr&eacute;&eacute; sur le site myFFME, de plein droit et sans
          pr&eacute;avis :</span
        >
      </p>
      <ul class="c2 lst-kix_list_19-0 start">
        <li class="c6 c23">
          <span class="c1"
            >en cas de non-respect des pr&eacute;sentes conditions
            g&eacute;n&eacute;rales et du r&egrave;glement int&eacute;rieur de
            la FFME ;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >en cas de non-respect des conditions g&eacute;n&eacute;rales
            d&rsquo;utilisation propos&eacute;es par le syst&egrave;me de
            paiement ;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >en cas de blocage du compte de paiement par la
            soci&eacute;t&eacute; Lemonway ;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >si votre Structure a fourni des donn&eacute;es d&#39;identification
            inexactes, p&eacute;rim&eacute;es ou incompl&egrave;tes ;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >en cas de risque de fraude, de blanchiment de capitaux ou
            financement du terrorisme ou de risque pouvant affecter la
            s&eacute;curit&eacute; du Compte de paiement ou le fonctionnement du
            site myFFME ;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >en cas de r&eacute;ception par l&rsquo;&eacute;tablissement
            g&eacute;rant le syst&egrave;me de paiement d&rsquo;un nombre
            important de demandes de remboursement, d&rsquo;annulation
            d&rsquo;ordres ou de contestation pour ordres non autoris&eacute;s
            ;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >lorsque des Contenus ont fait l&rsquo;objet de signalisations par
            les Internautes ;
          </span>
        </li>
      </ul>
      <p class="c6">
        <span class="c1"
          >Tout paiement &agrave; votre Structure sera &eacute;galement
          imm&eacute;diatement suspendu.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="6">
        <li class="c11" ref="h.1302m92">
          <span class="c10">6 - R&eacute;siliation</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME pourra r&eacute;silier de plein droit le pr&eacute;sent
          Accord :</span
        >
      </p>
      <ul class="c2 lst-kix_list_20-0 start">
        <li class="c6 c23">
          <span class="c1"
            >&agrave; l&rsquo;issue d&rsquo;une suspension d&eacute;cid&eacute;e
            conform&eacute;ment aux dispositions de l&rsquo;article 5 ;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >ind&eacute;pendamment de toute suspension pr&eacute;alable, en cas
            de manquement grave de votre Structure aux dispositions des
            pr&eacute;sentes conditions g&eacute;n&eacute;rales
            d&rsquo;utilisation&nbsp;;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >en raison d&rsquo;une r&eacute;siliation de la convention conclue
            avec la soci&eacute;t&eacute; Lemonway&nbsp;;</span
          >
        </li>
        <li class="c6 c23">
          <span class="c1"
            >en cas de manquement aux autres engagements pris par votre
            Structure au titre des pr&eacute;sentes conditions
            g&eacute;n&eacute;rales qui ne serait pas r&eacute;par&eacute; dans
            un d&eacute;lai de 30 jours calendaires &agrave; compter du
            lendemain de la date d&rsquo;envoi en recommand&eacute; ou par
            courriel de la notification d&rsquo;un tel manquement.
          </span>
        </li>
      </ul>
      <p class="c6">
        <span class="c1"
          >En cas de r&eacute;siliation, les contributeurs sont
          int&eacute;gralement rembours&eacute;s des sommes qu&rsquo;ils ont
          vers&eacute;es.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="7">
        <li class="c11" ref="h.3mzq4wv">
          <span class="c10">7 - Droit applicable</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Les pr&eacute;sentes CGU sont r&eacute;gies,
          interpr&eacute;t&eacute;es et appliqu&eacute;es conform&eacute;ment au
          droit fran&ccedil;ais.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="8">
        <li class="c11" ref="h.2250f4o">
          <span class="c10">8 - M&eacute;diation - Juridiction</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME et votre Structure conviennent de soumettre tout
          diff&eacute;rend qui surviendrait entre eux &agrave; une
          proc&eacute;dure de m&eacute;diation au cours de laquelle une solution
          amiable sera recherch&eacute;e, avant toute action en justice, sauf
          urgence d&ucirc;ment constat&eacute;e et recours en cons&eacute;quence
          &agrave; une proc&eacute;dure de r&eacute;f&eacute;r&eacute;
          d&eacute;clar&eacute;e recevable.</span
        >
      </p>
      <p class="c6" ref="h.haapch">
        <span class="c1"
          >A d&eacute;faut de solution trouv&eacute;e dans le cadre d&rsquo;un
          processus de m&eacute;diation, dans un d&eacute;lai de 5 mois &agrave;
          compter de la d&eacute;signation d&rsquo;un m&eacute;diateur, et sous
          r&eacute;serve des dispositions d&#39;ordre public applicables en
          mati&egrave;re de comp&eacute;tence juridictionnelle, les tribunaux du
          ressort de la Cour d&rsquo;Appel de Paris seront seuls
          comp&eacute;tents pour conna&icirc;tre de tout litige relatif aux
          pr&eacute;sentes, concernant tant leur validit&eacute;,
          interpr&eacute;tation, ex&eacute;cution, r&eacute;siliation, leurs
          cons&eacute;quences et leurs suites et qui n&rsquo;aurait pas
          &eacute;t&eacute; r&eacute;solu &agrave; l&rsquo;amiable.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="9">
        <li class="c11" ref="h.319y80a">
          <span class="c10">9 - Domiciliation</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Votre Structure &eacute;lit domicile &agrave; l&rsquo;adresse de son
          si&egrave;ge telle qu&rsquo;elle a &eacute;t&eacute;
          communiqu&eacute;e lors de la proc&eacute;dure d&rsquo;inscription en
          ligne. La FFME est domicili&eacute;e &agrave; son si&egrave;ge,
          rappel&eacute;e en t&ecirc;te des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales de partenariat.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="10">
        <li class="c11" ref="h.1gf8i83">
          <span class="c10">10 - Nullit&eacute;</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Si l&rsquo;une des clauses des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales &eacute;tait d&eacute;clar&eacute;e nulle, la
          validit&eacute; du Partenariat n&rsquo;en serait pas affect&eacute;e.
          La nullit&eacute;, l&#39;illic&eacute;it&eacute;,
          l&#39;inapplicabilit&eacute; ou l&#39;inopposabilit&eacute; d&#39;une
          clause quelconque des pr&eacute;sentes CGU ne saurait entra&icirc;ner
          la nullit&eacute;, l&#39;illic&eacute;it&eacute;,
          l&#39;inapplicabilit&eacute; ou l&#39;inopposabilit&eacute; des autres
          clauses. Les parties conviennent qu&rsquo;elles adopteront une clause
          valable qui produira les effets &eacute;conomiques les plus proches de
          la clause invalid&eacute;e. Il est express&eacute;ment convenu que
          dans l&rsquo;interpr&eacute;tation qui pourrait &ecirc;tre faite des
          clauses des pr&eacute;sentes conditions g&eacute;n&eacute;rales, il
          sera tenu compte de l&rsquo;&eacute;quilibre contractuel explicitement
          convenu et des objectifs poursuivis.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="11">
        <li class="c11" ref="h.40ew0vw">
          <span class="c10">11 - Tol&eacute;rance</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Toute tol&eacute;rance dans l&rsquo;exercice d&rsquo;un droit par
          l&rsquo;une des parties ne pourra emporter novation ou emp&ecirc;cher
          l&rsquo;exercice ult&eacute;rieur de ce droit &agrave; tout moment
          pendant la dur&eacute;e des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="12">
        <li class="c11" ref="h.2fk6b3p">
          <span class="c10">12 - Notifications et Communications</span>
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >Pour &ecirc;tre r&eacute;put&eacute;es valablement effectu&eacute;es,
          toutes notifications, r&eacute;clamations, mises en demeure et autres
          communications devront &ecirc;tre adress&eacute;es par voie de lettre
          recommand&eacute;e avec demande d&#39;avis de r&eacute;ception, et
          elles seront r&eacute;put&eacute;es avoir &eacute;t&eacute;
          re&ccedil;ues le jour suivant la date du tampon de la poste figurant
          sur l&rsquo;avis de r&eacute;ception, sauf mention contraire expresse
          et sp&eacute;cifique dans les dispositions du pr&eacute;sent
          Accord.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="42">
        <li class="c25" ref="h.upglbi">
          <span class="c12"
            >Modification des Conditions G&eacute;n&eacute;rales
            d&#39;Utilisation</span
          >
        </li>
      </ol>
      <p class="c6">
        <span class="c1"
          >La FFME est en droit de modifier les pr&eacute;sentes conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation &agrave; tout moment, sous
          r&eacute;serve d&#39;en informer les Utilisateurs&nbsp;:</span
        >
      </p>
      <ul class="c2 lst-kix_list_21-0 start">
        <li class="c6 c21">
          <span class="c1"
            >par simple affichage sur le site internet ou par courriel au plus
            tard &agrave; la date de publication sur le site myFFME dans
            l&rsquo;hypoth&egrave;se o&ugrave; les modifications
            apport&eacute;es ne portent pas atteinte aux droits reconnus
            &agrave; l&rsquo;utilisateur au titre des pr&eacute;sentes ou ne lui
            cr&eacute;ent pas de nouvelles obligations &agrave; sa
            charge&nbsp;;</span
          >
        </li>
        <li class="c6 c21">
          <span class="c1"
            >par courriel ou toute autre forme de communication au moins 15
            jours avant l&rsquo;entr&eacute;e en vigueur des nouvelles CGU dans
            les autres cas.
          </span>
        </li>
      </ul>
      <p class="c6">
        <span class="c1"
          >Toute modification entrera alors en vigueur &agrave; la date de sa
          publication par la FFME sur le site myFFME. L&rsquo;Utilisateur qui
          n&rsquo;accepte pas les CGU modifi&eacute;es doit le notifier &agrave;
          la FFME en adressant un courriel &agrave; l&rsquo;adresse
          info@ffme.fr, avant l&rsquo;entr&eacute;e en vigueur desdites CGU
          modifi&eacute;es et au plus tard la veille de l&rsquo;entr&eacute;e en
          vigueur, pour demander la d&eacute;sactivation de son Compte. Une
          utilisation continue de la part des Utilisateurs du site myFFME
          apr&egrave;s l&rsquo;entr&eacute;e en vigueur des nouvelles CGU vaut
          consentement &agrave; cette nouvelle version qui lui sera pleinement
          applicable.</span
        >
      </p>
      <p class="c26">
        <span class="c24"
          >Annexe 1 - Conditions g&eacute;n&eacute;rales d&rsquo;utilisation du
          service de paiement Lemon Way</span
        >
      </p>
      <p class="c19 c26"><span class="c24"></span></p>
      <p class="c0"><span class="c7">Version 2020</span></p>
      <p class="c0 c19"><span class="c7"></span></p>
      <p class="c0"><span class="c1">Le Titulaire d&rsquo;une part</span></p>
      <p class="c0"><span class="c1">et,</span></p>
      <p class="c0">
        <span class="c1"
          >Lemonway, soci&eacute;t&eacute; par actions simplifi&eacute;e dont le
          si&egrave;ge social est situ&eacute; au 8 rue du Sentier, 75002 Paris,
          France, immatricul&eacute;e au registre du commerce et des
          soci&eacute;t&eacute;s de Paris sous le num&eacute;ro 500 486 915
          (&laquo; Lemonway&laquo; ), agr&eacute;&eacute;e le 24 d&eacute;cembre
          2012 par l&rsquo;Autorit&eacute; de Contr&ocirc;le Prudentiel et de
          R&eacute;solution (&laquo; ACPR &raquo;, France, site internet
          http://acpr.banque-france.fr/), 4 place de Budapest CS 92459, 75436
          Paris, en qualit&eacute; d&rsquo;Etablissement de Paiement hybride,
          sous le num&eacute;ro 16 568 J, d&rsquo;autre part.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >D&eacute;sign&eacute;s individuellement une &laquo; Partie &raquo; et
          ensemble les &laquo; Parties &raquo;.</span
        >
      </p>
      <p class="c0 c19"><span class="c1"></span></p>
      <ol class="c2 lst-kix_list_1-0" start="43">
        <li class="c20" ref="h.3ep43zb">
          <span class="c12">AVERTISSEMENT</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut &agrave; tout moment consulter ce Contrat-cadre, le
          reproduire, le stocker sur son ordinateur ou sur un autre support, le
          transf&eacute;rer par courrier &eacute;lectronique ou l&rsquo;imprimer
          sur papier de mani&egrave;re &agrave; le conserver.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >A tout instant, conform&eacute;ment &agrave; la loi, il est possible
          de v&eacute;rifier l&rsquo;agr&eacute;ment de Lemonway sur le site
          regafi.fr, en qualit&eacute; d&rsquo;&eacute;tablissement de paiement.
          Le Site Internet de l&rsquo;&eacute;tablissement de paiement Lemonway
          est le suivant : www.lemonway.com</span
        >
      </p>
      <p class="c29"><span class="c12">Table des mati&egrave;res</span></p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.ihv636"
            >AVERTISSEMENT</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.ihv636"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1</a
          ></span
        >
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.32hioqz"
            >1. OBJET</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.32hioqz"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1hmsyys"
            >2. D&Eacute;FINITIONS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1hmsyys"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.41mghml"
            >3. OUVERTURE DE COMPTE DE PAIEMENT</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.41mghml"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2grqrue"
            >4. CR&Eacute;DITER LE COMPTE DE PAIEMENT</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2grqrue"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.vx1227"
            >5. D&Eacute;BITER UN COMPTE DE PAIEMENT PAR VIREMENT</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.vx1227"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3fwokq0"
            >6. REPORTING</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3fwokq0"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1v1yuxt"
            >7. DUR&Eacute;E DU CONTRAT-CADRE ET DATE D&rsquo;EFFET</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1v1yuxt"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4f1mdlm"
            >8. R&Eacute;CLAMATIONS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4f1mdlm"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2u6wntf"
            >9. FRAIS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2u6wntf"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.19c6y18"
            >10. S&Eacute;CURIT&Eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.19c6y18"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3tbugp1"
            >11. RESPONSABILIT&Eacute;S</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3tbugp1"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.28h4qwu"
            >12. PROTECTION DES FONDS CLIENTS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.28h4qwu"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.nmf14n"
            >13. D&Eacute;C&Egrave;S &ndash; COMPTE DE PAIEMENT INACTIF &ndash;
            PROCURATION</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.nmf14n"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.37m2jsg"
            >14. PROPRI&Eacute;T&Eacute; INTELLECTUELLE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.37m2jsg"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1mrcu09"
            >15. CONFIDENTIALIT&Eacute;</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.1mrcu09"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.46r0co2"
            >16. COLLECTE ET TRAITEMENT DES DONNEES A CARACTERE PERSONNEL</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.46r0co2"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2lwamvv"
            >17. CONVENTION DE PREUVE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2lwamvv"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.111kx3o"
            >18. BLOCAGE ET GEL DU COMPTE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.111kx3o"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3l18frh"
            >19. R&Eacute;SILIATION DU CONTRAT-CADRE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.3l18frh"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.206ipza"
            >20. MODIFICATION DU CONTRAT-CADRE</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.206ipza"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4k668n3"
            >21. GENERALITES</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.4k668n3"></a
        ></span>
      </p>
      <p class="c17">
        <span class="c15"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2zbgiuw"
            >22. DROIT APPLICABLE ET JURIDICTIONS</a
          ></span
        ><span class="c1"
          ><a class="c4" @click="scrollMeTo" data-href="#h.2zbgiuw"></a
        ></span>
      </p>
      <p class="c0 c19"><span class="c3"></span></p>
      <ol class="c2 lst-kix_list_1-0" start="44">
        <li class="c20" ref="h.1tuee74"><span class="c12">1. OBJET</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le pr&eacute;sent &laquo; Contrat-cadre de Services de paiement
          &raquo; est consultable &agrave; tout moment sur le Site Internet
          (https://www.lemonway.com). Il r&eacute;git les termes et conditions
          de l&rsquo;ouverture d&rsquo;un Compte de paiement par Lemonway au nom
          du Titulaire et la fourniture de Services de paiement. Le Titulaire
          est invit&eacute; &agrave; les lire avec attention avant de les
          accepter.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="45">
        <li class="c20" ref="h.4du1wux">
          <span class="c12">2. D&Eacute;FINITIONS</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les termes utilis&eacute;s dans le pr&eacute;sent Contrat-cadre
          auront, lorsqu&rsquo;ils sont utilis&eacute;s avec la premi&egrave;re
          lettre en majuscule et ind&eacute;pendamment du fait qu&rsquo;ils
          soient utilis&eacute;s au singulier ou au pluriel, le sens
          d&eacute;fini ci-apr&egrave;s :</span
        >
      </p>
      <ul class="c2 lst-kix_list_7-0">
        <li class="c0 c18">
          <span class="c1"
            >B&eacute;n&eacute;ficiaire : personne physique ou morale
            d&eacute;sign&eacute;e par le Titulaire comme destinataire
            d&rsquo;un virement provenant de son Compte de paiement. Le
            B&eacute;n&eacute;ficiaire peut &ecirc;tre le Titulaire.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Contrat-cadre : Contrat-cadre de Services de paiement tel que
            d&eacute;fini &agrave; l&rsquo;article L. 314-12 du Code
            mon&eacute;taire et financier conclu entre Lemonway et le Titulaire
            compos&eacute; des pr&eacute;sentes CGU.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Compte de paiement : Compte de paiement au sens de l&rsquo;article
            L. 314-1 du Code mon&eacute;taire et financier ouvert dans les
            livres de Lemonway &agrave; l&rsquo;effet d&rsquo;inscrire au
            d&eacute;bit et au cr&eacute;dit les Op&eacute;rations de paiement,
            les frais dus par le Titulaire et toute contrepassation en lien avec
            ses Op&eacute;rations et de compenser ces montants &agrave; la date
            de leur inscription aux fins de faire appara&icirc;tre un solde
            net.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Jour Ouvr&eacute; : un jour autre qu&rsquo;un samedi, un dimanche
            ou un jour f&eacute;ri&eacute; en France.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Op&eacute;rations de paiement : action consistant &agrave; verser,
            transf&eacute;rer ou retirer des fonds &agrave; partir ou &agrave;
            destination du Compte de paiement, ind&eacute;pendamment de toute
            obligation sous-jacente entre le Payeur et le
            B&eacute;n&eacute;ficiaire.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Ordre de paiement : consentement du Titulaire donn&eacute; suivant
            le dispositif personnalis&eacute; et les proc&eacute;dures convenus
            entre le Titulaire et Lemonway, afin d&rsquo;autoriser une
            Op&eacute;ration de paiement.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Partenaire : soci&eacute;t&eacute; commerciale exploitant le Site
            Partenaire et utilisant les services de Lemonway.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Payeur : Personne physique ou morale pouvant &ecirc;tre le
            Titulaire en cas d&rsquo;alimentation de son propre Compte de
            paiement ou utilisatrice du Site Partenaire.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Provision : montant disponible inscrit au cr&eacute;dit du Compte
            de paiement pouvant &ecirc;tre affect&eacute; &agrave;
            l&rsquo;ex&eacute;cution d&rsquo;Op&eacute;rations de paiement
            futures, d&eacute;termin&eacute; par Lemonway apr&egrave;s prise en
            compte des Op&eacute;rations de paiement en cours et du montant de
            la Provision bloqu&eacute;e d&eacute;finie &agrave; l&rsquo;article
            5.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Prestataire de Services de paiement (PSP) tiers : prestataire de
            Services de paiement (&eacute;tablissement financier)
            agr&eacute;&eacute; par une autorit&eacute; d&rsquo;un Etat partie
            &agrave; l&rsquo;Espace &Eacute;conomique Europ&eacute;en ayant
            ouvert un compte bancaire ou de paiement au nom du Titulaire.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Services de paiement : services fournis par Lemonway en application
            du Contrat-cadre et comprenant l&rsquo;ex&eacute;cution de virements
            et l&rsquo;acquisition d&rsquo;ordres de paiement par cartes et par
            virements, ainsi que l&rsquo;encaissement de ch&egrave;ques.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Site Internet : D&eacute;signe le site internet
            http://www.lemonway.com &agrave; partir duquel Lemonway propose les
            Services de paiement.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Site Partenaire ou Site : D&eacute;signe le site et/ou
            l&rsquo;application exploit&eacute; par le Partenaire en vue de
            permettre &agrave; des personnes de r&eacute;aliser des
            Op&eacute;rations de paiement.</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Titulaire : Personne physique ou morale disposant d&rsquo;un Compte
            de paiement permettant d&rsquo;effectuer et/ou recevoir une
            Op&eacute;ration de paiement.</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-0" start="46">
        <li class="c20" ref="h.2szc72q">
          <span class="c12">3. OUVERTURE DE COMPTE DE PAIEMENT</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire doit satisfaire la proc&eacute;dure d&rsquo;ouverture de
          Compte de paiement ci-apr&egrave;s d&eacute;crite.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="13">
        <li class="c9">
          <span class="c10"
            >3.1 D&eacute;clarations pr&eacute;alables du Titulaire</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire, personne morale ou physique majeure capable,
          d&eacute;clare express&eacute;ment avoir la capacit&eacute; et/ou
          avoir re&ccedil;u les autorisations requises pour utiliser les
          Services de paiement fournis par Lemonway et garantit Lemonway contre
          toute responsabilit&eacute; pouvant r&eacute;sulter pour elle
          d&rsquo;une fausse d&eacute;claration.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire d&eacute;clare agir pour son compte. Le Titulaire a
          l&rsquo;obligation d&rsquo;utiliser les services fournis par Lemonway
          de bonne foi, &agrave; des fins licites et dans le respect des
          dispositions du Contrat-cadre.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire personne physique d&eacute;clare &ecirc;tre
          r&eacute;sident en France ou dans l&rsquo;Espace &Eacute;conomique
          Europ&eacute;en.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Pour tout autre pays de r&eacute;sidence ou d&rsquo;immatriculation,
          Lemonway se garde la possibilit&eacute; d&rsquo;&eacute;tudier la
          demande d&rsquo;ouverture d&rsquo;un Compte de paiement afin de se
          conformer au p&eacute;rim&egrave;tre g&eacute;ographique de son
          agr&eacute;ment.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="14">
        <li class="c9">
          <span class="c10"
            >3.2 Transmission des documents d&rsquo;identification</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway informe le Titulaire que conform&eacute;ment aux obligations
          d&rsquo;identification relatives &agrave; la lutte contre le
          blanchiment de capitaux et de financement du terrorisme,
          l&rsquo;ouverture du Compte de paiement est conditionn&eacute;e
          &agrave; la transmission et validation des documents
          d&rsquo;identification requis en fonction de la qualit&eacute; du
          Titulaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway se r&eacute;serve le droit de demander tout autre document
          ou information compl&eacute;mentaire, pour lui permettre
          d&rsquo;effectuer les v&eacute;rifications utiles au respect de ses
          obligations l&eacute;gales y compris en mati&egrave;re de lutte contre
          le blanchiment de capitaux.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire accepte que le Site Partenaire fasse parvenir ces
          documents &agrave; Lemonway par transmission informatique et
          t&eacute;l&eacute;versement sur les syst&egrave;mes informatiques de
          Lemonway.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="15">
        <li class="c9">
          <span class="c10"
            >3.3 Modalit&eacute;s d&rsquo;approbation du Contrat-cadre</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Contrat-cadre doit &ecirc;tre approuv&eacute; par le Titulaire par
          tout moyen. Ce dernier reconna&icirc;t avoir attentivement lu, compris
          et accept&eacute; le Contrat-cadre dans son
          int&eacute;gralit&eacute;.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="16">
        <li class="c9">
          <span class="c10"
            >3.4 Acceptation d&rsquo;ouverture de Compte de paiement</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway peut refuser d&rsquo;ouvrir un Compte de paiement pour tout
          motif sans avoir &agrave; justifier sa d&eacute;cision. Celle-ci ne
          donnera lieu &agrave; aucun dommage et int&eacute;r&ecirc;t.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Sous r&eacute;serve de l&rsquo;autorisation expresse et &eacute;crite
          de son repr&eacute;sentant l&eacute;gal, le mineur de moins de
          dix-huit (18) ans peut ouvrir un Compte de paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >A cet effet, les documents d&rsquo;identification requis seront ceux
          du repr&eacute;sentant l&eacute;gal ayant donn&eacute; son
          autorisation expresse et &eacute;crite, du mineur ainsi qu&rsquo;un
          document attestant du pouvoir (livret de famille, d&eacute;cision de
          justice conf&eacute;rant le pouvoir de repr&eacute;sentation).</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Site Partenaire pourra faire parvenir l&rsquo;acceptation ou le
          refus de l&rsquo;ouverture de son Compte de paiement au Titulaire par
          envoi d&rsquo;un Email. Le Titulaire peut &agrave; compter de
          l&rsquo;acceptation s&rsquo;identifier sur le Site Partenaire pour
          constater que son Compte de paiement est ouvert.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="17">
        <li class="c9">
          <span class="c10"
            >3.5 R&eacute;gime sp&eacute;cifique de la client&egrave;le
            occasionnelle</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le client occasionnel est le client &laquo; de passage &raquo; qui
          sollicite l&rsquo;intervention de Lemonway pour la r&eacute;alisation
          d&rsquo;une Op&eacute;ration de paiement isol&eacute;e ou de plusieurs
          Op&eacute;rations de paiement pr&eacute;sentant un lien entre elles
          dans la limite des plafonds applicables par Lemonway. Les
          Op&eacute;rations effectu&eacute;es par un client occasionnel ne
          feront pas l&rsquo;objet de l&rsquo;ouverture d&rsquo;un Compte de
          paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway rappelle que le r&eacute;gime sp&eacute;cifique de la
          client&egrave;le occasionnelle est uniquement applicable &agrave; une
          certaine typologie de client&egrave;le et
          d&rsquo;activit&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >L&rsquo;acceptation du pr&eacute;sent Contrat-cadre ne constitue pas
          une entr&eacute;e en relation d&rsquo;affaires et l&rsquo;ouverture
          d&rsquo;un Compte de paiement dans le cas o&ugrave; le r&eacute;gime
          sp&eacute;cifique de la client&egrave;le occasionnelle est
          applicable.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway rappelle qu&rsquo;en cas de d&eacute;passement des plafonds
          d&rsquo;Op&eacute;rations de paiement applicables, l&rsquo;ouverture
          d&rsquo;un Compte de paiement sera obligatoire pour effectuer de
          nouvelles Op&eacute;rations de paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="47">
        <li class="c20" ref="h.184mhaj">
          <span class="c12">4. CR&Eacute;DITER LE COMPTE DE PAIEMENT</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">4.1 Alimentation du Compte de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut proc&eacute;der &agrave; l&rsquo;alimentation de
          son Compte de paiement par les moyens de paiement mis &agrave;
          disposition par le Partenaire de Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas d&rsquo;alimentation du Compte de paiement par ch&egrave;que
          (fran&ccedil;ais uniquement), ces derniers devront &ecirc;tre
          endoss&eacute;s &agrave; l&rsquo;ordre de Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway peut refuser ou annuler l&rsquo;enregistrement du moyen de
          paiement utilis&eacute; par le Titulaire par mesure de
          s&eacute;curit&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway param&egrave;tre des plafonds de paiement dans
          l&rsquo;int&eacute;r&ecirc;t de la protection du Titulaire et le
          respect de la r&eacute;glementation en vigueur en mati&egrave;re de
          Services de paiement. Des plafonds uniques, par jour, par mois et par
          an, ainsi que toutes formes de restrictions (notamment portant sur
          l&rsquo;authentification forte du Titulaire) sont appliqu&eacute;es
          par Lemonway pour lutter contre la fraude.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire est inform&eacute; que toute Op&eacute;ration de
          paiement risquant d&rsquo;entra&icirc;ner un d&eacute;passement des
          plafonds applicables sera automatiquement rejet&eacute;e par
          Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Toute Op&eacute;ration par carte bancaire ou de paiement, qui ferait
          l&rsquo;objet d&rsquo;un impay&eacute;, d&rsquo;un rejet ou
          d&rsquo;une opposition verra son montant automatiquement d&eacute;duit
          par Lemonway du solde net du Compte de paiement. Si le solde net est
          insuffisant, Lemonway est autoris&eacute;e &agrave; utiliser toutes
          les voies de recours contre le Titulaire en vue de recouvrer le
          montant d&ucirc;. En outre, Lemonway sera en droit de refuser
          l&rsquo;ex&eacute;cution de toutes les futures remises de fonds
          effectu&eacute;es au moyen de la carte ayant donn&eacute; lieu
          &agrave; l&rsquo;incident.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway r&eacute;percute, en outre, au d&eacute;bit du Compte de
          paiement du Titulaire, les Op&eacute;rations de paiement
          rejet&eacute;es et autres amendes qui peuvent &ecirc;tre
          prononc&eacute;es par les r&eacute;seaux d&rsquo;acceptation.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9">
          <span class="c10"
            >4.2 D&eacute;lais d&rsquo;inscription des fonds sur le Compte</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway inscrira les fonds r&eacute;sultant de l&rsquo;acquisition
          d&rsquo;une Op&eacute;ration de paiement par carte ou par virement
          dans les plus brefs d&eacute;lais et au plus tard &agrave; la fin du
          Jour Ouvr&eacute; au cours duquel ils ont &eacute;t&eacute;
          re&ccedil;us par Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les d&eacute;lais d&rsquo;inscription des fonds sur le Compte peuvent
          &ecirc;tre plus longs en cas d&rsquo;utilisation d&rsquo;un autre
          moyen de paiement mis &agrave; disposition par Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Pour des raisons de s&eacute;curit&eacute;, les d&eacute;lais
          d&rsquo;inscription peuvent &ecirc;tre plus longs en cas de suspicion
          de fraude dans l&rsquo;attente de fourniture d&rsquo;informations
          compl&eacute;mentaires par le Titulaire ou de tout autre tiers
          concern&eacute;.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="48">
        <li class="c20" ref="h.3s49zyc">
          <span class="c12"
            >5. D&Eacute;BITER UN COMPTE DE PAIEMENT PAR VIREMENT</span
          >
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">5.1 Initier un Ordre de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway fournit un service de paiement permettant aux Titulaires
          disposant d&rsquo;un Compte de paiement de donner instruction &agrave;
          Lemonway, d&rsquo;ex&eacute;cuter un virement sous r&eacute;serve que
          la Provision du Compte soit sup&eacute;rieure au montant total du
          virement (frais inclus). En cas de Provision insuffisante,
          l&rsquo;Ordre de paiement sera automatiquement refus&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La Provision disponible correspond au solde net du Compte de paiement
          exclusion faite de la Provision bloqu&eacute;e et des
          Op&eacute;rations en cours. Le montant de la Provision bloqu&eacute;e
          est d&eacute;termin&eacute; par Lemonway en vue de couvrir les
          &eacute;ventuelles contrepassations provenant de la contestation
          d&rsquo;un Ordre de paiement. Une telle contestation peut intervenir
          dans un d&eacute;lai de treize (13) mois &agrave; compter du
          d&eacute;bit en Compte de l&rsquo;Op&eacute;ration.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >L&rsquo;Ordre de paiement pourra &ecirc;tre fait &agrave;
          l&rsquo;attention soit d&rsquo;un autre Compte de paiement ouvert dans
          les livres de Lemonway, soit d&rsquo;un compte bancaire au nom du
          Titulaire ouvert dans les livres d&rsquo;un PSP tiers
          autoris&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >L&rsquo;Ordre de paiement devra comporter les informations suivantes
          :</span
        >
      </p>
      <ul class="c2 lst-kix_list_8-0">
        <li class="c0 c18">
          <span class="c1">le montant en euros ou en devises ;</span>
        </li>
        <li class="c0 c18">
          <span class="c1"
            >le nom et pr&eacute;nom du B&eacute;n&eacute;ficiaire ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >le num&eacute;ro de son compte bancaire ouvert dans les livres du
            PSP tiers.</span
          >
        </li>
      </ul>
      <p class="c0">
        <span class="c1"
          >Le Titulaire reconna&icirc;t que si la devise du Compte de paiement
          diff&egrave;re de celle du compte B&eacute;n&eacute;ficiaire vers
          lequel va s&rsquo;effectuer le virement des fonds, des frais de change
          seront imput&eacute;s par le PSP du B&eacute;n&eacute;ficiaire. Il
          appartient au Partenaire de Lemonway et au PSP du
          B&eacute;n&eacute;ficiaire d&rsquo;informer le
          B&eacute;n&eacute;ficiaire avant toute acquisition d&rsquo;un ordre de
          virement, du taux de change pratiqu&eacute;, des frais et des
          d&eacute;lais d&rsquo;ex&eacute;cution. Cette information devra
          &ecirc;tre transmise au Titulaire Payeur par le Partenaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway ne peut &ecirc;tre tenu pour responsable si les
          coordonn&eacute;es bancaires transmises pour les demandes de virement
          sont erron&eacute;es ou non mises &agrave; jour.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9">
          <span class="c10"
            >5.2 Irr&eacute;vocabilit&eacute; d&rsquo;un Ordre de paiement</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >L&rsquo;Ordre de paiement valablement donn&eacute; par un Titulaire
          est irr&eacute;vocable pour le Titulaire &agrave; compter de la saisie
          d&rsquo;un code &agrave; usage unique conform&eacute;ment &agrave;
          l&rsquo;article 5.1. Le Titulaire ne pourra donc pas en demander
          l&rsquo;annulation.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway pourra cependant bloquer tout Ordre de paiement en cas de
          suspicion de fraude ou de violation des r&egrave;gles applicables en
          mati&egrave;re de lutte contre le blanchiment et le financement du
          terrorisme (LCB-FT).</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les Ordres de paiement devront pr&eacute;alablement recueillir le
          consentement du Payeur ou du Titulaire. En l&rsquo;absence d&rsquo;un
          tel consentement, l&rsquo;Op&eacute;ration ou la s&eacute;rie
          d&rsquo;Op&eacute;rations de paiement est r&eacute;put&eacute;e non
          autoris&eacute;e.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le consentement peut &ecirc;tre retir&eacute; par le Payeur tant que
          l&rsquo;Ordre de paiement n&rsquo;a pas acquis un caract&egrave;re
          d&rsquo;irr&eacute;vocabilit&eacute; conform&eacute;ment aux
          dispositions de l&rsquo;article L. 133-8 du Code mon&eacute;taire et
          financier.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lorsque l&rsquo;Op&eacute;ration de paiement est initi&eacute;e par
          le B&eacute;n&eacute;ficiaire ou par le Payeur qui donne un Ordre de
          paiement par l&rsquo;interm&eacute;diaire du
          B&eacute;n&eacute;ficiaire, le Payeur ne peut r&eacute;voquer
          l&rsquo;Ordre de paiement apr&egrave;s avoir transmis l&rsquo;Ordre de
          paiement au B&eacute;n&eacute;ficiaire ou donn&eacute; son
          consentement &agrave; l&rsquo;ex&eacute;cution de
          l&rsquo;Op&eacute;ration de paiement au
          B&eacute;n&eacute;ficiaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le consentement &agrave; l&rsquo;ex&eacute;cution d&rsquo;une
          s&eacute;rie d&rsquo;Op&eacute;rations de paiement peut aussi
          &ecirc;tre retir&eacute;, avec pour effet que toute Op&eacute;ration
          post&eacute;rieure est r&eacute;put&eacute;e non
          autoris&eacute;e.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c9">
          <span class="c10"
            >5.3 Montants des plafonds et limites applicables</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Toute Op&eacute;ration de paiement risquant d&rsquo;entra&icirc;ner
          un d&eacute;passement des plafonds applicables au montant des
          paiements mensuels cumul&eacute;s sera automatiquement rejet&eacute;e
          par Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >D&rsquo;autres plafonds ou blocages d&rsquo;Ordre pourront &ecirc;tre
          activ&eacute;s &agrave; tout moment par Lemonway en cas de risque de
          fraude.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway se r&eacute;serve le droit de contrepasser une
          Op&eacute;ration de paiement, si l&rsquo;op&eacute;ration de transfert
          de fonds par carte bancaire ou de paiement utilis&eacute;e pour
          cr&eacute;diter le Compte de paiement en vue de r&eacute;aliser cette
          op&eacute;ration est rejet&eacute;e ou annul&eacute;e par le PSP
          &eacute;metteur de la carte.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c9">
          <span class="c10">5.4 D&eacute;lais d&rsquo;ex&eacute;cution</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les d&eacute;lais maximaux d&rsquo;ex&eacute;cution des Services de
          paiement, conform&eacute;ment &agrave; l&rsquo;arr&ecirc;t&eacute; du
          29 juillet 2009, d&rsquo;application de l&rsquo;article L. 133-13 du
          Code mon&eacute;taire et financier, sont les suivants :</span
        >
      </p>
      <ul class="c2 lst-kix_list_9-0">
        <li class="c0 c18">
          <span class="c1"
            >une Op&eacute;ration de paiement initi&eacute;e un Jour
            Ouvr&eacute; sera ex&eacute;cut&eacute;e au plus tard par Lemonway
            le Jour Ouvr&eacute; suivant si elle est r&eacute;alis&eacute;e en
            euros au profit d&rsquo;un &eacute;tablissement de cr&eacute;dit
            localis&eacute; dans un Etat membre de l&rsquo;Union
            Europ&eacute;enne ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >une Op&eacute;ration de paiement initi&eacute;e un Jour
            Ouvr&eacute; sera ex&eacute;cut&eacute;e au plus tard par Lemonway
            &agrave; la fin dudit Jour Ouvr&eacute; si elle est
            r&eacute;alis&eacute;e en euros au profit d&rsquo;un autre Compte de
            paiement.</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-0" start="49">
        <li class="c20" ref="h.279ka65">
          <span class="c12">6. REPORTING</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9"><span class="c10">6.1 Par Op&eacute;ration</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lorsqu&rsquo;une Op&eacute;ration de paiement est effectu&eacute;e,
          Lemonway ou le Site Partenaire envoie automatiquement un email de
          confirmation de l&rsquo;Op&eacute;ration au Titulaire ayant
          initi&eacute; l&rsquo;Ordre de paiement. Cet email reprend
          l&rsquo;ensemble des informations relatives &agrave;
          l&rsquo;Op&eacute;ration de paiement qui ont &eacute;t&eacute;
          communiqu&eacute;es &agrave; Lemonway, telles que :
          l&rsquo;identit&eacute; du B&eacute;n&eacute;ficiaire ou du Payeur, le
          descriptif de l&rsquo;Op&eacute;ration de paiement, son montant, la
          date et l&rsquo;heure de l&rsquo;Op&eacute;ration de paiement ainsi
          que les &eacute;ventuelles conditions particuli&egrave;res de
          paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9"><span class="c10">6.2 Relev&eacute;s</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Toutes les Op&eacute;rations de paiement sont reprises dans un
          relev&eacute; &eacute;tabli en temps r&eacute;el pour chaque Compte de
          paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire aura acc&egrave;s aux relev&eacute;s de Comptes de
          paiement reprenant l&rsquo;ensemble des Op&eacute;rations de paiement
          inscrites au d&eacute;bit et au cr&eacute;dit de ce compte par
          l&rsquo;interm&eacute;diaire du Site Partenaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La p&eacute;riode de consultation est maintenue pendant deux (2) ans,
          plus l&rsquo;ann&eacute;e en cours. Lemonway conservera sur support
          &eacute;lectronique d&rsquo;archivage, pendant les d&eacute;lais
          r&eacute;glementaires applicables, les enregistrements et documents
          des Op&eacute;rations de paiement effectu&eacute;es.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="50">
        <li class="c20" ref="h.meukdy">
          <span class="c12"
            >7. DUR&Eacute;E DU CONTRAT-CADRE ET DATE D&rsquo;EFFET</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Contrat-cadre entre en vigueur au moment de l&rsquo;acceptation
          des pr&eacute;sentes par le Titulaire et ce pour une dur&eacute;e
          ind&eacute;termin&eacute;e.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire dispose d&rsquo;un d&eacute;lai de quatorze (14) jours
          calendaires pour renoncer sans frais au Contrat-cadre, s&rsquo;il
          r&eacute;pond aux conditions de l&rsquo;article D. 341-1 du Code
          mon&eacute;taire et financier pour les personnes morales ou s&rsquo;il
          est une personne physique. Ce d&eacute;lai court &agrave; compter du
          jour o&ugrave; le Contrat-cadre est conclu c&rsquo;est-&agrave;-dire
          le jour o&ugrave; le Titulaire a accept&eacute; les pr&eacute;sentes
          conditions g&eacute;n&eacute;rales. Pendant ce d&eacute;lai de
          renonciation, l&rsquo;ex&eacute;cution du Contrat-cadre ne pourra
          commencer qu&rsquo;&agrave; la demande expresse du Titulaire. Le
          Titulaire reconna&icirc;t express&eacute;ment et accepte que toute
          instruction de paiement adress&eacute;e &agrave; Lemonway avant
          l&rsquo;&eacute;coulement du d&eacute;lai de renonciation, constitue
          une demande expresse du Titulaire d&rsquo;ex&eacute;cuter le
          Contrat-cadre. Le Titulaire ne sera donc pas en droit d&rsquo;annuler
          une instruction de paiement qu&rsquo;il aurait donn&eacute;e et
          confirm&eacute;e pendant le d&eacute;lai de renonciation.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Ce droit de renonciation peut &ecirc;tre exerc&eacute; par le
          Titulaire sans p&eacute;nalit&eacute;s et sans indication de
          motif.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire est tenu de notifier sa d&eacute;cision de renonciation
          &agrave; Lemonway par tout moyen. Si le Titulaire n&rsquo;exerce pas
          son droit de renonciation, le Contrat-cadre sera maintenu
          conform&eacute;ment aux dispositions des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales. Il devra pour r&eacute;silier le
          Contrat-cadre se conformer aux conditions de r&eacute;siliation de
          l&rsquo;article 19.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="51">
        <li class="c20" ref="h.36ei31r">
          <span class="c12">8. R&Eacute;CLAMATIONS</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les r&eacute;clamations qui portent sur les relations entre deux
          Titulaires ou entre un Titulaire et un tiers ne sont pas recevables
          aupr&egrave;s de Lemonway. Seules celles qui portent sur
          l&rsquo;absence ou la mauvaise ex&eacute;cution d&rsquo;une
          Op&eacute;ration de paiement ex&eacute;cut&eacute;e par Lemonway sont
          vis&eacute;es par le pr&eacute;sent article et par le
          Contrat-cadre.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les r&eacute;clamations (contestations, droits d&rsquo;opposition,
          d&rsquo;acc&egrave;s et de rectification) peuvent &ecirc;tre
          exerc&eacute;es gratuitement sur demande adress&eacute;e &agrave;
          Lemonway via la page de r&eacute;clamation disponible &agrave;
          l&rsquo;adresse suivante : www.lemonway.com/reclamation ou par
          courrier &agrave; l&rsquo;adresse suivante :</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Soci&eacute;t&eacute; LEMONWAY &ndash; Service R&eacute;clamation
          &ndash; 8 rue du Sentier, CS 60820, 75083 Paris CEDEX 2.</span
        >
      </p>
      <p class="c0">
        <span class="c1">Toute contestation ou requ&ecirc;te relative :</span>
      </p>
      <ul class="c2 lst-kix_list_10-0">
        <li class="c0 c21">
          <span class="c1"
            >&agrave; une information communiqu&eacute;e par Lemonway en
            application du Contrat-cadre ;</span
          >
        </li>
        <li class="c0 c21">
          <span class="c1"
            >&agrave; une erreur dans l&rsquo;ex&eacute;cution des Services de
            paiement ou dans son inex&eacute;cution ;</span
          >
        </li>
        <li class="c0 c21">
          <span class="c1"
            >&agrave; une erreur dans le pr&eacute;l&egrave;vement de
            commission, taxe ou frais par Lemonway ;</span
          >
        </li>
      </ul>
      <p class="c0">
        <span class="c1"
          >doit &ecirc;tre notifi&eacute;e &agrave; Lemonway par le Titulaire
          dans les plus brefs d&eacute;lais &agrave; compter du jour o&ugrave;
          le Titulaire en a eu connaissance ou est pr&eacute;sum&eacute; en
          avoir eu connaissance ou dans tout autre d&eacute;lai plus long
          pr&eacute;vu par des dispositions particuli&egrave;res ou par la
          loi.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Conform&eacute;ment &agrave; la recommandation de l&rsquo;ACPR
          2016-R-02 du 14 novembre 2016, un accus&eacute; de r&eacute;ception
          sera envoy&eacute; sous dix jours maximum. Les r&eacute;clamations
          seront trait&eacute;es sous deux mois maximum &agrave; compter de leur
          r&eacute;ception.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >A d&eacute;faut d&rsquo;accord amiable, le Titulaire agissant pour
          des besoins non professionnels peut s&rsquo;adresser, par lettre,
          &agrave; un m&eacute;diateur ind&eacute;pendant, pouvant &ecirc;tre
          saisi gratuitement en cas de litige n&eacute; de l&rsquo;application
          des pr&eacute;sentes, le M&eacute;diateur de l&rsquo;AFEPAME, 36 rue
          Taitbout 75009 Paris, et ceci sans pr&eacute;judice des autres voies
          d&rsquo;actions l&eacute;gales.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="52">
        <li class="c20" ref="h.1ljsd9k"><span class="c12">9. FRAIS</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >En contrepartie de la fourniture des Services de paiement au
          Titulaire, Lemonway percevra une r&eacute;mun&eacute;ration dont le
          montant et les conditions sont fix&eacute;es entre le Partenaire et
          Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway informe le Titulaire que le Partenaire prend en charge
          l&rsquo;ensemble des frais relatifs &agrave; la fourniture des
          Services de paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="53">
        <li class="c20" ref="h.45jfvxd">
          <span class="c12">10. S&Eacute;CURIT&Eacute;</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">10.1 Obligation de notification</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a l&rsquo;obligation d&rsquo;informer
          imm&eacute;diatement Lemonway en cas de soup&ccedil;on
          d&rsquo;acc&egrave;s ou d&rsquo;utilisation frauduleuse de son Compte
          de paiement ou de tout &eacute;v&eacute;nement susceptible de mener
          &agrave; une telle utilisation, tels que et de mani&egrave;re non
          limitative : la perte, la divulgation accidentelle ou le
          d&eacute;tournement de ses identifiants de Compte de paiement ou une
          Op&eacute;ration non autoris&eacute;e.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Cette notification doit s&rsquo;effectuer par l&rsquo;envoi
          d&rsquo;un courrier &eacute;lectronique &agrave; l&rsquo;adresse email
          suivante : alerte.lcbft@lemonway.com et &ecirc;tre confirm&eacute;e
          par courrier &agrave; l&rsquo;adresse suivante :</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Soci&eacute;t&eacute; LEMONWAY &ndash; Service S&eacute;curit&eacute;
          Financi&egrave;re &ndash; 8 rue du Sentier, CS 60820, 75083 Paris
          CEDEX 2.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9"><span class="c10">10.2 Pr&eacute;vention</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway fera ses meilleurs efforts pour emp&ecirc;cher toute autre
          utilisation du Compte de paiement. Le Partenaire dispose
          &eacute;galement de ses propres moyens de communication
          s&eacute;curis&eacute;e avec le Titulaire sous sa propre
          responsabilit&eacute;.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c9"><span class="c10">10.3 Utilisation de cookies</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway informe le Titulaire que dans le cadre des Services de
          paiement, des cookies pourront &ecirc;tre utilis&eacute;s. Ces cookies
          servent avant tout &agrave; am&eacute;liorer le fonctionnement des
          Services de paiement notamment en termes de rapidit&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire est inform&eacute; qu&rsquo;il peut refuser les cookies
          aupr&egrave;s de Lemonway dans les param&egrave;tres de son
          navigateur, mais que cela risque d&rsquo;alt&eacute;rer son
          utilisation des Services de paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c9">
          <span class="c10">10.4 Interruption des Services de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway s&rsquo;engage &agrave; mettre en &oelig;uvre tous les
          moyens raisonnables &agrave; sa disposition pour assurer un service
          permanent. Lemonway ne garantit toutefois pas l&rsquo;acc&egrave;s
          continu, ininterrompu aux Service de paiement. En cons&eacute;quence
          Lemonway ne pourra pas &ecirc;tre tenue pour responsable du retard
          et/ou de la non-accessibilit&eacute; totale ou partielle aux Services
          de paiement, d&egrave;s lors qu&rsquo;ils r&eacute;sultent de facteurs
          &eacute;chappant au contr&ocirc;le raisonnable de Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire est inform&eacute; que Lemonway peut interrompre,
          occasionnellement, l&rsquo;acc&egrave;s &agrave; tout ou partie des
          Services de paiement :</span
        >
      </p>
      <ul class="c2 lst-kix_list_11-0">
        <li class="c0 c23">
          <span class="c1"
            >pour permettre les r&eacute;parations, maintenances, ajouts de
            fonctionnalit&eacute; ;</span
          >
        </li>
        <li class="c0 c23">
          <span class="c1"
            >en cas de suspicion de tentative de piratage, de
            d&eacute;tournement de fonds ou de tout autre risque
            d&rsquo;atteinte ;</span
          >
        </li>
        <li class="c0 c23">
          <span class="c1"
            >sur demandes ou instructions &eacute;manant de personnes ou
            autorit&eacute;s comp&eacute;tentes habilit&eacute;es.</span
          >
        </li>
      </ul>
      <p class="c0">
        <span class="c1"
          >Lemonway ne peut en aucun cas &ecirc;tre tenue responsable des
          dommages r&eacute;sultants &eacute;ventuellement de ces
          suspensions.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >D&egrave;s la reprise normale du service, Lemonway mettra en
          &oelig;uvre les efforts raisonnables pour traiter les
          Op&eacute;rations de paiement en attente dans les meilleurs
          d&eacute;lais.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c9">
          <span class="c10"
            >10.5 Opposition au dispositif de s&eacute;curit&eacute;</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut faire opposition en contactant Lemonway via la page
          de r&eacute;clamation disponible &agrave; l&rsquo;adresse suivante :
          www.lemonway.com/reclamation ou par t&eacute;l&eacute;phone au
          num&eacute;ro : : +33 1 76 44 04 60.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le dispositif de s&eacute;curit&eacute; s&rsquo;entend comme toute
          mesure de s&eacute;curisation des Op&eacute;rations de paiement et/ou
          d&rsquo;acc&egrave;s au Titulaire &agrave; son Compte via son espace
          client du Site Partenaire, conform&eacute;ment &agrave; la
          r&eacute;glementation en vigueur.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Un num&eacute;ro d&rsquo;enregistrement de cette opposition est
          cr&eacute;&eacute; et est conserv&eacute; pendant 18 mois. Sur demande
          &eacute;crite du Titulaire et avant l&rsquo;expiration de ce
          d&eacute;lai, Lemonway lui communiquera une copie de cette
          opposition.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway ne saurait &ecirc;tre tenue pour responsable des
          cons&eacute;quences d&rsquo;une opposition qui
          n&rsquo;&eacute;manerait pas du Titulaire. La demande
          d&rsquo;opposition est r&eacute;put&eacute;e faite &agrave; la date de
          r&eacute;ception effective de la demande par Lemonway ou toute
          personne mandat&eacute;e par lui, &agrave; cet effet. En cas de vol ou
          d&rsquo;utilisation frauduleuse, Lemonway est habilit&eacute;e
          &agrave; demander un r&eacute;c&eacute;piss&eacute; ou une copie du
          d&eacute;p&ocirc;t de plainte au Titulaire qui s&rsquo;engage &agrave;
          y r&eacute;pondre dans les plus brefs d&eacute;lais.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway bloquera l&rsquo;acc&egrave;s au Compte de paiement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="54">
        <li class="c20" ref="h.2koq656">
          <span class="c12">11. RESPONSABILIT&Eacute;S</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Conform&eacute;ment &agrave; l&rsquo;article L. 133-22 du Code
          mon&eacute;taire et financier, Lemonway est responsable, sous
          r&eacute;serve des articles L. 133-5 et L. 133-21 du Code
          mon&eacute;taire et financier, de la bonne ex&eacute;cution de
          l&rsquo;Op&eacute;ration de paiement &agrave; l&rsquo;&eacute;gard du
          Titulaire Payeur jusqu&rsquo;&agrave; r&eacute;ception des fonds par
          le PSP tiers du B&eacute;n&eacute;ficiaire. Lorsque Lemonway est
          responsable d&rsquo;une Op&eacute;ration de paiement mal
          ex&eacute;cut&eacute;e par sa faute, Lemonway restitue sans tarder son
          montant au Payeur et r&eacute;tablit le compte d&eacute;bit&eacute;
          dans la situation qui aurait pr&eacute;valu si
          l&rsquo;Op&eacute;ration de paiement mal ex&eacute;cut&eacute;e
          n&rsquo;avait pas eu lieu.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire agissant pour des besoins non professionnels qui
          souhaite contester une Op&eacute;ration de paiement non
          autoris&eacute;e par lui doit contacter le service clients
          conform&eacute;ment &agrave; l&rsquo;article 8 dans les plus brefs
          d&eacute;lais suivant sa prise de connaissance de l&rsquo;anomalie et
          au plus tard 13 mois suivant l&rsquo;inscription de
          l&rsquo;Op&eacute;ration de paiement. En cas d&rsquo;utilisation du
          dispositif de s&eacute;curit&eacute;, les Op&eacute;rations de
          paiement non autoris&eacute;es effectu&eacute;es avant la notification
          de l&rsquo;opposition sont &agrave; la charge du Titulaire agissant
          pour des besoins non professionnels, dans la limite d&rsquo;un plafond
          de 50 euros conform&eacute;ment &agrave; l&rsquo;article L. 133-19 du
          Code mon&eacute;taire et financier. Toutefois, la
          responsabilit&eacute; de Lemonway n&rsquo;est pas engag&eacute;e en
          cas de faute du Titulaire tel qu&rsquo;un manquement volontaire ou
          constitutif d&rsquo;une n&eacute;gligence grave &agrave; ses
          obligations, une transmission tardive de l&rsquo;opposition ou de
          mauvaise foi. En cas de d&eacute;tournement de ses donn&eacute;es ou
          contrefa&ccedil;on, les pertes r&eacute;sultant des Op&eacute;rations
          de paiement pass&eacute;es avant l&rsquo;opposition par le Titulaire
          agissant pour des besoins non professionnels sont support&eacute;es
          par Lemonway, sauf en cas de faute telle que d&eacute;finie ci-dessus.
          Les Op&eacute;rations de paiement r&eacute;alis&eacute;es apr&egrave;s
          l&rsquo;opposition du Titulaire agissant pour des besoins non
          professionnels sont support&eacute;es par Lemonway sauf en cas de
          fraude.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway n&rsquo;est pas en droit d&rsquo;annuler un Ordre de
          paiement irr&eacute;vocable sur demande du Titulaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En aucun cas, Lemonway n&rsquo;est responsable des dommages
          indirects, tels que pr&eacute;judice commercial, perte de
          client&egrave;le, trouble commercial quelconque, perte de
          b&eacute;n&eacute;fice, perte d&rsquo;image de marque subis par un
          Titulaire, ou par un tiers, et qui pourraient r&eacute;sulter des
          Services de paiement fournis par Lemonway. Toute action dirig&eacute;e
          contre un Titulaire par un tiers est assimil&eacute;e &agrave; un
          pr&eacute;judice indirect, et en cons&eacute;quence n&rsquo;ouvre pas
          droit &agrave; r&eacute;paration.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Sauf stipulation contraire des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales ou des lois imp&eacute;ratives et sans
          pr&eacute;judice des autres causes d&rsquo;exclusion ou de limitation
          de responsabilit&eacute; pr&eacute;vues par les pr&eacute;sentes,
          Lemonway ne pourra en aucun cas &ecirc;tre tenue pour responsable de
          tout dommage caus&eacute; par un cas de force majeure ou un
          &eacute;v&eacute;nement hors de son contr&ocirc;le ou de toute mesure
          ou dispositions l&eacute;gislatives prises par les autorit&eacute;s
          fran&ccedil;aises ou &eacute;trang&egrave;res. Sont
          r&eacute;put&eacute;s constituer un cas de force majeure ou un
          &eacute;v&eacute;nement hors de son contr&ocirc;le, notamment, mais
          sans que cela soit limitatif : une panne
          d&rsquo;&eacute;lectricit&eacute;, un incendie ou une inondation, la
          gr&egrave;ve de son personnel ou d&rsquo;un de ses sous-traitants ou
          fournisseurs, un dysfonctionnement des syst&egrave;mes interbancaires
          ou de paiement par carte bancaire, troubles &agrave; l&rsquo;ordre
          public, n&eacute;gligence d&rsquo;un tiers au sens retenu par la
          jurisprudence et de la doctrine telles que les personnes responsables
          de la livraison d&rsquo;&eacute;lectricit&eacute;, des services de
          t&eacute;l&eacute;communication ou d&rsquo;h&eacute;bergement.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="55">
        <li class="c20" ref="h.zu0gcz">
          <span class="c12">12. PROTECTION DES FONDS CLIENTS</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway conservera les fonds disponibles inscrits au cr&eacute;dit
          du Compte de paiement du Titulaire &agrave; la fin du Jour
          Ouvr&eacute; suivant le jour o&ugrave; ils ont &eacute;t&eacute;
          re&ccedil;us sur un compte de cantonnement ouvert aupr&egrave;s des
          banques partenaires de Lemonway conform&eacute;ment l&rsquo;article L.
          522-17 du Code mon&eacute;taire et financier.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="56">
        <li class="c20" ref="h.3jtnz0s">
          <span class="c12"
            >13. D&Eacute;C&Egrave;S &ndash; COMPTE DE PAIEMENT INACTIF &ndash;
            PROCURATION</span
          >
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9"><span class="c10">13.1 D&eacute;c&egrave;s</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >En cas de d&eacute;c&egrave;s du Titulaire du Compte de paiement,
          Lemonway doit en &ecirc;tre avis&eacute;e le plus rapidement possible
          par les ayant droits ou leur mandataire. Si cet avis est donn&eacute;
          verbalement, il doit &ecirc;tre confirm&eacute; par &eacute;crit.
          D&egrave;s r&eacute;ception de cet &eacute;crit, Lemonway veillera
          &agrave; ce qu&rsquo;aucune nouvelle Op&eacute;ration de paiement ne
          soit ex&eacute;cut&eacute;e et proc&egrave;dera &agrave; la
          cl&ocirc;ture du Compte.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Si la Provision que Lemonway d&eacute;tient au nom du d&eacute;funt
          est sup&eacute;rieure aux frais permettant de supporter les
          co&ucirc;ts de retrait, elle pourra faire l&rsquo;objet d&rsquo;un
          remboursement en faveur des ayants-droits uniquement en cas de
          production par ces ayants-droits ou leur mandataire des pi&egrave;ces
          probantes, selon la l&eacute;gislation applicable, &eacute;tablissant
          la d&eacute;volution de la succession ainsi que de toute autre
          pi&egrave;ce que Lemonway jugerait n&eacute;cessaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >A d&eacute;faut de virement pour quelque raison que ce soit, y
          compris l&rsquo;absence de production de document probant &agrave;
          Lemonway, les dispositions de l&rsquo;article 13.2 des
          pr&eacute;sentes conditions g&eacute;n&eacute;rales
          s&rsquo;appliqueront &agrave; la Provision.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9"><span class="c10">13.2 Compte inactif</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Un Compte de paiement est r&eacute;put&eacute; inactif si :</span
        >
      </p>
      <ol class="c2 lst-kix_list_12-0" start="4">
        <li class="c0 c16">
          <span class="c1"
            >a) le Compte de paiement n&rsquo;a fait l&rsquo;objet
            d&rsquo;aucune Op&eacute;ration de paiement pendant une
            p&eacute;riode de douze mois au cours de laquelle, hors inscription
            de d&eacute;bit par Lemonway tenant le compte de frais et
            commissions de toutes natures, et</span
          >
        </li>
        <li class="c0 c16">
          <span class="c1"
            >b) le Titulaire du Compte, son repr&eacute;sentant l&eacute;gal ou
            la personne habilit&eacute;e par lui ne s&rsquo;est pas
            manifest&eacute;, sous quelque forme que ce soit, aupr&egrave;s de
            Lemonway ; ou</span
          >
        </li>
        <li class="c0 c16">
          <span class="c1"
            >&agrave; l&rsquo;issue d&rsquo;une p&eacute;riode de douze (12)
            mois suivant le d&eacute;c&egrave;s du Titulaire. Le Titulaire et
            ses ayants droit sont inform&eacute;s par les pr&eacute;sentes des
            cons&eacute;quences qui y sont attach&eacute;es.</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les avoirs inscrits sur le Compte de paiement inactif sont
          d&eacute;pos&eacute;s &agrave; la Caisse des d&eacute;p&ocirc;ts et
          consignations &agrave; l&rsquo;issue d&rsquo;un d&eacute;lai de dix
          (10) ans &agrave; compter de la date de la derni&egrave;re
          Op&eacute;ration de paiement hors inscription des d&eacute;bits par
          Lemonway tenant le compte de frais et commissions de toutes natures ;
          sauf en cas de d&eacute;c&egrave;s du Titulaire du compte o&ugrave;
          les avoirs inscrits sur le compte de paiement inactif sont
          d&eacute;pos&eacute;s &agrave; la Caisse des d&eacute;p&ocirc;ts et
          consignations &agrave; l&rsquo;issue d&rsquo;un d&eacute;lai de trois
          (3) ans apr&egrave;s la date du d&eacute;c&egrave;s du
          Titulaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Pour toute relance et notification de Lemonway au Titulaire ou au
          Partenaire, des frais seront applicables. En cas de compte inactifs et
          apr&egrave;s relance et notification de Lemonway, des frais de gestion
          seront applicables.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c9"><span class="c10">13.3 Procuration</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut donner &agrave; une personne pouvoir
          d&rsquo;effectuer sur son Compte de paiement et sous son
          enti&egrave;re responsabilit&eacute; les Op&eacute;rations de paiement
          telles que d&eacute;finies dans la procuration. La procuration ne
          prendra effet qu&rsquo;&agrave; compter de la r&eacute;ception et de
          l&rsquo;acceptation du formulaire d&ucirc;ment compl&eacute;t&eacute;
          par Lemonway. La procuration sera notifi&eacute;e par tout moyen. Elle
          cesse automatiquement au d&eacute;c&egrave;s du Titulaire. Elle peut
          &ecirc;tre r&eacute;voqu&eacute;e &agrave; l&rsquo;initiative du
          Titulaire qui en informe le mandataire et Lemonway par lettre
          recommand&eacute;e avec avis de r&eacute;ception. La r&eacute;vocation
          prend effet &agrave; la date de r&eacute;ception de la notification
          par Lemonway. Le Titulaire reste tenu des Op&eacute;rations de
          paiement initi&eacute;es pour son compte jusqu&rsquo;&agrave; cette
          date par le mandataire d&eacute;sign&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire l&egrave;ve express&eacute;ment Lemonway du secret
          professionnel relatif aux donn&eacute;es du Compte de paiement
          &agrave; l&rsquo;&eacute;gard du mandataire d&eacute;sign&eacute; par
          la procuration.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="57">
        <li class="c20" ref="h.1yyy98l">
          <span class="c12">14. PROPRI&Eacute;T&Eacute; INTELLECTUELLE</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Aucun droit de propri&eacute;t&eacute; intellectuelle relatif
          &agrave; l&rsquo;utilisation du Service de paiement ou aux prestations
          rendues par Lemonway n&rsquo;est transf&eacute;r&eacute; au Titulaire
          au titre des pr&eacute;sentes conditions
          g&eacute;n&eacute;rales.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire s&rsquo;engage &agrave; ne pas porter atteinte aux
          droits d&eacute;tenus par Lemonway, en s&rsquo;interdisant notamment,
          toute reproduction, ou adaptation de tout ou partie des
          &eacute;l&eacute;ments intellectuels et mat&eacute;riels de Lemonway
          et ses accessoires, et ce quel que soit le support, actuel et
          futur.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >L&rsquo;ensemble des droits relatifs aux logiciels servant &agrave;
          la r&eacute;alisation des Services de paiement sont la
          propri&eacute;t&eacute; pleine et enti&egrave;re de la
          soci&eacute;t&eacute; Lemonway. Ils font partie de ses informations
          confidentielles sans &eacute;gard au fait que certaines composantes
          puissent ou non &ecirc;tre prot&eacute;g&eacute;es en
          l&rsquo;&eacute;tat actuel de la l&eacute;gislation par un droit de
          propri&eacute;t&eacute; intellectuelle.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les logiciels de Lemonway et, le cas &eacute;ch&eacute;ant, leur
          documentation, sont reconnus par le Titulaire comme &oelig;uvre de
          l&rsquo;esprit que lui-m&ecirc;me et les membres de son personnel
          s&rsquo;obligent &agrave; consid&eacute;rer comme telle en
          s&rsquo;interdisant de les copier, de les reproduire, de les traduire
          en toute autre langue ou langage, de les adapter, de les distribuer
          &agrave; titre gratuit ou on&eacute;reux, ou de leur adjoindre tout
          objet non conforme &agrave; leurs sp&eacute;cifications.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La marque &laquo; Lemonway &raquo; est la propri&eacute;t&eacute; de
          la soci&eacute;t&eacute; Lemonway. Le Titulaire s&rsquo;engage
          &agrave; ne pas supprimer la mention de la marque &laquo; Lemonway
          &raquo; sur tout &eacute;l&eacute;ment fourni ou mis &agrave; sa
          disposition par Lemonway, tels que logiciel, document ou
          banni&egrave;re publicitaire.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="58">
        <li class="c20" ref="h.4iylrwe">
          <span class="c12">15. CONFIDENTIALIT&Eacute;</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire s&rsquo;engage &agrave; respecter la plus stricte
          confidentialit&eacute; concernant l&rsquo;ensemble des techniques,
          commerciales ou de toute autre nature dont il viendrait &agrave; avoir
          connaissance dans le cadre de l&rsquo;ex&eacute;cution du Service de
          paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Cette obligation de confidentialit&eacute; demeurera en vigueur
          pendant la dur&eacute;e de la souscription au Service de paiement et
          pendant trois (3) ans suivant la date de r&eacute;siliation du
          Contrat-cadre. Cette obligation de confidentialit&eacute; ne
          s&rsquo;applique pas aux informations qui sont ou deviennent
          publiquement disponibles sans faute du Titulaire.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les Parties reconnaissent que les Op&eacute;rations de paiement sont
          couvertes par le secret professionnel en application de
          l&rsquo;article L. 522-19 du Code mon&eacute;taire et financier.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="59">
        <li class="c20" ref="h.2y3w247">
          <span class="c12"
            >16. COLLECTE ET TRAITEMENT DES DONNEES A CARACTERE PERSONNEL</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Conform&eacute;ment au R&egrave;glement G&eacute;n&eacute;ral sur la
          Protection des Donn&eacute;es (General Data Protection
          R&eacute;gulation) adopt&eacute; par le Parlement europ&eacute;en le
          14 avril 2016, et &agrave; la Loi Informatique et Libert&eacute;s du 6
          janvier 1978 modifi&eacute;e, Lemonway informe le Titulaire :</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c9">
          <span class="c10"
            >16.1 Identification du responsable de traitement</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Soci&eacute;t&eacute; Lemonway SAS, si&egrave;ge social sis au 8 rue
          du Sentier, 75002 Paris, France, T&eacute;l. : +33 1 76 44 04
          60.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c9">
          <span class="c10"
            >16.2 D&eacute;l&eacute;gu&eacute; &agrave; la protection des
            donn&eacute;es</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire pourra joindre le d&eacute;l&eacute;gu&eacute; &agrave;
          la protection des donn&eacute;es &agrave; l&rsquo;adresse courriel
          suivante : dpo@lemonway.com</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="6">
        <li class="c9">
          <span class="c10">16.3 Finalit&eacute;s du traitement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Dans le cadre de l&rsquo;exploitation du Site et des services fournis
          par Lemonway, le traitement de donn&eacute;es personnelles a pour
          finalit&eacute; la gestion des clients, la cr&eacute;ation et la
          gestion des comptes, la gestion des contrats, la gestion des
          r&eacute;siliations, la gestion des litiges, la gestion du Site
          Internet, le mailing, les communications, les v&eacute;rifications en
          mati&egrave;re de lutte contre le blanchiment de capitaux et de lutte
          contre le financement du terrorisme, la gestion de la connaissance
          client, l&rsquo;&eacute;laboration de statistiques dans le but
          d&rsquo;am&eacute;liorer les outils de Lemonway, la gestion des
          demandes concernant les droits des personnes,
          l&rsquo;impl&eacute;mentation des Partenaires, la gestion du
          support.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="7">
        <li class="c9">
          <span class="c10">16.4 Nature des donn&eacute;es</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway collecte de mani&egrave;re directe et indirecte les
          cat&eacute;gories de donn&eacute;es suivantes concernant ses
          utilisateurs :</span
        >
      </p>
      <ul class="c2 lst-kix_list_13-0">
        <li class="c0 c18">
          <span class="c1"
            >Donn&eacute;es d&rsquo;&eacute;tat-civil, d&rsquo;identit&eacute;,
            d&rsquo;identification&hellip; ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Donn&eacute;es relatives &agrave; la vie professionnelle (CV,
            scolarit&eacute;, formation professionnelle, &hellip;) ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Informations d&rsquo;ordre &eacute;conomique et financier (revenus,
            situation financi&egrave;re, situation fiscale&hellip;) ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Donn&eacute;es de connexion (adresses IP, journaux
            d&rsquo;&eacute;v&eacute;nements&hellip;).</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">16.5 Source des donn&eacute;es</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway collecte les donn&eacute;es &agrave; caract&egrave;re
          personnel de mani&egrave;re directe par le biais d&rsquo;un contrat,
          d&rsquo;une obligation l&eacute;gale, du consentement de la personne
          ou de l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime de la
          soci&eacute;t&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway collecte &eacute;galement les donn&eacute;es &agrave;
          caract&egrave;re personnel de mani&egrave;re indirecte afin de
          respecter la r&egrave;glementation en mati&egrave;re de lutte contre
          le blanchiment de capitaux et de lutte contre le financement du
          terrorisme.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9">
          <span class="c10">16.6 Consentement de la personne</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lorsque la personne a donn&eacute; son consentement pour la collecte
          de ses donn&eacute;es personnelles, celle-ci peut retirer son
          consentement &agrave; tout moment. Le Titulaire peut retirer son
          consentement via l&rsquo;adresse dpo@lemonway.com.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway informe qu&rsquo;un tel retrait entra&icirc;nera la
          cl&ocirc;ture du Compte.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="3">
        <li class="c9">
          <span class="c10"
            >16.7 Int&eacute;r&ecirc;t l&eacute;gitime du traitement</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lorsque Lemonway collecte et utilise des donn&eacute;es personnelles
          de repr&eacute;sentants de ses Partenaires en se fondant sur
          l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime, celui-ci a pour
          finalit&eacute; la prospection de nouveaux Partenaires.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="4">
        <li class="c9"><span class="c10">16.8 Scoring</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Un scoring est mis en place uniquement en mati&egrave;re de lutte
          contre le blanchiment de capitaux, de lutte contre le financement du
          terrorisme et de lutte contre la fraude.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="5">
        <li class="c9">
          <span class="c10">16.9 Destinataires des donn&eacute;es</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les destinataires des donn&eacute;es &agrave; caract&egrave;re
          personnel sont les collaborateurs habilit&eacute;s au sein de
          Lemonway, les autorit&eacute;s de contr&ocirc;le, les partenaires de
          Lemonway et ses sous-traitants. Les donn&eacute;es personnelles
          peuvent &eacute;galement &ecirc;tre divulgu&eacute;es en application
          d&rsquo;une loi, d&rsquo;un r&egrave;glement ou en vertu d&rsquo;une
          d&eacute;cision d&rsquo;une autorit&eacute; r&eacute;glementaire ou
          judiciaire comp&eacute;tente.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="6">
        <li class="c9">
          <span class="c10">16.10 Dur&eacute;e de conservation</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les donn&eacute;es &agrave; caract&egrave;re personnel
          collect&eacute;es par Lemonway sont conserv&eacute;es le temps
          n&eacute;cessaire &agrave; la finalit&eacute; du traitement.
          Au-del&agrave; de cette dur&eacute;e de conservation, elles deviennent
          des archives interm&eacute;diaires ou sont anonymis&eacute;es et
          conserv&eacute;es dans un but statistique et historique.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Des purges concernant les donn&eacute;es &agrave; caract&egrave;re
          personnel sont mises en place afin de v&eacute;rifier la suppression
          effective d&egrave;s lors que la dur&eacute;e de conservation ou
          d&rsquo;archivage n&eacute;cessaire &agrave; l&rsquo;accomplissement
          des finalit&eacute;s d&eacute;termin&eacute;es ou impos&eacute;es est
          atteinte.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="7">
        <li class="c9"><span class="c10">16.11 Droits des personnes</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Conform&eacute;ment aux dispositions en vigueur, le Titulaire dispose
          de droits concernant ses donn&eacute;es personnelles qu&rsquo;il
          pourra exercer en &eacute;crivant &agrave; l&rsquo;adresse postale
          mentionn&eacute;e au point 16.1 en l&rsquo;adressant au DPO ou en
          &eacute;crivant &agrave; dpo@lemonway.com.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="3">
        <li class="c9">
          <span class="c22">&Oslash; Droit d&rsquo;acc&egrave;s</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire dispose de la facult&eacute; d&rsquo;acc&eacute;der aux
          donn&eacute;es personnelles le concernant. Cependant, pour des motifs
          de s&eacute;curit&eacute; et de confidentialit&eacute;, la demande ne
          pourra &ecirc;tre trait&eacute;e que si le Titulaire apporte la preuve
          de son identit&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway peut s&rsquo;opposer ou mettre en place une facturation pour
          les demandes manifestement abusives (nombre important de demandes,
          caract&egrave;re r&eacute;p&eacute;titif ou
          syst&eacute;matique).</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="4">
        <li class="c9">
          <span class="c22">&Oslash; Droit de rectification</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de demander la rectification de ses
          donn&eacute;es personnelles lorsque celles-ci sont inexactes,
          erron&eacute;es, incompl&egrave;tes ou obsol&egrave;tes.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="5">
        <li class="c9">
          <span class="c22">&Oslash; Droit &agrave; limitation</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de demander la limitation de ses
          donn&eacute;es personnelles. Lorsque le droit &agrave; limitation est
          demand&eacute;, Lemonway pourra seulement stocker les donn&eacute;es.
          Aucune autre op&eacute;ration ne pourra avoir lieu.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="6">
        <li class="c9">
          <span class="c22">&Oslash; Droit &agrave; portabilit&eacute;</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire conserve la facult&eacute; de demander de
          r&eacute;cup&eacute;rer les donn&eacute;es personnelles qu&rsquo;il a
          fourni &agrave; Lemonway, dans un format structur&eacute;, couramment
          utilis&eacute; et lisible par machine afin de les transmettre &agrave;
          un autre responsable de traitement. Ce droit ne peut &ecirc;tre
          utilis&eacute; que si le traitement des donn&eacute;es est bas&eacute;
          sur le consentement de la personne concern&eacute;e ou sur un
          contrat.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="7">
        <li class="c9">
          <span class="c22">&Oslash; Droit d&rsquo;opposition</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de s&rsquo;opposer &agrave;
          l&rsquo;utilisation de ses donn&eacute;es dans deux situations :</span
        >
      </p>
      <ul class="c2 lst-kix_list_14-0">
        <li class="c0 c18">
          <span class="c1">Motifs l&eacute;gitimes ;</span>
        </li>
        <li class="c0 c18">
          <span class="c1"
            >En cas d&rsquo;utilisation des donn&eacute;es recueillies &agrave;
            des fins commerciales.</span
          >
        </li>
      </ul>
      <ol class="c2 lst-kix_list_1-2 start" start="1">
        <li class="c9">
          <span class="c22">&Oslash; Droit &agrave; l&rsquo;effacement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de demander l&rsquo;effacement de
          ses donn&eacute;es dans les meilleurs d&eacute;lais si l&rsquo;un des
          motifs du paragraphe 1 de l&rsquo;article 17 du R&egrave;glement
          G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es
          s&rsquo;applique.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Si les donn&eacute;es du Titulaire ont &eacute;t&eacute; transmises
          &agrave; d&rsquo;autres entit&eacute;s, le m&eacute;canisme du &laquo;
          droit &agrave; l&rsquo;oubli &raquo; s&rsquo;enclenche : Lemonway
          devra prendre toutes les mesures raisonnables pour informer les autres
          entit&eacute;s que la personne concern&eacute;e a demand&eacute;
          l&rsquo;effacement de tout lien vers ses donn&eacute;es personnelles,
          ou de toute copie ou reproduction de celles-ci.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-2" start="2">
        <li class="c9"><span class="c22">&Oslash; Droit post mortem</span></li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire a la facult&eacute; de d&eacute;finir des directives
          concernant les donn&eacute;es personnelles du Titulaire apr&egrave;s
          son d&eacute;c&egrave;s. Le cas &eacute;ch&eacute;ant, les
          h&eacute;ritiers du Titulaire peuvent exiger de prendre en
          consid&eacute;ration le d&eacute;c&egrave;s ou proc&eacute;der aux
          mises &agrave; jour.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="8">
        <li class="c9">
          <span class="c10">16.12 D&eacute;lai de r&eacute;ponse</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway s&rsquo;engage &agrave; r&eacute;pondre aux demandes
          relatives aux donn&eacute;es &agrave; caract&egrave;re personnel
          d&rsquo;acc&egrave;s ou &agrave; l&rsquo;exercice d&rsquo;un droit
          dans un d&eacute;lai d&rsquo;un (1) mois &agrave; compter de la
          r&eacute;ception de la demande.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="9">
        <li class="c9">
          <span class="c10">16.13 Transfert de donn&eacute;es</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Lemonway a recours &agrave; des prestataires habilit&eacute;s se
          situant &agrave; l&rsquo;int&eacute;rieur et &agrave;
          l&rsquo;ext&eacute;rieur de l&rsquo;Union Europ&eacute;enne.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas de transfert vers un pays tiers, Lemonway respecte le
          R&egrave;glement G&eacute;n&eacute;ral sur la Protection des
          Donn&eacute;es en ayant recours &agrave; des partenaires ou
          sous-traitant pr&eacute;sentant des garanties ad&eacute;quates par le
          biais d&rsquo;une proc&eacute;dure d&rsquo;ad&eacute;quation, de
          clauses contractuelles types ou de r&egrave;gles internes
          d&rsquo;entreprise.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="10">
        <li class="c9">
          <span class="c10"
            >16.14 Commission Nationale Informatique et Libert&eacute;s
            (CNIL)</span
          >
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Si le Titulaire consid&egrave;re que Lemonway ne respecte pas ses
          obligations au regard de Loi Informatique et Libert&eacute;s ou du
          R&egrave;glement G&eacute;n&eacute;ral sur la Protection des
          Donn&eacute;es, il pourra adresser une plainte ou une demande
          aupr&egrave;s de l&rsquo;autorit&eacute; comp&eacute;tente. Le
          si&egrave;ge social de Lemonway se situant en France,
          l&rsquo;autorit&eacute; comp&eacute;tente est la Commission Nationale
          Informatique et Libert&eacute;s. Le Titulaire a la possibilit&eacute;
          de s&rsquo;adresser &agrave; la Commission Nationale Informatique et
          Libert&eacute;s par voie &eacute;lectronique via le lien suivant :
          https://www.cnil.fr/fr/plaintes/internet.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="60">
        <li class="c20" ref="h.1d96cc0">
          <span class="c12">17. CONVENTION DE PREUVE</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les communications effectu&eacute;es par le biais de courrier
          &eacute;lectronique sont des modes de communication valablement admis
          &agrave; titre de preuve par le Titulaire et Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Toutes les informations enregistr&eacute;es dans les bases de
          donn&eacute;es informatiques de Lemonway relatives notamment aux
          Ordres et Op&eacute;rations de paiement, ont, jusqu&rsquo;&agrave;
          preuve du contraire, la m&ecirc;me force probante qu&rsquo;un
          &eacute;crit sign&eacute; sur un support papier, tant en ce qui
          concerne leur contenu qu&rsquo;en ce qui concerne la date et
          l&rsquo;heure &agrave; laquelle ils ont &eacute;t&eacute;
          effectu&eacute;s et/ou re&ccedil;us. Ces traces inalt&eacute;rables,
          s&ucirc;res et fiables sont grav&eacute;es et conserv&eacute;es dans
          les syst&egrave;mes informatiques de Lemonway.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Les documents de Lemonway reproduisant ces informations, ainsi que
          les copies ou reproductions de documents produits par Lemonway ont la
          m&ecirc;me force probante que l&rsquo;original, jusqu&rsquo;&agrave;
          preuve du contraire.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="61">
        <li class="c20" ref="h.3x8tuzt">
          <span class="c12">18. BLOCAGE ET GEL DU COMPTE</span>
        </li>
      </ol>
      <ol class="c2 lst-kix_list_1-1 start" start="1">
        <li class="c9">
          <span class="c10">18.1 Blocage du Compte de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >La suspension temporaire et imm&eacute;diate d&rsquo;un Compte de
          paiement peut &ecirc;tre prononc&eacute;e par Lemonway pour toute
          raison &agrave; l&rsquo;appr&eacute;ciation de Lemonway et notamment
          :</span
        >
      </p>
      <ul class="c2 lst-kix_list_15-0">
        <li class="c0 c18">
          <span class="c1"
            >Si le Titulaire n&rsquo;a pas respect&eacute; les dispositions du
            Contrat-cadre ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >Si le Titulaire a fourni &agrave; Lemonway des donn&eacute;es
            d&rsquo;identification inexactes, p&eacute;rim&eacute;es ou
            incompl&egrave;tes ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >En cas de risque sensiblement accru d&rsquo;incapacit&eacute; par
            le Titulaire de s&rsquo;acquitter de son obligation de paiement
            ;</span
          >
        </li>
        <li class="c0 c18">
          <span class="c1"
            >En cas de r&eacute;ception par Lemonway d&rsquo;un nombre important
            de remboursements, d&rsquo;impay&eacute;s, d&rsquo;annulation
            d&rsquo;Ordres ou de contestation pour Op&eacute;rations de paiement
            non autoris&eacute;es.</span
          >
        </li>
      </ul>
      <p class="c0">
        <span class="c1"
          >Cette d&eacute;cision est notifi&eacute;e au Titulaire par tout
          moyen. La suspension du Compte de paiement ayant pour objet de
          prot&eacute;ger le Titulaire, elle ne pourra en aucun cas donner lieu
          au versement de dommages int&eacute;r&ecirc;ts au profit de ce
          dernier.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La r&eacute;activation du Compte de paiement se fera &agrave; la
          discr&eacute;tion de Lemonway, sur la base d&rsquo;informations ou de
          documents additionnels qui pourront &ecirc;tre
          r&eacute;clam&eacute;s.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En fonction de la gravit&eacute; du manquement au Contrat-cadre et
          notamment en cas d&rsquo;utilisation du Compte de paiement &agrave;
          des fins illicites ou contraire aux bonnes m&oelig;urs, Lemonway se
          r&eacute;serve le droit de r&eacute;silier le Contrat-cadre
          conform&eacute;ment aux dispositions de l&rsquo;article 19.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway se r&eacute;serve le droit d&rsquo;appliquer des
          p&eacute;nalit&eacute;s, des frais de gestion et de r&eacute;clamer
          des dommages et int&eacute;r&ecirc;ts au Titulaire.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-1" start="2">
        <li class="c9">
          <span class="c10">18.2 Gel du Compte de paiement</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Dans le cadre du dispositif de filtrage mis en place par Lemonway
          (notamment bas&eacute; sur les listes de sanctions internationales et
          de personnes politiquement expos&eacute;es), le gel imm&eacute;diat
          d&rsquo;un Compte de paiement pourra &ecirc;tre prononc&eacute; en cas
          de risque de fraude, de blanchiment de capitaux ou financement du
          terrorisme ou de risque pouvant affecter la s&eacute;curit&eacute; du
          Compte de paiement.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le gel du Compte de paiement sera lev&eacute; ou non apr&egrave;s
          analyse approfondie du risque par les &eacute;quipes LCB-FT de
          Lemonway, et les fonds gel&eacute;s seront trait&eacute;s
          conform&eacute;ment aux mesures d&eacute;finies par la Direction
          g&eacute;n&eacute;rale du Tr&eacute;sor.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="62">
        <li class="c20" ref="h.2ce457m">
          <span class="c12">19. R&Eacute;SILIATION DU CONTRAT-CADRE</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le Titulaire peut r&eacute;silier de plein droit le Contrat-cadre qui
          emportera cl&ocirc;ture de son Compte de paiement par tout moyen
          suivant le respect d&rsquo;un pr&eacute;avis d&rsquo;un (1) mois. Il
          doit maintenir une Provision suffisante pour assurer la bonne fin des
          Op&eacute;rations de paiement en cours pendant le d&eacute;lai
          n&eacute;cessaire &agrave; leur d&eacute;nouement et le paiement des
          frais dus par lui.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway peut r&eacute;silier de plein droit le Contrat-cadre qui
          emportera cl&ocirc;ture du Compte de paiement par lettre
          recommand&eacute;e avec avis de r&eacute;ception suivant un
          pr&eacute;avis de deux (2) mois.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas de manquement grave d&rsquo;une Partie, le Contrat-cadre peut
          &ecirc;tre r&eacute;sili&eacute; avec effet imm&eacute;diat. Il est
          entendu par manquements graves r&eacute;alis&eacute;s par le Titulaire
          : communication de fausses informations ; exercice
          d&rsquo;activit&eacute; ill&eacute;gale, contraire aux bonnes
          m&oelig;urs, de blanchiment de capitaux ou de financement du
          terrorisme ; menaces &agrave; l&rsquo;encontre de
          pr&eacute;pos&eacute;s de Lemonway ou du Partenaire ; d&eacute;faut de
          paiement ; non-respect d&rsquo;une obligation du Titulaire au titre
          des pr&eacute;sentes ; r&eacute;siliation des relations entre le
          Titulaire et le Partenaire ; r&eacute;siliation des relations entre le
          Partenaire et Lemonway ; surendettement ou pour les personnes morales
          nomination d&rsquo;un mandataire ad hoc, d&rsquo;un administrateur
          judiciaire, ouverture d&rsquo;une proc&eacute;dure de redressement ou
          de liquidation. Il est entendu par manquements graves
          r&eacute;alis&eacute;s par Lemonway : communication de fausses
          informations ; irrespect d&rsquo;une obligation au titre des
          pr&eacute;sentes ; nomination d&rsquo;un mandataire ad hoc, d&rsquo;un
          administrateur judiciaire, ouverture d&rsquo;une proc&eacute;dure de
          redressement ou de liquidation.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas de modification de la r&eacute;glementation applicable et de
          l&rsquo;interpr&eacute;tation qui en est faite par
          l&rsquo;autorit&eacute; de r&eacute;gulation concern&eacute;e
          affectant la capacit&eacute; de Lemonway ou de ses mandataires
          &agrave; ex&eacute;cuter les Op&eacute;rations de paiement, le
          Contrat-cadre sera automatiquement r&eacute;sili&eacute;.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le Titulaire ne pourra plus transmettre d&rsquo;Ordre de paiement
          &agrave; compter de la date d&rsquo;effet de la r&eacute;siliation. Le
          Compte pourra &ecirc;tre maintenu pendant une dur&eacute;e de 13 mois
          &agrave; l&rsquo;effet de couvrir les &eacute;ventuelles contestations
          et r&eacute;clamations ult&eacute;rieures. Les Op&eacute;rations de
          paiement initi&eacute;es avant la date d&rsquo;effet de la
          r&eacute;siliation ne seront pas remises en cause par la demande de
          r&eacute;siliation et devront &ecirc;tre ex&eacute;cut&eacute;es dans
          les termes du Contrat-cadre.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La r&eacute;siliation du Contrat-cadre entra&icirc;ne la
          cl&ocirc;ture d&eacute;finitive du Compte de paiement. La
          cl&ocirc;ture d&rsquo;un Compte de paiement ne pourra donner lieu
          &agrave; aucune indemnit&eacute; quels que soient les &eacute;ventuels
          dommages occasionn&eacute;s par la fermeture de ce Compte de paiement.
          Le Titulaire dont le Compte a &eacute;t&eacute; cl&ocirc;tur&eacute;
          par Lemonway n&rsquo;est pas autoris&eacute;, sauf accord
          expr&egrave;s de Lemonway, &agrave; ouvrir un autre Compte de
          paiement. Tout Compte de paiement ouvert en violation de cette
          disposition pourra &ecirc;tre imm&eacute;diatement
          cl&ocirc;tur&eacute; par Lemonway, sans pr&eacute;avis.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >La Provision sur le Compte de paiement objet de la cl&ocirc;ture
          donnera droit &agrave; un virement au profit du Titulaire de ce compte
          suivant ses instructions sous r&eacute;serve (i) des Op&eacute;rations
          de paiement en cours et des &eacute;ventuels impay&eacute;s, rejets
          bancaires ou oppositions &agrave; venir et (ii) des mesures
          applicables au gel des avoirs telles que d&eacute;finies par la
          Direction G&eacute;n&eacute;rale du Tr&eacute;sor. Si un successeur
          est d&eacute;sign&eacute; par Lemonway, il pourra &ecirc;tre
          propos&eacute; au Titulaire de cl&ocirc;turer son Compte de paiement
          et de transf&eacute;rer la Provision sur un nouveau compte de paiement
          ouvert dans les livres de l&rsquo;&eacute;tablissement
          d&eacute;sign&eacute; comme successeur.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Lemonway se r&eacute;serve le droit de demander en justice
          r&eacute;paration du pr&eacute;judice qu&rsquo;elle aurait subi du
          fait de la violation du Contrat-cadre. La cl&ocirc;ture du Compte de
          paiement pourra donner lieu &agrave; des frais dans la limite de
          l&rsquo;article L. 314-13 du Code mon&eacute;taire et financier.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="63">
        <li class="c20" ref="h.rjefff">
          <span class="c12">20. MODIFICATION DU CONTRAT-CADRE</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Les dispositions du Contrat-cadre sont susceptibles
          d&rsquo;&ecirc;tre modifi&eacute;es ou compl&eacute;t&eacute;es
          &agrave; tout moment, notamment en vue de se conformer &agrave; toute
          &eacute;volution l&eacute;gislative, r&egrave;glementaire,
          jurisprudentielle ou technologique.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Tout projet de modification du Contrat-cadre est communiqu&eacute;
          sur support papier ou sur un autre support durable au Titulaire au
          plus tard deux (2) mois avant la date d&rsquo;application
          propos&eacute;e pour son entr&eacute;e en vigueur.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En l&rsquo;absence de contestation &eacute;crite par lettre
          recommand&eacute;e avec avis de r&eacute;ception adress&eacute;e
          &agrave; Lemonway par le Titulaire avant l&rsquo;expiration de ce
          d&eacute;lai de deux mois, ce dernier est r&eacute;put&eacute; avoir
          accept&eacute; ces modifications. En cas de refus de la modification
          propos&eacute;e, le Titulaire peut r&eacute;silier sur demande
          &eacute;crite le Contrat-cadre sans frais, avant la date
          d&rsquo;entr&eacute;e en vigueur propos&eacute;e de celle-ci. Cette
          demande n&rsquo;affecte pas l&rsquo;ensemble des d&eacute;bits (frais,
          cotisations, paiement) dont le Titulaire reste redevable.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="64">
        <li class="c20" ref="h.3bj1y38">
          <span class="c12">21. GENERALITES</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Au cas o&ugrave; des formalit&eacute;s administratives seraient
          n&eacute;cessaires au titre de l&rsquo;ex&eacute;cution du
          pr&eacute;sent Contrat-cadre, Lemonway et le Titulaire se
          pr&ecirc;teront mutuelle assistance pour la r&eacute;gularisation de
          ces formalit&eacute;s.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Si l&rsquo;une quelconque des stipulations non substantielles du
          Contrat-cadre est nulle au regard d&rsquo;une r&egrave;gle de droit en
          vigueur, elle sera r&eacute;put&eacute;e non &eacute;crite, mais
          n&rsquo;entra&icirc;nera pas la nullit&eacute; du pr&eacute;sent
          Contrat-cadre.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le fait pour l&rsquo;une des Parties de ne pas se pr&eacute;valoir
          d&rsquo;un manquement par l&rsquo;autre Partie &agrave; l&rsquo;une
          quelconque des obligations vis&eacute;es dans les pr&eacute;sentes ne
          saurait &ecirc;tre interpr&eacute;t&eacute; pour l&rsquo;avenir comme
          une renonciation &agrave; l&rsquo;obligation en cause.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >En cas de difficult&eacute; d&rsquo;interpr&eacute;tation entre
          l&rsquo;un quelconque des titres et l&rsquo;une quelconque des clauses
          des conditions g&eacute;n&eacute;rales il ne sera pas tenu compte des
          titres.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Le pr&eacute;sent Contrat-cadre a &eacute;t&eacute;
          r&eacute;dig&eacute; en fran&ccedil;ais et en anglais puis traduit
          dans d&rsquo;autres langues &agrave; titre d&rsquo;information
          uniquement. En cas de difficult&eacute; d&rsquo;interpr&eacute;tation,
          les versions fran&ccedil;aises et anglaises du Contrat-cadre
          pr&eacute;vaudront sur toute autre traduction existante.</span
        >
      </p>
      <ol class="c2 lst-kix_list_1-0" start="65">
        <li class="c20" ref="h.1qoc8b1">
          <span class="c12">22. DROIT APPLICABLE ET JURIDICTIONS</span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1"
          >Le pr&eacute;sent Contrat-cadre est r&eacute;gi par le droit
          fran&ccedil;ais.</span
        >
      </p>
      <p class="c0">
        <span class="c1"
          >Sauf disposition imp&eacute;rative contraire, tout litige relatif
          &agrave; son ex&eacute;cution, son interpr&eacute;tation ou sa
          validit&eacute;, sera port&eacute; devant les cours et tribunaux
          comp&eacute;tents et &agrave; d&eacute;faut de Paris.</span
        >
      </p>
      <p class="c6 c19"><span class="c1"></span></p>
      <p class="c28 c19"><span class="c3"></span></p>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.scrollable-container {
  @import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98");

  .lst-kix_list_14-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_14-3 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_14-0 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_14-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_14-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_14-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_14-6 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_9-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-0 {
    list-style-type: none;
  }

  .lst-kix_list_14-2 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_17-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-0.start {
    counter-reset: lst-ctn-kix_list_12-0 0;
  }

  ul.lst-kix_list_17-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-3 {
    list-style-type: none;
  }

  .lst-kix_list_14-8 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_17-2 {
    list-style-type: none;
  }

  .lst-kix_list_5-0 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }

  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }

  .lst-kix_list_5-3 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_5-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_5-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_5-7 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }

  .lst-kix_list_5-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_5-8 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }

  .lst-kix_list_5-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_5-5 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-5.start {
    counter-reset: lst-ctn-kix_list_12-5 0;
  }

  .lst-kix_list_6-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_6-3 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_6-0 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_6-4 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_16-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-0 {
    list-style-type: none;
  }

  .lst-kix_list_6-2 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_16-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-4 {
    list-style-type: none;
  }

  .lst-kix_list_6-8 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_16-3 {
    list-style-type: none;
  }

  .lst-kix_list_6-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_6-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_6-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_7-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_7-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_7-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_12-6 > li {
    counter-increment: lst-ctn-kix_list_12-6;
  }

  .lst-kix_list_13-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_7-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_15-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_4-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_15-7 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_19-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_19-6 {
    list-style-type: none;
  }

  .lst-kix_list_4-3 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_4-5 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_19-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_19-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_19-3 {
    list-style-type: none;
  }

  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }

  ul.lst-kix_list_19-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_19-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_19-0 {
    list-style-type: none;
  }

  .lst-kix_list_15-1 > li:before {
    content: "o  ";
  }

  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }

  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }

  .lst-kix_list_15-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_19-8 {
    list-style-type: none;
  }

  .lst-kix_list_12-3 > li:before {
    content: "" counter(lst-ctn-kix_list_12-3, decimal) ". ";
  }

  .lst-kix_list_12-1 > li:before {
    content: "" counter(lst-ctn-kix_list_12-1, lower-latin) ". ";
  }

  .lst-kix_list_13-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_18-0 {
    list-style-type: none;
  }

  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3;
  }

  .lst-kix_list_13-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_12-5 > li:before {
    content: "" counter(lst-ctn-kix_list_12-5, lower-roman) ". ";
  }

  ul.lst-kix_list_18-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_18-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_18-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-2.start {
    counter-reset: lst-ctn-kix_list_12-2 0;
  }

  ul.lst-kix_list_18-5 {
    list-style-type: none;
  }

  .lst-kix_list_12-7 > li:before {
    content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ". ";
  }

  ul.lst-kix_list_18-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_18-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_18-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_18-1 {
    list-style-type: none;
  }

  .lst-kix_list_13-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1;
  }

  .lst-kix_list_3-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }

  .lst-kix_list_21-8 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_5-0 {
    list-style-type: none;
  }

  .lst-kix_list_3-4 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }

  .lst-kix_list_3-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }

  .lst-kix_list_8-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }

  .lst-kix_list_8-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_3-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_21-0 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_21-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_8-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_13-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_13-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_13-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_13-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_13-1 {
    list-style-type: none;
  }

  .lst-kix_list_3-7 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_13-0 {
    list-style-type: none;
  }

  .lst-kix_list_8-4 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_13-8 {
    list-style-type: none;
  }

  .lst-kix_list_11-1 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_13-7 {
    list-style-type: none;
  }

  .lst-kix_list_21-5 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_13-6 {
    list-style-type: none;
  }

  .lst-kix_list_21-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_11-0 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_8-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_16-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_16-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_4-8 > li:before {
    content: "\0025aa  ";
  }

  ol.lst-kix_list_12-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-6 {
    list-style-type: none;
  }

  .lst-kix_list_4-7 > li:before {
    content: "o  ";
  }

  ol.lst-kix_list_12-7 {
    list-style-type: none;
  }

  .lst-kix_list_17-0 > li:before {
    content: "\0025cf  ";
  }

  ol.lst-kix_list_12-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }

  .lst-kix_list_16-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-0 {
    list-style-type: none;
  }

  .lst-kix_list_16-4 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }

  .lst-kix_list_16-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }

  .lst-kix_list_17-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_17-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_17-3 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_17-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_7-0 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_2-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_2-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_7-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }

  .lst-kix_list_10-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }

  .lst-kix_list_13-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_18-3 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_18-7 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }

  .lst-kix_list_7-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_15-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_10-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_10-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_4-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_15-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_15-2 {
    list-style-type: none;
  }

  .lst-kix_list_15-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_15-1 {
    list-style-type: none;
  }

  .lst-kix_list_15-8 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_15-0 {
    list-style-type: none;
  }

  .lst-kix_list_4-4 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_15-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_15-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_15-6 {
    list-style-type: none;
  }

  .lst-kix_list_9-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_15-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_15-4 {
    list-style-type: none;
  }

  .lst-kix_list_12-8 > li {
    counter-increment: lst-ctn-kix_list_12-8;
  }

  .lst-kix_list_9-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_11-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_12-4 > li:before {
    content: "" counter(lst-ctn-kix_list_12-4, lower-latin) ". ";
  }

  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }

  .lst-kix_list_20-5 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }

  .lst-kix_list_1-0 > li:before {
    content: " ";
  }

  .lst-kix_list_20-1 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }

  .lst-kix_list_11-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_12-3 > li {
    counter-increment: lst-ctn-kix_list_12-3;
  }

  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }

  .lst-kix_list_12-0 > li:before {
    content: "" counter(lst-ctn-kix_list_12-0, decimal) ". ";
  }

  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }

  .lst-kix_list_1-4 > li:before {
    content: " ";
  }

  .lst-kix_list_13-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_14-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-3 {
    list-style-type: none;
  }

  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }

  ul.lst-kix_list_14-2 {
    list-style-type: none;
  }

  .lst-kix_list_13-4 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_14-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-7 {
    list-style-type: none;
  }

  .lst-kix_list_2-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_14-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-5 {
    list-style-type: none;
  }

  .lst-kix_list_1-8 > li:before {
    content: " ";
  }

  .lst-kix_list_12-8 > li:before {
    content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ". ";
  }

  ul.lst-kix_list_20-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-3 {
    list-style-type: none;
  }

  .lst-kix_list_19-0 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_19-1 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_20-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-6.start {
    counter-reset: lst-ctn-kix_list_12-6 0;
  }

  .lst-kix_list_19-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_19-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_19-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_20-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }

  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2;
  }

  .lst-kix_list_19-8 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_19-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_19-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_19-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_12-1 > li {
    counter-increment: lst-ctn-kix_list_12-1;
  }

  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }

  .lst-kix_list_18-0 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_18-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_18-2 > li:before {
    content: "\0025aa  ";
  }

  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }

  .lst-kix_list_2-7 > li:before {
    content: "o  ";
  }

  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }

  .lst-kix_list_2-5 > li:before {
    content: "\0025aa  ";
  }

  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }

  .lst-kix_list_18-6 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }

  .lst-kix_list_10-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_18-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_18-8 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }

  .lst-kix_list_10-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_10-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_10-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_11-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-0 {
    list-style-type: none;
  }

  .lst-kix_list_9-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_20-8 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_11-8 {
    list-style-type: none;
  }

  .lst-kix_list_12-5 > li {
    counter-increment: lst-ctn-kix_list_12-5;
  }

  .lst-kix_list_9-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_21-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_21-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_21-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_21-4 {
    list-style-type: none;
  }

  .lst-kix_list_20-0 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_21-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_21-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_21-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_21-8 {
    list-style-type: none;
  }

  .lst-kix_list_9-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_9-4 > li:before {
    content: "o  ";
  }

  .lst-kix_list_11-3 > li:before {
    content: "\0025cf  ";
  }

  ul.lst-kix_list_21-0 {
    list-style-type: none;
  }

  .lst-kix_list_20-6 > li:before {
    content: "\0025cf  ";
  }

  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }

  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }

  .lst-kix_list_20-4 > li:before {
    content: "o  ";
  }

  ol.lst-kix_list_12-1.start {
    counter-reset: lst-ctn-kix_list_12-1 0;
  }

  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }

  .lst-kix_list_11-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_20-2 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }

  .lst-kix_list_9-8 > li:before {
    content: "\0025aa  ";
  }

  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }

  .lst-kix_list_1-1 > li:before {
    content: " ";
  }

  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }

  .lst-kix_list_11-7 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-0 {
    list-style-type: none;
  }

  .lst-kix_list_1-3 > li:before {
    content: " ";
  }

  ul.lst-kix_list_10-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-7 {
    list-style-type: none;
  }

  .lst-kix_list_1-7 > li:before {
    content: " ";
  }

  ul.lst-kix_list_10-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-3 {
    list-style-type: none;
  }

  .lst-kix_list_1-5 > li:before {
    content: " ";
  }

  ul.lst-kix_list_10-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-1 {
    list-style-type: none;
  }

  .lst-kix_list_2-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_2-3 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_3-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_3-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_8-1 > li:before {
    content: "o  ";
  }

  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }

  .lst-kix_list_8-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_3-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_12-0 > li {
    counter-increment: lst-ctn-kix_list_12-0;
  }

  ol.lst-kix_list_12-3.start {
    counter-reset: lst-ctn-kix_list_12-3 0;
  }

  .lst-kix_list_21-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_8-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_8-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_3-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_21-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_21-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_11-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_21-3 > li:before {
    content: "\0025cf  ";
  }

  ol.lst-kix_list_12-4.start {
    counter-reset: lst-ctn-kix_list_12-4 0;
  }

  .lst-kix_list_16-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_17-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_16-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_16-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_16-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_12-4 > li {
    counter-increment: lst-ctn-kix_list_12-4;
  }

  .lst-kix_list_12-7 > li {
    counter-increment: lst-ctn-kix_list_12-7;
  }

  .lst-kix_list_17-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_17-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_17-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_2-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_7-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_7-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_18-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_13-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }

  .lst-kix_list_15-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_10-2 > li:before {
    content: "\0025aa  ";
  }

  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }

  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }

  .lst-kix_list_20-7 > li:before {
    content: "o  ";
  }

  .lst-kix_list_4-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_4-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_15-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_10-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_9-1 > li:before {
    content: "o  ";
  }

  ol.lst-kix_list_12-7.start {
    counter-reset: lst-ctn-kix_list_12-7 0;
  }

  .lst-kix_list_12-2 > li {
    counter-increment: lst-ctn-kix_list_12-2;
  }

  .lst-kix_list_9-5 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_12-2 > li:before {
    content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ". ";
  }

  ol.lst-kix_list_12-8.start {
    counter-reset: lst-ctn-kix_list_12-8 0;
  }

  .lst-kix_list_11-6 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_20-3 > li:before {
    content: "\0025cf  ";
  }

  .lst-kix_list_1-2 > li:before {
    content: " ";
  }

  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0;
  }

  .lst-kix_list_1-6 > li:before {
    content: " ";
  }

  .lst-kix_list_12-6 > li:before {
    content: "" counter(lst-ctn-kix_list_12-6, decimal) ". ";
  }

  .lst-kix_list_2-2 > li:before {
    content: "\0025aa  ";
  }

  .lst-kix_list_13-2 > li:before {
    content: "\0025aa  ";
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c9 {
    margin-left: 0pt;
    padding-top: 2pt;
    list-style-position: inside;
    padding-bottom: 0pt;
    line-height: 1.2;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }

  .c11 {
    margin-left: 0pt;
    padding-top: 2pt;
    padding-bottom: 10px;
    list-style-position: inside;
    line-height: 1.2;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c20 {
    margin-left: 0pt;
    padding-top: 12pt;
    list-style-position: inside;
    padding-bottom: 0pt;
    line-height: 1.2;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }

  .c25 {
    margin-left: 0pt;
    padding-top: 12pt;
    list-style-position: inside;
    padding-bottom: 10pt;
    line-height: 1.2;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c31 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-style: normal;
  }

  .c15 {
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-style: normal;
  }

  .c5 {
    margin-left: 45.8pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 8pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c7 {
    color: #5a5a5a;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal;
  }

  .c22 {
    color: #1f3763;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal;
  }

  .c30 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: italic;
  }

  .c24 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 28pt;
    font-style: normal;
    font-weight: bold;
    line-height: 1.2;
    text-align: justify;
  }

  .c3 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal;
  }

  .c12 {
    color: #2f5496;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-style: normal;
  }

  .c32 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    line-height: 1.2;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c10 {
    color: #2f5496;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13pt;
    font-style: normal;
  }

  .c14 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal;
    font-weight: bold;
  }

  .c1 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13pt;
    font-style: normal;
  }

  .c8 {
    margin-left: 11pt;
    padding-top: 0pt;
    padding-bottom: 5pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c29 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    line-height: 1.2;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }

  .c13 {
    padding-top: 0pt;
    padding-bottom: 5pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c28 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c17 {
    padding-top: 0pt;
    padding-bottom: 5pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }

  .c26 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }

  .c0 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }

  .c6 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }

  .c27 {
    background-color: #ffffff;
    max-width: 451.3pt;
    padding: 72pt 72pt 72pt 72pt;
  }

  .c18 {
    margin-left: 45.8pt;
    padding-left: 0pt;
  }

  .c21 {
    margin-left: 38.2pt;
    padding-left: 0pt;
  }

  .c2 {
    padding: 0;
    margin: 0;
  }

  .c16 {
    margin-left: 37.5pt;
    padding-left: 0pt;
  }

  .c4 {
    color: inherit;
    text-decoration: inherit;
  }

  .c23 {
    margin-left: 36pt;
    padding-left: 0pt;
  }

  .c19 {
    height: 10pt;
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-size: 36pt;
    padding-bottom: 6pt;
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
    font-size: 10pt;
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 10pt;
  }

  h1 {
    padding-top: 24pt;
    color: #000000;
    font-size: 24pt;
    padding-bottom: 6pt;
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 18pt;
    padding-bottom: 4pt;
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-size: 14pt;
    padding-bottom: 4pt;
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-size: 12pt;
    padding-bottom: 2pt;
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 2pt;
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-size: 10pt;
    padding-bottom: 2pt;
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
</style>
<script>
export default {
  data() {
    return {
      type: null,
    };
  },

  updated() {
    document.querySelector(".scrollable-container").scrollTo(0, 0);
  },
  methods: {
    scrollMeTo(e) {
      //   console.log()
      var element = this.$refs[e.target.dataset.href.replace("#", "")];
      console.log(this.$refs);
      console.log(e.target.dataset.href.replace("#", ""));
      console.log(element);
      var top = element.offsetTop - 80;
      document.querySelector(".scrollable-container").scrollTo(0, top);
    },
  },
};
</script>
